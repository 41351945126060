export const ConfigDeploymentFilterData = [
  {
    defaultValue: "",
    disabled: true,
    key: "modality",
    label: "Modality",
    multiple: false,
    options: [
      {
        id: 1,
        label: "Monitor"
      },
      {
        id: 2,
        label: "DCAR"
      },
      {
        id: 3,
        label: "Hub"
      }
    ]
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Phoenix"
      },
      {
        id: 2,
        label: "B125"
      }
    ]
  },
  {
    defaultValue: [],
    key: "swVersion",
    label: "SW ver.",
    multiple: true,
    options: [
      {
        id: 1,
        label: "1.0"
      },
      {
        id: 2,
        label: "1.1"
      },
      {
        id: 3,
        label: "1.112"
      }
    ]
  },
  {
    defaultValue: [],
    key: "fleet",
    label: "Fleet",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Critical"
      },
      {
        id: 2,
        label: "Moderate"
      },
      {
        id: 3,
        label: "Low"
      }
    ]
  }
]

export const ConfigFilterData = [
  {
    defaultValue: "",
    key: "modality",
    label: "Modality",
    multiple: false,
    options: [
      {
        id: 1,
        label: "Monitor"
      },
      {
        id: 2,
        label: "DCAR"
      },
      {
        id: 3,
        label: "Hub"
      }
    ]
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Phoenix"
      },
      {
        id: 2,
        label: "B125"
      }
    ]
  }
]

export const FleetViewFilterData = [
  {
    defaultValue: [],
    key: "modality",
    label: "Modality",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Monitor"
      },
      {
        id: 2,
        label: "DCAR"
      },
      {
        id: 3,
        label: "Hub"
      }
    ],
    required: true
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Phoenix"
      },
      {
        id: 2,
        label: "B125"
      }
    ]
  },
  {
    defaultValue: [],
    key: "status",
    label: "Status",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Idle"
      },
      {
        id: 2,
        label: "In use"
      },
      {
        id: 3,
        label: "Offline"
      }
    ]
  },
  {
    defaultValue: [],
    key: "faultSeverity",
    label: "Fault severity",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Critical"
      },
      {
        id: 2,
        label: "Moderate"
      },
      {
        id: 3,
        label: "Low"
      }
    ]
  }
]

export const FleetGroupByFilterData  = [
  {
    defaultValue: [],
    disabled: false,
    key: "modality",
    label: "Modality",
    multiple: true,
    options: [],
    required: true
  },
  {
    defaultValue: [],
    disabled: false,
    key: "model",
    label: "Model",
    multiple: true,
    options: []
  },
  {
    defaultValue: [],
    key: "status",
    label: "Status",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Available"
      },
      {
        id: 2,
        label: "In use"
      },
      {
        id: 3,
        label: "Offline"
      }
    ]
  },
  {
    defaultValue: [],
    key: "severity",
    label: "Fault severity",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Critical"
      },
      {
        id: 2,
        label: "Moderate"
      },
      {
        id: 3,
        label: "Low"
      }
    ]
  }
]

export const SiteViewGroupFilterData = [
  {
    defaultValue: [],
    disabled: false,
    key: "modality",
    label: "Modality",
    multiple: true,
    options: [],
    required: true
  },
  {
    defaultValue: [],
    disabled: false,
    key: "model",
    label: "Model",
    multiple: true,
    options: []
  },
  {
    defaultValue: [],
    key: "status",
    label: "Status",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Available"
      },
      {
        id: 2,
        label: "In use"
      },
      {
        id: 3,
        label: "Offline"
      }
    ]
  },
  {
    defaultValue: [],
    key: "severity",
    label: "Fault severity",
    multiple: true,
    options: [
      {
        id: 1,
        label: "Critical"
      },
      {
        id: 2,
        label: "Moderate"
      },
      {
        id: 3,
        label: "Low"
      }
    ]
  }
]
