import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import style from "./CustomerCard.module.scss"

import { setSiteUCMID } from "../../pages/CustomerView/CustomerUtils"
import { EDITABLE_FIELD_TYPE, ROUTE_CONFIG, UPDATE } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import { BREADCRUMB_ROUTE } from "../Breadcrumb/BreadcrumbUtils"
import Distribution from "../Distribution/Distribution"
import EditableLabel from "../EditableLabel/EditableLabel"
import Icon from "../Icon/Icon"
import PageLink from "../PageLink/PageLink"
import ReadOnlyField from "../ReadOnlyField/ReadOnlyField"

const CustomerCard = ({ siteInformation, openLabelEditPopup }) => {
  const { t } = useTranslation(["site"])
  const idLabel = t("hospital-id")
  const cityLabel = t("hospital-city")
  const currentURL = window.location.pathname

  return (
    <PageLink
      className={ classNames(style.card, style.removeLinkStyles) }
      data-testid={ `customer-card-${siteInformation.siteNumber}` }
      to={ currentURL.concat(`/${ROUTE_CONFIG.SITE.routeName}/${siteInformation.id}`) }
      type={ BREADCRUMB_ROUTE.SITE }
      pageName={ siteInformation.siteAliasName ?? siteInformation.siteName }
      onClick={ () => setSiteUCMID(siteInformation?.siteUcmId) }
    >
      <span className={ style.hospitalData }>
        <Icon icon={ "hospital" } iconStyles={ style.siteIcon } />
        <EditableLabel
          dataId={ siteInformation.id }
          type={ EDITABLE_FIELD_TYPE.SITE_CARD }
          value={ siteInformation.siteAliasName ?? siteInformation.siteName }
          openLabelEditPopup={ () =>
            openLabelEditPopup({
              isOpen: true,
              name: siteInformation.siteAliasName ?? siteInformation.siteName,
              type: { action: UPDATE, id: siteInformation.id, type: EDITABLE_FIELD_TYPE.SITE_CARD }
            })
          }
          isPopupEnabled={ true }
        />
      </span>
      <span className={ style.cardDetail }>
        <span className={ classNames(style.idData, style.textEllipsis) }>
          <ReadOnlyField label={ idLabel } value={ siteInformation.siteNumber } />
        </span>
        <span className={ classNames(style.cityData, style.textEllipsis) }>
          <ReadOnlyField label={ cityLabel } value={ siteInformation.city } />
        </span>
      </span>
      <span className={ style.distributionData }>
        { siteInformation?.distribution?.map((data) => (
          <Distribution
            key={ data.type }
            type={ data.type }
            distribution={ data.distribution }
            customStyles={ style.distributionCards }
          />
        )) }
      </span>
    </PageLink>
  )
}

CustomerCard.propTypes = {
  openLabelEditPopup: PropTypes.func,
  siteInformation: PropTypes.shape({
    city: PropTypes.string,
    distribution: PropTypes.arrayOf(
      PropTypes.shape({
        distribution: PropTypes.array,
        type: PropTypes.oneOf(["assets", "alerts", "updates", "configs"])
      })
    ),
    id: PropTypes.string,
    siteAliasName: PropTypes.string,
    siteName: PropTypes.string,
    siteNumber: PropTypes.string,
    siteUcmId: PropTypes.string
  })
}

export default CustomerCard
