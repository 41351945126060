import { decodeURIString } from "../../utils/Common/common"
import { DEFAULT_DATE_RANGE } from "../../utils/Constants/AssetFaults"
import { EMPTY_CELL_DEFAULT_VALUE } from "../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { formatDate } from "../../utils/helper"

const getDistributionData = (faultsStatistics) => {
  return [
    {
      countValue: faultsStatistics?.assetsInFaults?.total ?? 0,
      distribution: [
        faultsStatistics?.assetsInFaults?.critical ?? 0,
        faultsStatistics?.assetsInFaults?.moderate ?? 0,
        faultsStatistics?.assetsInFaults?.low ?? 0
      ],
      mutedAssetCount: faultsStatistics?.assetsInFaults?.disabled ?? 0,
      showMutedCount: true,
      type: "alerts"
    },
    {
      countValue: faultsStatistics?.faultCount?.total ?? 0,
      distribution: [
        faultsStatistics?.faultCount?.critical ?? 0,
        faultsStatistics?.faultCount?.moderate ?? 0,
        faultsStatistics?.faultCount?.low ?? 0
      ],
      type: "faults"
    }
  ]
}

const getPieChartData = (pieChartData) => {
  return [
    {
      count: pieChartData?.faultCount?.acknowledged,
      label: "acknowledged",
      value: pieChartData?.ackPercentage?.acknowledged
    },
    {
      count: pieChartData?.faultCount?.unAcknowledged,
      label: "unAcknowledged",
      value: pieChartData?.ackPercentage?.unAcknowledged
    }
  ]
}

export const transformAssetFaultStatistics = (assets, faultsStatistics) => {
  for (const { faults } of assets) {
    for (const eachFault of faults) {
      eachFault.name = eachFault.assetName
      eachFault.detectedOnForExport = eachFault.detectedOn
      eachFault.notes = decodeURIString(eachFault.latestNote)
      eachFault.lastUpdated =
        eachFault.lastUpdatedOn &&
        `${eachFault?.lastUpdatedBy ? eachFault?.lastUpdatedBy + " | " : ""}${formatDate(
          eachFault.lastUpdatedOn,
          DATE_FORMAT.dateTime
        )}`
      eachFault.isAcknowledge = eachFault.acknowledged
      eachFault.suggestions = eachFault.suggestions || EMPTY_CELL_DEFAULT_VALUE
    }
  }
  return {
    assetSummary: assets,
    distributionData: {
      distributionData: getDistributionData(faultsStatistics),
      pieChartData: getPieChartData(faultsStatistics)
    }
  }
}

export const GET_GROUPBY_VALUE_BY_ID = {
  1: "SEVERITY",
  2: "ASSET_TYPE"
}

export const INITIAL_STATE = {
  dateRange: {
    endDate: DEFAULT_DATE_RANGE.endDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  },
  filters: [],
  groupBy: 1,
  resetDateRange: true,
  searchError: false,
  searchString: null,
  selectedAssets: []
}

export const TYPES = {
  SET_DATE_RANGE: "dateRange",
  SET_FILTERS: "setFilters",
  SET_GROUPBY: "groupBy",
  SET_SEARCH_ERROR: "setSearchError",
  SET_SEARCH_STRING: "searchString",
  SET_SELECTED_ASSETS: "selectedAssets"
}

export const siteAssetFaultsReducer = (newState, action) => {
  switch (action.type) {
  case TYPES.SET_FILTERS:
    if (action.payload?.length === 0) {
      return { ...INITIAL_STATE, ...{ resetDateRange: Math.random() } }
    } else
      return {
        ...newState,
        ...{
          filters: action.payload,
          searchError: INITIAL_STATE.searchError,
          searchString: INITIAL_STATE.searchString
        }
      }

  case TYPES.SET_GROUPBY:
    return {
      ...newState,
      ...{
        groupBy: action.payload,
        selectedAssets: INITIAL_STATE.selectedAssets
      }
    }

  case TYPES.SET_DATE_RANGE:
    return {
      ...newState,
      ...{
        dateRange: {
          ...newState.dateRange,
          ...action.payload
        },
        searchError: INITIAL_STATE.searchError,
        searchString: INITIAL_STATE.searchString,
        selectedAssets: INITIAL_STATE.selectedAssets
      }
    }
  case TYPES.SET_SELECTED_ASSETS:
    return {
      ...newState,
      ...{
        selectedAssets: action.payload
      }
    }
  case TYPES.SET_SEARCH_STRING:
    if ((action.payload || "")?.length < 3 && !action.closeAction) {
      return {
        ...newState,
        ...{
          searchError: true,
          searchString: action.payload
        }
      }
    } else {
      return {
        ...newState,
        ...{
          searchError: false,
          searchString: action.payload
        }
      }
    }
  case TYPES.SET_SEARCH_ERROR:
    return {
      ...newState,
      ...{
        searchError: action.payload
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

export const pieChartData = [
  {
    count: 200,
    label: "acknowledged",
    value: 70
  },
  {
    count: 60,
    label: "unAcknowledged",
    value: 30
  }
]
