import i18n from "i18next"
import React from "react"

import Action from "./internals/Action"

import SoftwareState from "../../../components/SoftwareState/SoftwareState"
import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../utils/Constants/TimeZones"
import { formatDate } from "../../../utils/helper"

const SoftwarePackagesColumnConfig = (handleRowClickActions, muted) => [
  {
    accessorKey: "packageState",
    cell: ({ cell }) => <SoftwareState releaseState={ cell.getValue() } />,
    className: muted && "opacityo5",
    disableTooltip: true,
    enableResizing: false,
    header: "",
    maxSize: 40,
    size: 40
  },
  {
    accessorKey: "uploadedDate",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    className: muted && "opacityForText",
    defaultCanSort: true,
    header: i18n.t("asset:softwarePackages.tableHeaders.uploadedOn"),
    size: 160,
    sortDesc: true
  },
  {
    accessorKey: "name",
    className: muted && "opacityForText",
    header: i18n.t("asset:softwarePackages.tableHeaders.name")
  },
  {
    accessorKey: "description",
    className: muted && "opacityForText",
    header: i18n.t("asset:softwarePackages.tableHeaders.description")
  },
  {
    accessorKey: "version",
    className: muted && "opacityForText",
    header: i18n.t("asset:softwarePackages.tableHeaders.version"),
    size: 90
  },
  {
    accessorKey: "status",
    disableTooltip: true,
    header: i18n.t("asset:softwarePackages.tableHeaders.status"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SOFTWARE_STATUS,
    minSize: 160,
    size: 160
  },
  {
    accessorKey: "lastUpdated",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    className: muted && "opacityForText",
    header: i18n.t("asset:softwarePackages.tableHeaders.lastUpdated")
  },
  {
    accessorKey: "userName",
    className: muted && "opacityForText",
    header: i18n.t("asset:softwarePackages.tableHeaders.user"),
    size: 90
  },
  {
    accessorKey: "note",
    header: i18n.t("asset:softwarePackages.tableHeaders.notes"),
    sortingFn: "alphanumeric"
  },
  {
    accessorKey: "action",
    cell: ({ row }) => (
      <Action
        row={ row }
        handleRowClickActions={ handleRowClickActions }
        muted={ muted }
      />
    ),
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: i18n.t("asset:assetFaults.tableHeaders.action"),
    maxSize: 100
  }
]

export default SoftwarePackagesColumnConfig
