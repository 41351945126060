import PropTypes from "prop-types"
import React, { useState } from "react"

import CentralConfigurationColumnConfiguration from "./CentralConfigurationColumnConfiguration"

import DataTable from "../../../../../components/DataTable/DataTable"
import LabelDataCountAccordion from "../../../../../components/LabelDataCountAccordion/LabelDataCountAccordion"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import styles from "../../CentralConfiguration.module.scss"

const AssetsAccordionItem = ({ itemDetails, throttleActions, hasAnyActionAccess }) => {
  const assetsData = itemDetails.assets
  const [actionClick, setActionClick] = useState(false)

  return (
    <LabelDataCountAccordion
      id={ itemDetails.assetName }
      label={ itemDetails.assetName }
      itemCount={ itemDetails?.assets?.length }
      icon={ ICON_NAME.fleet }
      actionClick = { actionClick }
      customClass={ styles.accordionCustomClass }
    >
      <DataTable
        tableData={ assetsData }
        rowHoverEffect
        columnsData={ CentralConfigurationColumnConfiguration(throttleActions, hasAnyActionAccess, setActionClick) }
      />
    </LabelDataCountAccordion>
  )
}

AssetsAccordionItem.propTypes = {
  handleSelectedAssets: PropTypes.func,
  hasAnyActionAccess: PropTypes.bool,
  itemDetails: PropTypes.object,
  throttleActions: PropTypes.any
}

export default AssetsAccordionItem
