import React from "react"

import AssetSummary from "./AssetSummary/AssetSummary"
import SubFleetSummaryStatistics from "./SubFleetSummaryStatistics/SubFleetSummaryStatistics"
import styles from "./SubFleetView.module.scss"

import ConfirmMoveAsset from "../../components/ConfirmMoveAsset/ConfirmMoveAsset"
import Loading from "../../components/Loading/Loading"
import MoveFleet from "../../components/MoveFleet/MoveFleet"
import { useSubFletViewContext } from "../../contexts/subFleetView/subFleetView"

const SubFleetViewContainer = () => {
  const {
    distributionData,
    assetsSummaryRef,
    handleMoveAssetPopup,
    state,
    handleConfirmPopup,
    getApiQuery,
    onMoveToSuccess
  } = useSubFletViewContext()

  if (!getApiQuery?.isFetched) return <Loading />

  return (
    <>
      <div className={ styles.staticCards }>
        <SubFleetSummaryStatistics distributionData={ distributionData } />
      </div>
      <div className={ styles.subFleetsSummary }>
        <AssetSummary ref={ assetsSummaryRef } />
      </div>
      { state?.moveAssetPopup && (
        <MoveFleet
          isPopupOpen={ state?.moveAssetPopup }
          onCancel={ () => handleMoveAssetPopup(false) }
          setIsConfirmPopUp={ handleConfirmPopup }
        />
      ) }
      <ConfirmMoveAsset
        popupStyles={ styles.confirmationPopUp }
        onSubmit={ onMoveToSuccess }
        selectedDevice={ state?.selectedAssets }
        isOpen={ state?.confirmMovePopup?.isOpen }
        onCancel={ () => handleConfirmPopup({ isOpen: false }) }
        { ...state?.confirmMovePopup }
      />
    </>
  )
}

export default SubFleetViewContainer
