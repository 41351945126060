/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import { DEFAULT_DATE_RANGE } from "../../pages/Reports/ReportsUtils"
import { transformConfigurations } from "../../pages/Reports/Tabs/Configuration/ConfigurationUtils"
import { transformSWPackage } from "../../pages/Reports/Tabs/SWPackage/SWPackageUtils"
import { fileSVC } from "../../services/reactQueries/filesvc"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { softwareSVC } from "../../services/reactQueries/softwaresvc"
import { useLazyQuery, useMutationWithHandlers, useQuery } from "../../utils/CustomHooks/reactQuery"
import { ROUTE_CONFIG, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../utils/GlobalConstants"

export const ReportsContext = createContext({})

export const ReportsProvider = ({ children }) => {
  const queryParams = useParams()
  const searchBarRef = useRef(null)
  const siteId = queryParams[ROUTE_CONFIG.SITE.param]
  const { data: siteData } = useQuery([fleetSVCKeys.GET_SITES], fleetGetSVC.getSites)

  const sites = useMemo(() => siteData?.data?.sites || [], [siteData])

  const currentSite = useMemo(() => sites.find((eachSite) => eachSite.id == siteId), [sites, siteId])

  const siteUcmId = currentSite?.siteUcmId
  const siteName = currentSite?.siteAliasName

  const [modality, setModality] = useState()
  const [model, setModel] = useState([])
  const [configurations, setConfigurations] = useState(null)
  const [swPackage, setSWPackage] = useState(null)
  const [sectionTab, setSectionTab] = useState(0)
  const [searchInput, setSearchInput] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const [searchClose, setSearchClose] = useState(false)
  const [reportsDateRange, setReportsDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    maxDate: DEFAULT_DATE_RANGE.maxDate,
    minDate: DEFAULT_DATE_RANGE.minDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })

  const [exportClicked, setExportClicked] = useState(false)
  const [exportData, setExportData] = useState([])
  const exportRef = useRef(null)
  const [sortingObject, setSortingObject] = useState()

  const {
    data: modalityList,
    isLoading: isModalityListLoading,
    error: isModalityFailed
  } = useQuery([fleetSVCKeys.MODALITY_VALUES_BY_SITE, siteId], () => fleetGetSVC.getModalityValuesBySite(siteId), {
    catchTime: 0
  })

  const modalityOptions = !isModalityListLoading && modalityList?.data?.modalities

  const [getModel, { data: modelList, isLoading: isModelLoading, isFetching: isModelFetching }] = useLazyQuery(
    [fleetSVCKeys.MODEL_VALUES_BY_SITE, siteId],
    () => fleetGetSVC.getModelValuesBySiteAndModality(siteId, modalityOptions, [modality], { catchTime: 0 })
  )
  const getModelOptions = (!isModelLoading && modelList?.data?.models) || []
  const modelOptions = getModelOptions.map(ele => ele.split("|")[1].trim())

  useEffect(() => {
    if (modality) {
      getModel()
    }
  }, [modality, getModel])

  const onConfigurationMutationCompleted = (data) => {
    const configData = transformConfigurations(data?.data?.configurations)
    if (searchInput) {
      setSearchResult(configData)
    } else {
      if (modality) setConfigurations(configData)
    }
  }

  const onSWPackageMutationCompleted = (data) => {
    const swPackageData = transformSWPackage(data?.data?.packageInfo)
    if (searchInput) {
      setSearchResult(swPackageData)
    } else {
      if (modality) setSWPackage(swPackageData)
    }
  }

  const {
    requestMutation: getConfigReportFiles,
    isError: configApiFaliure,
    isLoading: configDataLoading
  } = useMutationWithHandlers({
    onCompletedCallback: onConfigurationMutationCompleted,
    queryFn: fileSVC.getConfigReportsBySiteId
  })

  const {
    requestMutation: getSWPackageReportFiles,
    isError: swPackageApiFaliure,
    isLoading: swPackageDataLoading
  } = useMutationWithHandlers({
    onCompletedCallback: onSWPackageMutationCompleted,
    queryFn: softwareSVC.getSWPackageReportsBySiteId
  })

  const handleSearch = (value, closeAction, hasError) => {
    setSearchClose(closeAction)
    setSearchInput(value)
    if ((closeAction && !hasError) || (!closeAction && value.length >= SEARCH_DEFAULT_MIN_CHARACTERS)) {
      setSearchResult(null)
      setSearchError(false)
      if (sectionTab === 0) {
        getConfigReportFiles({
          payload: {
            modality: modality,
            modalityOptions,
            model: model,
            modelOptions,
            reportsDateRange: reportsDateRange,
            searchInput: value,
            siteId: siteUcmId
          },
          showToast: false
        })
      } else if (sectionTab === 1) {
        getSWPackageReportFiles({
          payload: {
            modality: modality,
            modalityOptions,
            model: model,
            modelOptions,
            reportsDateRange: reportsDateRange,
            searchInput: value,
            siteId: siteUcmId
          },
          showToast: false
        })
      }
    } else {
      setSearchError(true)
      setSearchResult(null)
    }
  }

  const resetSearch = () => {
    setSearchResult(null)
    setSearchError(false)
    setSearchInput(null)
    setSearchClose(false)
  }

  const reset = () => {
    setModality(0)
    setModel([])
    setConfigurations(null)
    setSWPackage(null)
    resetSearch()
    searchBarRef.current?.reset()
  }

  const handleTabChange = (_event, newValue) => {
    setSectionTab(newValue)
    reset()
  }

  const ReportsValue = useMemo(() => ({
    configApiFaliure,
    configDataLoading,
    configurations,
    currentSite,
    exportClicked,
    exportData, 
    exportRef,
    getConfigReportFiles,
    getSWPackageReportFiles,
    handleSearch,
    handleTabChange,
    isModalityFailed,
    isModalityListLoading,
    isModelFetching,
    isModelLoading,
    modality,
    modalityOptions,
    model,
    modelOptions,
    reportsDateRange,
    resetSearch,
    searchBarRef,
    searchClose,
    searchError,
    searchInput,
    searchResult,
    sectionTab,
    setConfigurations,
    setExportClicked,
    setExportData,
    setModality,
    setModel,
    setReportsDateRange,
    setSWPackage,
    setSortingObject,
    siteName,
    siteUcmId,
    sortingObject,
    swPackage,
    swPackageApiFaliure,
    swPackageDataLoading
  }))

  return (
    <ReportsContext.Provider value={ ReportsValue }>
      { children }
    </ReportsContext.Provider>
  )
}

ReportsProvider.propTypes = {
  children: PropTypes.any
}

export const useReportsContext = () => {
  return useContext(ReportsContext)
}
