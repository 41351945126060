/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./HeaderContainer.module.scss"

import { useAuthContext } from "../../contexts/Auth/auth"
import { useHelp } from "../../contexts/help/HelpContext"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"
import IconLabel from "../IconLabel/IconLabel"
import UserSettingsMenu from "../UserSettingsMenu/UserSettingsMenu"

const HeaderContainer = ({
  handleSignIn,
  headerData,
  isSignedIn,
  helpIcon
}) => {
  const { getTokenData } = useAuthContext()
  const { t } = useTranslation(["userMenu"])
  const [userName, setUserName] = useState("")
  const [isUserSettingsMenuOpen, setIsUserSettingsMenuOpen] = useState(false)
  const userData = useMemo(() => getTokenData(), [])
  const { displayHelpPopup } = useHelp()

  useEffect(() => {
    getUserName()
  }, [])

  const getUserName = () => {
    const name = userData?.sub
    setUserName(name || headerData.userName)
  }

  const handleUserSettingsMenu = (value) => {
    setIsUserSettingsMenuOpen(value)
  }

  return (
    <div
      className={ classNames(
        styles.headerContainer,
        isUserSettingsMenuOpen ? "menuOpen" : "menuClosed"
      ) }
    >
      <span className={ styles.leftAlign }>
        <IconLabel
          label={ t(`header.${headerData.headerName}`) }
          iconName={ headerData.headerLogo }
          labelStyles={ styles.headerText }
          showDivider
        />
      </span>
      <span className={ styles.rightAlign }>
        { helpIcon && (
          <IconButton
            className={ classNames(styles.helpIconButton, "helpIcon") }
            disableRipple
            onClick={ () => displayHelpPopup() }
          >
            <Icon icon={ ICON_NAME.help } iconStyles={ styles.helpIcon } />
          </IconButton>
        ) }
        { isSignedIn ? (
          <UserSettingsMenu
            userName={ userName }
            handleUserSettingsMenu={ handleUserSettingsMenu }
          />
        ) : (
          <span className={ styles.signInButton }>
            <IconButton onClick={ handleSignIn } disableRipple>
              <IconLabel
                label={ t(`header.${headerData.signInButtonLabel}`) }
                iconName={ headerData.userIcon }
                labelStyles={ styles.signInText }
              />
            </IconButton>
          </span>
        ) }
      </span>
    </div>
  )
}

HeaderContainer.defaultProps = {
  helpIcon: true
}

HeaderContainer.propTypes = {
  handleSignIn: PropTypes.func,
  headerData: PropTypes.object,
  helpIcon: PropTypes.bool,
  isSignedIn: PropTypes.bool
}

export default HeaderContainer
