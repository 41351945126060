import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./Mute.module.scss"

import { classNames } from "../../utils/styles/helper"

const Mute = ({ status }) => {
  const { t } = useTranslation(["asset"])

  return (
    <div className={ classNames(styles.rootIndicator) }>
      { status && (
        <img
          id="alert-mute"
          className={ styles.icons }
          src="/assets/icons/disabledIcon.svg"
          alt={ t("alertMute") }
        />
      ) }
    </div>
  )
}

Mute.propTypes = {
  status: PropTypes.bool
}

Mute.defaultProps = {
  status: true
}

export default Mute
