import PropTypes from "prop-types"
import React from "react"
import { Bar, BarChart, ResponsiveContainer } from "recharts"

import styles from "./Chart.module.scss"
import CustomBar from "./internal/CustomTooltip"

const ChartStatisticCard = ({ type, value, title }) => {
  return (
    <div className={ styles.cardContainer }>
      <div className={ styles.title }>{ title }</div>
      <div className={ styles.chart }>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={ 400 } height={ 300 } data={ value } barGap={ 12 }>
            <Bar dataKey="value" shape={ <CustomBar type={ type } /> }/>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

ChartStatisticCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.array
}

export default ChartStatisticCard
