import i18n from "i18next"
import React from "react"

import Action from "./Action"

import SoftwareState from "../../../../components/SoftwareState/SoftwareState"
import { DATE_FORMAT } from "../../../../utils/Constants/TimeZones"
import { formatDate } from "../../../../utils/helper"

const SoftwareColumnConfig = (handleRowClickActions) => [
  {
    accessorKey: "packageState",
    cell: ({ cell }) => <SoftwareState releaseState={ cell.getValue() } />,
    defaultEmptyValue: "",
    disableTooltip: true,
    enableResizing: false,
    header: "",
    maxSize: 20,
    size: 20
  },
  {
    accessorKey: "uploadedOn",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    defaultCanSort: true,
    header: i18n.t("asset:softwarePackages.tableHeaders.uploadedOn"),
    size: 90,
    sortDesc: true
  },
  {
    accessorKey: "name",
    header: i18n.t("asset:softwarePackages.tableHeaders.name")
  },
  {
    accessorKey: "description",
    header: i18n.t("asset:softwarePackages.tableHeaders.description")
  },
  {
    accessorKey: "version",
    header: i18n.t("asset:softwarePackages.tableHeaders.version"),
    size: 100
  },
  {
    accessorKey: "action",
    cell: ({ row }) => (
      <Action row={ row } handleRowClickActions={ handleRowClickActions } />
    ),
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: i18n.t("asset:assetFaults.tableHeaders.action"),
    maxSize: 100
  }
]

export default SoftwareColumnConfig
