import colors from "../../styles/jsExports/colorExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const OutlinedInput = {
  styleOverrides: {
    root: {
      "&.overlay":{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["datePicker-outline"],
          borderWidth: margins["marginInPixel-px1"]
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          backgroundColor: colors["datePicker-hoverBg"],
          borderColor: colors["datePicker-outline"]
        },
        // eslint-disable-next-line sort-keys
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["createEditOverlay-outline"],
          borderWidth: margins["marginInPixel-px2"]
        },
        "&.error": {
          background: colors["createEditOverlay-errorBackground"]
        },
        "&.error .MuiOutlinedInput-notchedOutline":{
          borderColor : colors["createEditOverlay-errorBorder"] 
        },
        background: colors["createEditOverlay-inputBackground"],
        marginBottom: margins["marginInPixel-px4"]
      },
      height: margins["marginInPixel-px32"],
      radius: margins["marginInPixel-px4"]
    }
  }
}
