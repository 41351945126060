import { ThemeProvider } from "@mui/material"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import App from "./App"
import Loading from "./components/Loading/Loading"
import "./index.scss"
import "./services/i18n/i18"
import { theme } from "./utils/Themes/theme"


const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ThemeProvider theme={ theme }>
    <BrowserRouter>
      <Suspense fallback={ <Loading /> }>
        <App />
      </Suspense>
    </BrowserRouter>
  </ThemeProvider>
)
