import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./CentralSoftwareUpdate.module.scss"
import CentralSoftwareUpdateStatistics from "./CentralSoftwareUpdateStatistics/CentralSoftwareUpdateStatistics"
import { softwareFilterData } from "./CentralSoftwareUpdatesUtils"
import SoftwareUpdateSummary from "./SoftwareSummary/SoftwareUpdateSummary"

import ConfigFilterPopup from "../../../components/ConfigFilterPopup/ConfigFilterPopup"
import Error from "../../../components/Error/Error"
import { useSoftwareUpdateContext } from "../../../contexts/swUpdate/SoftwareUpdateContext"
import {
  COMPARE_VALUE,
  CONFIG_CONTEXT_TYPE
} from "../../../utils/GlobalConstants"

const CentralSoftwareUpdate = () => {
  const { t: common } = useTranslation(["common"])
  const { isFilterOpen, setIsFilterOpen, setFilter, distributionData, modalityChartData } = useSoftwareUpdateContext()
  const [filterAPIError, setFilterAPIError] = useState(false)

  const updateFilterItem = (value) => {
    const filterItem = value.filter(
      (option) => option.type === COMPARE_VALUE.modality
    )
    const setFilterValue = filterItem.length ? value : []
    setFilter(setFilterValue)
  }

  if (filterAPIError)
    return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <>
      <div className={ styles.softwareUpdateStatisticCard }>
        <CentralSoftwareUpdateStatistics
          distributionData={ distributionData }
          chartData={ modalityChartData }
        />
      </div>
      <div className={ styles.softwareDataSection }>
        <SoftwareUpdateSummary />

        { isFilterOpen && (
          <ConfigFilterPopup
            isPopupOpen={ isFilterOpen }
            onCancel={ setIsFilterOpen }
            onSubmit={ updateFilterItem }
            setIsFilterOpen={ setIsFilterOpen }
            data={ softwareFilterData }
            popupStyles={ styles.overlay }
            type={ CONFIG_CONTEXT_TYPE.CENTRAL_SOFTWARE }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
            isModelMandatory={ true }
          />
        ) }
      </div>
    </>
  )
}

export default CentralSoftwareUpdate
