import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "../../../components/Icon/Icon"
import { ICON_NAME } from "../../../utils/GlobalConstants"
import styles from "../Login.module.scss"

const Contact = ({ id }) => {
  const { t } = useTranslation(["login"])
  return (
    <div id={ id } className={ styles.contactDesc }>
      <div className={ styles.contactTitle }>{ t("contactUs.contactUs") }</div>

      <div className={ styles.contactEmail }>
        <Icon icon={ ICON_NAME.mailIon }></Icon>
        <span className={ styles.contactLabel }>{ t("contactUs.email") }</span>
      </div>

      <div className={ styles.contactNumber }>
        <Icon icon={ ICON_NAME.phoneIcon }></Icon>
        <span className={ styles.contactLabel }>{ t("contactUs.number") }</span>
      </div>
    </div>
  )
}

Contact.propTypes = {
  id: PropTypes.string
}

export default Contact
