import { useReducer } from "react"
import { useMutation, useQueryClient, useQuery as useReactQuery } from "react-query"

import { useToaster } from "../../contexts/ToastContext"
import { INITIAL_MUTATION_STATE, mutationDataReducer } from "../Reducers/MutationData"

export const useQuery = (keys, fn, options = {}) => {
  return useReactQuery(keys, fn, options)
}

export const useLazyQuery = (key, queryFn, options = {}) => {
  const query = useQuery(key, queryFn, {
    ...options,
    enabled: false
  })
  return [query.refetch, query]
}

export const useMutationWithHandlers = ({ queryFn, onCompletedCallback, onErrorCallback, refetchQueries }) => {
  const queryClient = useQueryClient()
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()
  const [state, dispatch] = useReducer(mutationDataReducer, INITIAL_MUTATION_STATE)

  const onDeployMutationComplete = (res) => {
    if (refetchQueries)
      for (const query of refetchQueries) {
        const refetchQuery = query.id ? [query.key, query.id] : [query.key]
        queryClient.refetchQueries({
          exact: true,
          queryKey: refetchQuery,
          type: "active"
        })
      }
    if (state.showToast) displaySuccessToast(state.successMessage || res?.message)
    onCompletedCallback?.(res)
    dispatch({ type: "clear" })
  }

  const onDeployMutationError = (err) => {
    if (state.showToast) displayMediumErrorToast(state.errorMessage || err.message)
    onErrorCallback?.(err)
    dispatch({ type: "clear" })
  }

  const { mutate, data, isLoading, error, ...rest } = useMutation(queryFn, {
    onError: onDeployMutationError,
    onSuccess: onDeployMutationComplete
  })

  const requestMutation = (req) => {
    dispatch({
      payload: {
        errorMessage: req.errorMessage,
        showToast: Object.hasOwn(req, "showToast") ? req.showToast : true,
        successMessage: req.successMessage
      },
      type: "add"
    })
    setTimeout(() => {
      mutate(req.payload)
    }, 0)
  }

  return {
    data,
    error,
    isLoading,
    loading: isLoading,
    requestMutation,
    ...rest
  }
}
