import { IconButton } from "@mui/material"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import AccessManagementContainer from "./internals/accessManagement/AccessManagementContainer"
import NoUsers from "./internals/NoUsers/NoUsers"
import UserGroups from "./internals/UserGroups"
import Users from "./internals/Users"
import styles from "./UserManagement.module.scss"

import Icon from "../../components/Icon/Icon"
import SearchBar from "../../components/SearchBar/SearchBar"
import { UserManagementConsumer, UserManagementProvider } from "../../contexts/userManagement/UserManagement"
import { UsersAuthElement } from "../../utils/Constants/Auth/user"
import { SEARCH_INSTANCES } from "../../utils/Constants/Search"
import useAccess from "../../utils/CustomHooks/useAccess"
import { ICON_NAME, ROUTE_CONFIG, SEARCH_DEFAULT_MIN_CHARACTERS } from "../../utils/GlobalConstants"
import { StorageKeys, getSession } from "../../utils/SessionHelper/session"

const UserManagement = () => {
  const hasUserAccess = useAccess(UsersAuthElement.ReadUser)
  const hasUserGroupAccess = useAccess(UsersAuthElement.ReadUserGroup)

  const backURL = `/${ROUTE_CONFIG.CUSTOMER.routeName}/${getSession(StorageKeys.CUSTOMER_ID)}`
  const navigate = useNavigate()

  const getSearchCount = (context) => {
    if (context?.searchString?.length >= SEARCH_DEFAULT_MIN_CHARACTERS) {
      return (context.usersData?.users?.length || 0) + (context.userGroupsData?.groups?.length || 0)
    }
  }

  useEffect(() => {
    (!hasUserAccess && !hasUserGroupAccess) && navigate(`/${ROUTE_CONFIG.ACCESS_DENIED.routeName}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserAccess, hasUserGroupAccess])
  

  if(!hasUserAccess && !hasUserGroupAccess) return navigate(`/${ROUTE_CONFIG.ACCESS_DENIED.routeName}`)

  return (
    <UserManagementProvider>
      <UserManagementConsumer>
        { (context) => {
          let searchCount = getSearchCount(context)
          return (
            <>
              { !context?.searchString &&
              context.usersData?.users?.length === 0 &&
              context.userGroupsData?.groups?.length === 0 ? (
                // eslint-disable-next-line react/jsx-indent
                  <NoUsers />
                ) : (
                  <div className={ styles.container }>
                    <div className={ styles.topContainer }>
                      <div className={ styles.title }>
                        <IconButton aria-label="Back button" onClick={ () => navigate(backURL) }>
                          <Icon icon={ ICON_NAME.back } />
                        </IconButton>
                        <span className={ styles.iconLabel }>{ context.t("manageUsers") }</span>
                      </div>
                    </div>
                    <div className={ styles.bottomContainer }>
                      <div className={ styles.bottomLeftContainer }>
                        <div className="searchContainer">
                          <span className="selectionText">
                            { searchCount >= 0 && context.t("searchUserOrGroupResult", { count: searchCount }) }
                          </span>
                          <div className="searchInput">
                            <SearchBar
                              onHandleSubmit={ context.searchUserOrGroups }
                              type={ SEARCH_INSTANCES.userManagement }
                            />
                          </div>
                        </div>
                        <div className={ styles.accordions }>
                          { hasUserGroupAccess && <UserGroups /> }
                          { hasUserAccess && <Users /> }
                        </div>
                      </div>
                      <div className={ styles.bottomRightContainer }>
                        { Object.keys(context.selectedRowDetails)?.length > 0 && <AccessManagementContainer /> }
                      </div>
                    </div>
                  </div>
                ) }
            </>
          )
        } }
      </UserManagementConsumer>
    </UserManagementProvider>
  )
}

export default UserManagement
