import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Icon from "../../../components/Icon/Icon"
import { SOLUTION_DATA } from "../../../utils/Constants/Login"
import styles from "../Login.module.scss"

const Solution = ({ id }) => {
  const { t } = useTranslation(["login"])

  return (
    <div id={ id } className={ styles.solutionSection }>
      <div className={ styles.solutionsDesc }>
        <div className={ styles.solutionDescription }>
          { t("solution.desc") }
        </div>

        <div className={ styles.solutionsGridSection }>
          { SOLUTION_DATA.map((solution) => (
            <span key={ solution.id } className={ styles.solutionsGrid }>
              <Icon icon={ solution.icon } iconStyles={ styles.solutionIcon } />
              <span className={ styles.solutionsDescText }>{ solution.desc }</span>
            </span>
          )) }
        </div>
      </div>
    </div>
  )
}

Solution.propTypes = {
  id: PropTypes.string
}

export default Solution
