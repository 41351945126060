import PropTypes from "prop-types"
import React from "react"

import styles from "./CardColorBar.module.scss"

import { ASSET_STATUS } from "../../utils/GlobalConstants"
import { formatStatus } from "../../utils/helper"
import { classNames } from "../../utils/styles/helper"
import colors from "../../utils/styles/jsExports/colorExport.module.scss"

const CardColorBar = ({ status, colorStyles }) => {
  const transformStatus = formatStatus(status)
  const color = ASSET_STATUS[transformStatus]
    ? ASSET_STATUS[transformStatus].COLOR_CODE
    : colors["assetStatus-default"]
  const indicatorColor = {
    backgroundColor: color,
    borderColor: color
  }

  return (
    <div
      style={ indicatorColor }
      className={ classNames(styles.root, colorStyles, "customCardColorBar") }
      data-testid="CardColor"
    />
  )
}

CardColorBar.propTypes = {
  colorStyles: PropTypes.any,
  status: PropTypes.any.isRequired
}
export default CardColorBar
