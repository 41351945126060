import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AssetStatusIndicator.module.scss"

import { ASSET_STATUS } from "../../utils/GlobalConstants"
import { formatStatus } from "../../utils/helper"
import colors from "../../utils/styles/jsExports/colorExport.module.scss"

const AssetStatusIndicator = ({
  status,
  displayStatusText,
  styles: customStyles
}) => {
  const { t } = useTranslation(["asset"])

  const transFormStatus = formatStatus(status)

  const indicatorColor = {
    backgroundColor: ASSET_STATUS[transFormStatus]
      ? ASSET_STATUS[transFormStatus].COLOR_CODE
      : colors["assetStatus-default"]
  }

  return (
    <div
      aria-label="statusIndicatorContainer"
      style={ customStyles }
      className={ styles.rootIndicator }
    >
      <div style={ indicatorColor } className={ styles.colorIndicator } />
      { displayStatusText && (
        <label aria-label="statusText">{ t(`assetStatusText.${transFormStatus}`) }</label>
      ) }
    </div>
  )
}

AssetStatusIndicator.propTypes = {
  displayStatusText: PropTypes.bool,
  status: PropTypes.oneOf(["Offline", "Available", "In use", ""]).isRequired,
  styles: PropTypes.object
}

AssetStatusIndicator.defaultProps = {
  displayStatusText: false,
  status: "Available"
}

export default AssetStatusIndicator
