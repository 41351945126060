/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { setSiteUCMID, transformSiteStatistics } from "./CustomerUtils"
import style from "./CustomerView.module.scss"

import { BREADCRUMB_ROUTE } from "../../components/Breadcrumb/BreadcrumbUtils"
import CreateUpdateLabelPopup from "../../components/CreateUpdateLabelPopup/CreateUpdateLabelPopup"
import CustomerCard from "../../components/CustomerCard/CustomerCard"
import CustomerExpandedCard from "../../components/CustomerExpandedCard/CustomerExpandedCard"
import EditableLabel from "../../components/EditableLabel/EditableLabel"
import Error from "../../components/Error/Error"
import Icon from "../../components/Icon/Icon"
import Loading from "../../components/Loading/Loading"
import ReadOnlyField from "../../components/ReadOnlyField/ReadOnlyField"
import SearchBar from "../../components/SearchBar/SearchBar"
import SearchErrorPage from "../../components/SearchErrorPage/SearchErrorPage"
import { useBreadcrumbContext } from "../../contexts/breadcrumb/breadcrumb"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { SEARCH_INSTANCES } from "../../utils/Constants/Search"
import {
  APPLICATION_DETAILS,
  EDITABLE_FIELD_TYPE,
  ICON_NAME,
  ROUTE_CONFIG,
  globalSearch
} from "../../utils/GlobalConstants"
import { StorageKeys, setSession } from "../../utils/SessionHelper/session"
import { classNames } from "../../utils/styles/helper"

const CustomerView = () => {
  const queryParams = useParams()
  const queryClient = useQueryClient()
  const [navigateToSite, setNavigateToSite] = useState(false)
  const { updateBreadcrumbData, setIsCustomerDisabled } = useBreadcrumbContext()
  const customerId = queryParams[ROUTE_CONFIG.CUSTOMER.param]
  const resCustomerId = useRef(customerId)

  const {
    isFetching: isLoading,
    data,
    error,
    isError
  } = useQuery([fleetSVCKeys.GET_SITES], fleetGetSVC.getSites, {
    onSuccess: (_data) => handleDataLoad(_data),
    staleTime: 2000
  })

  useEffect(() => {
    const _data = queryClient.getQueryData(fleetSVCKeys.GET_SITES)
    if (_data) handleDataLoad(_data)
  }, [])

  useEffect(() => {
    if (navigateToSite) {
      navigate(
        // eslint-disable-next-line max-len
        `/${ROUTE_CONFIG.CUSTOMER.routeName}/${resCustomerId?.current}/${ROUTE_CONFIG.SITE.routeName}/${data?.data?.sites?.[0].id}`
      )
    }
  }, [navigateToSite])

  const handleDataLoad = (_data) => {
    const customerRes = _data?.data?.customer
    const custId = customerRes?.id
    resCustomerId.current = custId
    setSession(StorageKeys.CUSTOMER_ID, custId)
    const sites = _data?.data?.sites
    handleUpdateCustomerName(customerRes?.aliasName ?? customerRes?.name)
    if (sites?.length === 1) {
      setSiteUCMID(sites[0]?.siteUcmId)
      setIsCustomerDisabled(true)
      handleUpdateSiteName(sites[0]?.siteAliasName ?? sites[0]?.siteName)
      setNavigateToSite(true)
    } else if (sites?.length === 0) {
      navigate(`/${ROUTE_CONFIG.ACCESS_DENIED.routeName}`)
    } else {
      setIsCustomerDisabled(false)
      setNavigateToSite(false)
      if (!customerId || customerId === "null" || customerId === "undefined") {
        navigate(`/${ROUTE_CONFIG.CUSTOMER.routeName}/${custId}`)
      }
    }
  }

  const handleUpdateCustomerName = (name) => {
    updateBreadcrumbData({
      key: BREADCRUMB_ROUTE.CUSTOMER,
      name
    })
  }

  const handleUpdateSiteName = (name) => {
    updateBreadcrumbData({
      key: BREADCRUMB_ROUTE.SITE,
      name
    })
  }

  const customer = !isLoading && data?.data?.customer
  const sitesData = data?.data?.sites

  const { sites } = useCallback(
    transformSiteStatistics(sitesData), [sitesData]
  )

  const { t } = useTranslation(["search"])
  const [viewChange, setViewChange] = useState(true)
  const [searchInput, setSearchInput] = useState("")
  const [searchedSites, setSearchedSites] = useState(null)
  const [searchError, setSearchError] = useState(false)
  const [isEditPopupOpen, setIsEditPopupOpen] = useState({
    isOpen: false,
    name: "",
    type: { action: "", id: "", type: "" }
  })
  const navigate = useNavigate()
  const currentURL = window.location.pathname

  const filterSearchData = (value, _data) => {
    const filteredResults = globalSearch({
      data: _data,
      includeKeys: ["siteNumber", "siteName", "siteAliasName"],
      searchString: value
    })

    if (filteredResults.length === 1) {
      const { id, siteAliasName, siteName } = filteredResults[0]
      updateBreadcrumbData({
        key: BREADCRUMB_ROUTE.SITE,
        name: siteAliasName ?? siteName
      })
      const path = currentURL.concat(`/${ROUTE_CONFIG.SITE.routeName}/${id}`)
      navigate(path)
    } else if (filteredResults.length === 0) {
      setSearchError(true)
      return null
    } else {
      return filteredResults
    }
  }

  const handleOnSubmit = (value, closeAction) => {
    if (closeAction) {
      setSearchError(false)
      setSearchedSites(null)
      return false
    }
    if (value.length >= 3) {
      const res = filterSearchData(value, sites)
      setSearchInput(value)
      setSearchedSites(res)
      setSearchError(res ? false : true)
    } else {
      setSearchInput(value)
      setSearchError(true)
      setSearchedSites(null)
    }
  }

  if (isLoading) return <Loading />
  if (isError) return <Error error={ error } />

  return (
    <>
      <div className={ style.customerViewHeader }>
        <div className={ style.customerViewTitle } data-testid="customer-view-header">
          <EditableLabel
            type={ EDITABLE_FIELD_TYPE.CUSTOMER_NAME }
            dataId={ customerId }
            value={ customer?.aliasName ?? customer?.name }
            handleUpdateComplete={ handleUpdateCustomerName }
            isPopupEnabled={ true }
            openLabelEditPopup={ setIsEditPopupOpen }
          />
          <div className={ style.readOnlyField }>
            <ReadOnlyField 
              label={ APPLICATION_DETAILS.labelID } value={ customer.number } labelStyles={ style.customerID } />
          </div>
        </div>
        <div className={ style.customerViewChange }>
          { (searchedSites || (searchError && searchInput?.length >= 3)) && (
            <span role={ "contentinfo" } className={ style.assetsCountText }>
              { searchError && searchInput && searchInput.length >= 3
                ? t("noResultSearch")
                : `${t("searchSiteCount", {
                  searchSitesCount: searchedSites.length
                })}` }
            </span>
          ) }
          <button
            data-testid="toggle-button"
            className={ viewChange ? style.customerViewToggleButtonCard : style.customerViewToggleButtonList }
            onClick={ () => setViewChange(!viewChange) }
          >
            <Icon icon={ ICON_NAME.gridView } iconStyles={ style.toggleIcon } />
          </button>
          <SearchBar
            type={ SEARCH_INSTANCES.customerView }
            inputStyles={ style.searchField }
            onHandleSubmit={ handleOnSubmit }
          />
        </div>
      </div>
      <div className={ classNames(style.customerViewContent, viewChange ? style.cardContent : style.listContent) }>
        { searchError && <SearchErrorPage /> }
        { !searchError &&
          (searchedSites || sites).map((_data) =>
            viewChange ? (
              <CustomerCard 
                siteInformation={ _data } key={ _data.siteNumber } openLabelEditPopup={ setIsEditPopupOpen } />
            ) : (
              <CustomerExpandedCard
                siteInformation={ _data }
                key={ _data.siteNumber }
                openLabelEditPopup={ setIsEditPopupOpen }
              />
            )
          ) }
      </div>
      { isEditPopupOpen.isOpen && (
        <CreateUpdateLabelPopup
          dataId={ isEditPopupOpen?.type?.id }
          isEditPopupOpen={ isEditPopupOpen }
          setIsEditPopupOpen={ setIsEditPopupOpen }
          popupStyles={ style.popupStyle }
          inputStyle={ style.inputTextField }
        />
      ) }
    </>
  )
}

export default CustomerView
