import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useRef } from "react"

const DataTable = React.createContext({})

export const DataTableProvider = ({ dataLength, children }) => {
  const dataTableRef = useRef(null)

  const rowRefs = useRef([])

  useEffect(() => {
    rowRefs.current = rowRefs.current.slice(0, dataLength)
  }, [dataLength])

  const dataTableContextState = useMemo(
    () => ({
      dataTableRef,
      rowRefs
    }),
    []
  )

  return <DataTable.Provider value={ dataTableContextState }>{ children }</DataTable.Provider>
}

DataTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dataLength: PropTypes.number
}

export const DataTableConsumer = DataTable.Consumer

export const useDataTableContext = () => {
  return useContext(DataTable)
}
