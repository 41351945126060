import PropTypes from "prop-types"
import { React, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import style from "./AssetCard.module.scss"

import { CommonAuthElement } from "../../utils/Constants/Auth/common.js"
import { DATE_FORMAT } from "../../utils/Constants/TimeZones.js"
import { ASSET_STATUS, DRAG_ICON_BASE_PATH, ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { formatDate, formatStatus } from "../../utils/helper.js"
import { classNames } from "../../utils/styles/helper"
import colors from "../../utils/styles/jsExports/colorExport.module.scss"
import AlertConfigStatus from "../AlertConfigStatus/AlertConfigStatus.jsx"
import AlertSoftwareStatus from "../AlertSoftwareStatus/AlertSoftwareStatus.jsx"
import AlertSeverity from "../AssetAlertsSeverity/AlertSeverity.jsx"
import AuthGuard from "../AuthGuard/AuthGuard"
import { BREADCRUMB_ROUTE } from "../Breadcrumb/BreadcrumbUtils"
import CardColorBar from "../CardColorBar/CardColorBar.jsx"
import CheckBoxLabel from "../CheckBoxLabel/CheckBoxLabel"
import IconLabel from "../IconLabel/IconLabel.jsx"
import PageLink from "../PageLink/PageLink"
import ReadOnlyField from "../ReadOnlyField/ReadOnlyField.jsx"

const AssetCard = ({ assetDetails, onChange, status, isSelected, dndProvided, isSelectable }) => {
  const [selected, setSelected] = useState(isSelected)
  const { t } = useTranslation(["asset"])
  const currentURL = window.location.pathname

  const handleCheck = (event) => {
    setSelected(event.target.checked)
    onChange(event.target.checked, assetDetails)
    event.stopPropagation()
  }

  useEffect(() => {
    setSelected(isSelected)
  }, [isSelected])

  const transformStatus = formatStatus(status)
  const color = ASSET_STATUS[transformStatus] ? ASSET_STATUS[transformStatus].COLOR_CODE : colors["assetStatus-default"]
  const cardBorder = {
    borderBottomColor: color,
    borderRightColor: color,
    borderTopColor: color
  }

  return (
    <PageLink
      style={ cardBorder }
      className={ classNames(style.assetDetails, style.removeLinkStyles, selected && style.selectedCard) }
      tabIndex={ assetDetails.id }
      to={ currentURL.concat(`/${ROUTE_CONFIG.ASSET.routeName}/${assetDetails.id}`) }
      type={ BREADCRUMB_ROUTE.ASSET }
      pageName={ assetDetails.name }
    >
      <CardColorBar status={ status } />
      <span className={ style.assetContent }>
        { isSelectable && (
          <AuthGuard actionType={ CommonAuthElement.Any }>
            <div className={ style.assetCheckbox }>
              <CheckBoxLabel isChecked={ selected } onSelect={ handleCheck } />
            </div>
          </AuthGuard>
        ) }
        <span className={ style.assetTitle }>
          <IconLabel
            label={ assetDetails.name }
            iconName={ assetDetails.mute ? "disabledIcon" : "assets" }
            labelStyles={ classNames(style.assetIconLabel) }
            iconStyles={ "assetIcon" }
            displayIconTooltip={ assetDetails.mute }
            iconTooltipLabel={ t("muteUnmuteMessage.muteIconTooltip") }
            displayAssetNameTooltip={ true }
            note={ assetDetails.assetNote }
          />
          <span className={ assetDetails.mute ? style.iconStylesMuted : style.iconStyles }>
            { assetDetails.severity && <AlertSeverity severity={ assetDetails.severity } styles={ style.severity } /> }
            { assetDetails.swStatus && (
              <AlertSoftwareStatus failedReason={ assetDetails?.swFailedReason } 
                swStatus={ assetDetails.swStatus } />
            ) }
            { assetDetails.configStatus && (
              <AlertConfigStatus
                failedReason={ assetDetails?.configFailedReason }
                configStatus={ assetDetails.configStatus }
              />
            ) }
          </span>
        </span>

        <span className={ style.assetsField }>
          <ReadOnlyField label={ t("assetCard.sn") } 
            value={ assetDetails.deviceId } valueStyles={ style.assetsFieldLabel } />
          <ReadOnlyField label={ t("assetCard.model") } 
            value={ assetDetails.model } valueStyles={ style.assetsFieldLabel } />
          { dndProvided && (
            <AuthGuard actionType={ CommonAuthElement.MoveAsset }>
              <span
                className={ classNames(style.assetDragIcon, "assetDragIcon") }
                { ...(dndProvided ? dndProvided.dragHandleProps : "") }
              >
                <img alt={ t(DRAG_ICON_BASE_PATH.altText) } src={ DRAG_ICON_BASE_PATH.icon } />
              </span>
            </AuthGuard>
          ) }
        </span>

        <span className={ assetDetails.mute ? style.assetsFieldMuted : style.assetsField }>
          <ReadOnlyField
            label={ t("assetCard.sw") }
            value={ assetDetails.softwareVersion }
            valueStyles={ style.assetsFieldLabel }
          />
          <ReadOnlyField
            label={ t("assetCard.lastConnected") }
            value={ assetDetails.lastConnected ? formatDate(assetDetails.lastConnected, DATE_FORMAT.date) : "" }
            valueStyles={ style.assetsFieldLabel }
          />
        </span>
      </span>
    </PageLink>
  )
}

AssetCard.propTypes = {
  assetDetails: PropTypes.shape({
    assetNote: PropTypes.string,
    configFailedReason: PropTypes.string,
    configStatus: PropTypes.string,
    deviceId: PropTypes.string,
    id: PropTypes.string,
    lastConnected: PropTypes.string,
    model: PropTypes.string,
    mute: PropTypes.bool,
    name: PropTypes.string,
    severity: PropTypes.string,
    softwareVersion: PropTypes.string,
    swFailedReason: PropTypes.string,
    swStatus: PropTypes.string
  }),
  dndProvided: PropTypes.any,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  status: PropTypes.oneOf(["Offline", "Available", "In use", ""]).isRequired
}

export default AssetCard
