import i18n from "i18next"

import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../../utils/Constants/TimeZones"
import { formatDate } from "../../../../utils/helper"

const ReportsConfigTableColumnConfig = () => [
  {
    accessorKey: "deploymentDate",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    header: i18n.t("reports:configurationTab.tableHeaders.deploymentDate"),
    size: 135
  },
  {
    accessorKey: "configFile",
    header: i18n.t("reports:configurationTab.tableHeaders.name")
  },
  {
    accessorKey: "assetName",
    header: i18n.t("reports:configurationTab.tableHeaders.assetName")
  },
  {
    accessorKey: "serialNumber",
    header: i18n.t("reports:configurationTab.tableHeaders.serialNumber")
  },
  {
    accessorKey: "description",
    header: i18n.t("reports:configurationTab.tableHeaders.description")
  },
  {
    accessorKey: "lastUpdated",
    cell: ({ cell }) => formatDate(cell.getValue(), DATE_FORMAT.dateTime),
    defaultCanSort: true,
    header: i18n.t("reports:configurationTab.tableHeaders.lastUpdated"),
    sortDesc: true
  },
  {
    accessorKey: "user",
    header: i18n.t("reports:configurationTab.tableHeaders.user")
  },
  {
    accessorKey: "status",
    disableTooltip: true,
    header: i18n.t("reports:configurationTab.tableHeaders.status"),
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_CONFIG_STATUS,
    minSize: 140,
    size: 140
  }
]

export default ReportsConfigTableColumnConfig
