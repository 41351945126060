import { Button } from "@mui/material"
import { addDays, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./HeadSection.module.scss"

import AuthGuard from "../../../../../components/AuthGuard/AuthGuard"
import DateRangePicker from "../../../../../components/DateRangePicker/DateRangePicker"
import MultipleSelectDropdown from "../../../../../components/Dropdown/MultipleSelectDropdown"
import SingleSelectDropdown from "../../../../../components/Dropdown/SingleSelectDropdown"
import { ExportCSV } from "../../../../../components/ExportCSV/ExportCSV"
import Icon from "../../../../../components/Icon/Icon"
import SearchBar from "../../../../../components/SearchBar/SearchBar"
import { useReportsContext } from "../../../../../contexts/reports/reports"
import { EXPORT_CSV_TYPES } from "../../../../../utils/Constants/ExportCSVConstants"
import { SEARCH_INSTANCES } from "../../../../../utils/Constants/Search"
import { ICON_NAME } from "../../../../../utils/GlobalConstants"
import { transformDropdown } from "../../../ReportsUtils"

const HeadSection = ({
  handleGetReports,
  configurations,
  swPackage,
  exportAuthKey,
  value,
  handleSearch,
  searchInput,
  searchError,
  searchBarRef,
  tabs
}) => {
  const {
    modality,
    model,
    setModality,
    setModel,
    modalityOptions,
    modelOptions,
    isModelFetching,
    searchResult,
    reportsDateRange,
    setReportsDateRange,
    siteName,
    sectionTab,
    exportRef,
    exportData
  } = useReportsContext()
  const { t } = useTranslation(["reports"])
  const [endMaxDate, setEndMaxDate] = useState(reportsDateRange.maxDate)
  const disable =
    (configurations?.length === 0 || configurations === null) && (swPackage?.length === 0 || swPackage === null)
  const disableExportButton = Boolean(disable) || searchResult?.length === 0 || searchError

  const handleModalitySelect = (value) => {
    setModel?.([])
    setModality?.(value)
  }

  const searchResultText = () => {
    return `${t("configurationTab.searchAssetsCount", {
      searchResultsCount: searchResult.length
    })}`
  }

  const handleStartDateChange = (value) => {
    const startDate = new Date(value)
    const todaysDate = new Date()
    const oneYearPlus = addDays(startDate, 364)
    let finalEndDate = reportsDateRange.maxDate
    if (getDateOnlyFormat(oneYearPlus) < getDateOnlyFormat(todaysDate)) {
      finalEndDate = oneYearPlus
    }
    setEndMaxDate(finalEndDate)
    setReportsDateRange((prevState) => ({
      ...prevState,
      endDate: finalEndDate
    }))
  }

  const getDateOnlyFormat = (date) => {
    return setMilliseconds(setSeconds(setMinutes(setHours(new Date(date), 0), 0), 0), 0)
  }

  return (
    <div className={ styles.headerContainer }>
      <div className={ styles.dropDownContainer }>
        <div className={ styles.dropDown }>
          <SingleSelectDropdown
            options={ transformDropdown(modalityOptions) }
            value={ modality }
            width={ 164 }
            listWidth={ "20%" }
            placeholder={ t("tabs.dropDown.modality") }
            onChange={ (value) => handleModalitySelect(value) }
            data-testid="modality"
          />
          <MultipleSelectDropdown
            options={ (modality && !isModelFetching && transformDropdown(modelOptions)) || [] }
            value={ model }
            width={ 164 }
            listWidth={ "20%" }
            displayLabelText={ false }
            placeholder={ t("tabs.dropDown.model") }
            onChange={ (value) => setModel?.(value) }
            data-testid="model"
          />
        </div>
        <div className={ styles.calendarContainer }>
          <DateRangePicker
            startDate={ reportsDateRange.startDate }
            endDate={ reportsDateRange.endDate }
            endMaxDate={ endMaxDate }
            minDate={ reportsDateRange.minDate }
            onChange={ (value) => setReportsDateRange((prevState) => ({ ...prevState, ...value })) }
            startDateChange={ handleStartDateChange }
          />
        </div>
        <span className={ styles.inputWidth }>
          <SearchBar
            type={ value === 0 ? SEARCH_INSTANCES.reportsConfiguration : SEARCH_INSTANCES.reportsSWPackage }
            isDisabled={ Boolean(disable) }
            ref={ searchBarRef }
            onHandleSubmit={ handleSearch }
          />
          <div className={ styles.contentInfo }>
            { (searchResult || (searchError && searchInput?.length >= 3)) && (
              <span role={ "contentinfo" } className={ styles.searchResultsText }>
                { searchInput && searchInput.length >= 3 && searchResult?.length === 0
                  ? t("configurationTab.noSearchResult")
                  : searchResultText() }
              </span>
            ) }
          </div>
        </span>
      </div>
      <div className={ styles.rightPanel }>
        <Button
          variant="text"
          color="secondary"
          startIcon={ <Icon icon={ !modality ? ICON_NAME.reportDisabled : ICON_NAME.report } /> }
          onClick={ handleGetReports }
          disabled={ !modality }
        >
          { t("tabs.report") }
        </Button>
        <AuthGuard actionType={ exportAuthKey }>
          <ExportCSV
            asyncDownload
            ref={ exportRef }
            fileName={ 
              tabs[sectionTab]?.id === 1 ? `${siteName}-Report-Configuration` : `${siteName}-Report-SWpackage` }
            type={ tabs[sectionTab]?.id === 1 ? EXPORT_CSV_TYPES.REPORTS_CONFIG : EXPORT_CSV_TYPES.REPORTS_SW }
            isDisabled={ Boolean(disableExportButton) }
          >
            <Button
              onClick={ ()=>exportRef?.current?.downloadNewData?.(exportData) }
              data-testid="exportButton"
              variant="text"
              color="secondary"
              aria-label="Export"
              startIcon={ <Icon icon={ disableExportButton ? ICON_NAME.exportDisabled : ICON_NAME.export } /> }
              disabled={ Boolean(disableExportButton) }
            >
              { t("tabs.export") }
            </Button>
          </ExportCSV>
        </AuthGuard>
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  configurations: PropTypes.array,
  exportAuthKey: PropTypes.string,
  handleGetReports: PropTypes.func,
  handleSearch: PropTypes.func,
  searchBarRef: PropTypes.any,
  searchError: PropTypes.bool,
  searchInput: PropTypes.string,
  swPackage: PropTypes.array,
  tabs: PropTypes.array,
  value: PropTypes.number
}

export default HeadSection
