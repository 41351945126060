import PropTypes from "prop-types"
import React from "react"

import { headerData } from "./HeaderUtils"

import HeaderContainer from "../HeaderContainer/HeaderContainer"

const AppHeader = ({ isSignedIn, handleSignIn, helpIcon }) => {
  return (
    <HeaderContainer
      handleSignIn={ handleSignIn }
      headerData={ headerData }
      isSignedIn={ isSignedIn }
      helpIcon={ helpIcon }
    />
  )
}

AppHeader.propTypes = {
  handleSignIn: PropTypes.func,
  helpIcon: PropTypes.bool,
  isSignedIn: PropTypes.bool
}

export default AppHeader
