export const StorageType = {
  LOCAL_STORAGE: "localStorage",
  SESSION_STORAGE: "sessionStorage"
}

const localStorageMethods = {
  clearAllSession: () => {
    localStorage.clear()
  },
  clearSession: (key) => {
    localStorage.removeItem(key)
  },
  getSession: (key) => {
    return localStorage.getItem(key)
  },
  setSession: (key, value) => {
    localStorage.setItem(key, value)
  }
}

const sessionStorageMethods = {
  clearAllSession: () => {
    sessionStorage.clear()
  },
  clearSession: (key) => {
    sessionStorage.removeItem(key)
  },
  getSession: (key) => {
    return sessionStorage.getItem(key)
  },
  setSession: (key, value) => {
    sessionStorage.setItem(key, value)
  }
}

export const getSession = (key, type) => {
  return type === StorageType.SESSION_STORAGE
    ? sessionStorageMethods.getSession(key)
    : localStorageMethods.getSession(key)
}

export const setSession = (key, value, type) => {
  type === StorageType.SESSION_STORAGE
    ? sessionStorageMethods.setSession(key, value)
    : localStorageMethods.setSession(key, value)
}

export const clearSession = (key, type) => {
  type === StorageType.SESSION_STORAGE
    ? sessionStorageMethods.clearSession(key)
    : localStorageMethods.clearSession(key)
}

export const clearAllSession = (type) => {
  type === StorageType.SESSION_STORAGE
    ? sessionStorageMethods.clearAllSession()
    : localStorageMethods.clearAllSession()
}

export const StorageKeys = {
  ACCESS_TOKEN: "access_token",
  AUTH_STATE: "state",
  BREADCRUMB: "breadcrumb",
  CODE_VERIFIER: "code_verifier",
  CUSTOMER_ID: "customerId",
  FLEET_CREATE_FLEET: "fleetCreateFleet",
  FLEET_CREATE_FLEET_REF: "fleetCreateFleetRef",
  FLEET_GROUP_SELECTED_ASSETS: "fleetGroupBySelectedAssets",
  ID_TOKEN: "id_token",
  REFRESH_TOKEN: "refresh_token",
  SITE_CREATE_FLEET: "siteCreateFleet",
  SITE_CREATE_FLEET_REF: "siteCreateFleetRef",
  SITE_GROUP_SELECTED_ASSETS: "siteGroupBySelectedAssets",
  SITE_UCM_ID: "siteUcmId",
  USER_SESSION: "oauthSession"
}
