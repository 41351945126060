import PropTypes from "prop-types"
import React from "react"

import PieChartComponent from "../../../components/ChartStatisticCard/PieChartComponent"
import DistributionStatisticsCard from "../../../components/DistributionStatisticsCard/DistributionStatisticsCard"
import styles from "../AssetFaults.module.scss"

const AssetFaultStatistics = ({
  distributionData
}) => {
  return (
    <>
      { distributionData?.distributionData?.map((eachDistribution) => (
        <DistributionStatisticsCard
          key={ eachDistribution.type }
          { ...eachDistribution }
        />
      )) }
      <div className={ styles.faultsPiChartCard }>
        <PieChartComponent data={ distributionData?.pieChartData }/>
      </div>
    </>
  )
}

AssetFaultStatistics.propTypes = {
  distributionData: PropTypes.array.isRequired
}
  
export default AssetFaultStatistics
