import React from "react"

import FleetsSummary from "./FleetsSummary/FleetsSummary"
import SiteSummaryStatistics from "./SiteSummaryStatistics/SiteSummaryStatistics"
import style from "./SiteView.module.scss"

import ConfirmMoveFleet from "../../components/ConfirmMoveFleet/ConfirmMoveFleet"
import Error from "../../components/Error/Error"
import Loading from "../../components/Loading/Loading"
import MoveFleet from "../../components/MoveFleet/MoveFleet"
import { useSiteViewContext } from "../../contexts/siteView/SiteView"

const SiteViewContainer = () => {

  const { handleOpenMoveFleet, handleConfirmPopup, siteViewState, fleetSummaryRef, siteTranslation } =
    useSiteViewContext()

  const handleOnSubmit = (_data) => {
    handleConfirmPopup(_data)
    fleetSummaryRef?.current?.resetCheckboxSelection()
  }

  if (siteViewState?.isLoading) return <Loading />
  if (siteViewState?.isError) return <Error error={ { message: siteTranslation("groupByErrorMessage") } } />


  return (
    <>
      <div className={ style.staticCards }>
        <SiteSummaryStatistics distributionData={ siteViewState?.distributionData } />
      </div>
      <div className={ style.fleetsSummary }>
        <FleetsSummary fleets={ siteViewState?.contents } ref={ fleetSummaryRef } />
      </div>
      { siteViewState.moveFleet && (
        <MoveFleet
          isPopupOpen={ siteViewState.moveFleet }
          onCancel={ handleOpenMoveFleet }
          setIsConfirmPopUp={ handleConfirmPopup }
        />
      ) }
      <ConfirmMoveFleet
        popupStyles={ style.confirmationPopUp }
        selectedDevice={ siteViewState?.selectedAssets }
        onSubmit={ handleOnSubmit }
        onCancel={ (value) => handleConfirmPopup({ isOpen: value }) }
        { ...siteViewState?.confirmPopup }
      /> 
    </>
  )
}

export default SiteViewContainer
