import PropTypes from "prop-types"
import React from "react"

import style from "../../DataTable.module.scss"

const ColumnResizer = ({ header, isResizing }) => {
  const onMouseDown = (e) => {
    header.getResizeHandler()(e)
    e.stopPropagation()
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <span
      { ...{
        className: `${!isResizing && "resizerOnHover"} ${style.resizer} ${
          header.column.getIsResizing() ? style.isResizing : ""
        }`,
        onClick: (e) => e.stopPropagation(),
        onDoubleClick: () => header.column.resetSize(),
        onKeyDown: (e) => e.stopPropagation(),
        onMouseDown: onMouseDown,
        onTouchStart: onMouseDown
      } }
    >
      <div className={ style.resizerSpan } />
    </span>
  )
}

ColumnResizer.propTypes = {
  header: PropTypes.any,
  isResizing: PropTypes.any
}

export default ColumnResizer
