/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"

import { default as ErrorPage } from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import { generateToken } from "../../../services/idam/login"
import { fleetGetSVC, fleetSVCKeys } from "../../../services/reactQueries/fleetsvc"
import { ERROR_MESSAGE } from "../../../utils/Auth/auth"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { StorageKeys, StorageType, clearAllSession, setSession } from "../../../utils/SessionHelper/session"

const UserAuth = () => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    const authCode = searchParams.get("code")
    const authState = searchParams.get("state")
    if (authCode && authState) {
      getToken(authCode, authState)
    } else {
      handleLogout()
    }
  }, [])

  const getToken = async (authCode, authState) => {
    const { error: err } = await generateToken(authCode, authState)
    if (err) {
      if (err?.message === ERROR_MESSAGE.InvalidState) handleLogout()
      else setError(err)
    } else {
      try {
        const data = await queryClient.fetchQuery(
          [fleetSVCKeys.GET_SITES], fleetGetSVC.getSites
        )
        const customerId = data?.data?.customer?.id
        setSession(StorageKeys.CUSTOMER_ID, customerId)
        navigate(`/${ROUTE_CONFIG.CUSTOMER.routeName}/${customerId}`)
      } catch (error) {
        if (error?.status === 403) handleUnauthorized()
        else setError(error)
      }
    }
  }

  const handleLogout = () => {
    clearAllSession()
    clearAllSession(StorageType.SESSION_STORAGE)
    navigate(`/${ROUTE_CONFIG.LOGIN.routeName}`)
  }

  const handleUnauthorized = () => {
    navigate(`/${ROUTE_CONFIG.ACCESS_DENIED.routeName}`)
  }

  if (error) return <ErrorPage error={ error } />
  return <Loading />
}
  
export default UserAuth
