import { TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { differenceInDays, isAfter, isBefore } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import CalenderIcon from "./CalenderIcon"
import styles from "./DateRangePicker.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import { DEFAULT_DATE_RANGE } from "../../utils/Constants/AssetLogs"

const DateRangePicker = ({ startDate, endDate, minDate, maxDate, onChange, reset, endMaxDate, startDateChange }) => {
  const { t } = useTranslation(["asset"])

  const [startValue, setStartValue] = useState(startDate)
  const [endValue, setEndValue] = useState(endDate)
  const [isEndDateOpen, setIsEndDateOpen] = useState(false)
  const [isStartDateOpen, setIsStartDateOpen] = useState(false)
  const [previousState, setPreviousState] = useState(startDate)

  const ref = useRef(null)

  const { displayMediumErrorToast } = useToaster()

  useEffect(() => {
    setStartValue(startDate)
    setEndValue(endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  useEffect(() => {
    setEndValue(endDate)
  }, [endDate])

  const startHandleChange = (newValue) => {
    setStartValue(newValue)
    startDateChange?.(newValue)
    setIsEndDateOpen(true)
  }

  const endHandleChange = async (newValue) => {
    ref.current.ignoreClose = true
    if (isBefore(newValue, startValue)) {
      const msg = t("datePicker.endDateLessError")
      displayMediumErrorToast(msg)
      setEndValue(endDate)
    } else {
      setEndValue(newValue)
      handleSubmit(newValue)
    }
  }

  const handleOnclose = () => {
    if (!ref.current.ignoreClose) {
      const dateDifference = differenceInDays(previousState, startValue)
      if (dateDifference !== 0) {
        if (!isAfter(endValue, startValue) && isBefore(endValue, startValue)) {
          const msg = t("datePicker.endDateLessError")
          displayMediumErrorToast(msg)
        } else {
          setIsEndDateOpen(false)
          setPreviousState(startValue)
          handleSubmit(endValue)
        }
      }
    }
    setIsEndDateOpen(false)
    ref.current.ignoreClose = false
  }

  const handleSubmit = (_enddate) => {
    onChange?.({ endDate: _enddate, startDate: startValue })
    setIsEndDateOpen(false)
  }

  return (
    <LocalizationProvider dateAdapter={ AdapterDateFns }>
      <div className={ styles.datePicker }>
        <DesktopDatePicker
          shouldDisableYear={ () => true }
          value={ startValue }
          onClose={ () => {
            setIsEndDateOpen(true)
            setIsStartDateOpen(false)
          } }
          onOpen={ () => setIsStartDateOpen(true) }
          onChange={ startHandleChange }
          PopperProps={ {
            "data-testid": "startDate-popup",
            placement: "bottom"
          } }
          inputProps={ { "aria-label": "startDate-picker", readOnly: true } }
          InputAdornmentProps={ { "className": isStartDateOpen && !isEndDateOpen ? "Active_Datepicker" : "" } }
          minDate={ minDate }
          maxDate={ maxDate }
          components={ {
            OpenPickerIcon: CalenderIcon
          } }
          renderInput={ (params) => <TextField { ...params } /> }
        />
        <span className={ styles.separator }>{ t("logs.separator") }</span>
        <DesktopDatePicker
          shouldDisableYear={ () => true }
          ref={ ref }
          open={ isEndDateOpen }
          onOpen={ () => setIsEndDateOpen(true) }
          value={ endValue }
          onClose={ () => handleOnclose() }
          onChange={ (value) => endHandleChange(value) }
          minDate={ minDate }
          maxDate={ endMaxDate ?? maxDate }
          components={ {
            OpenPickerIcon: CalenderIcon
          } }
          inputProps={ { "aria-label": "endDate-picker", readOnly: true } }
          InputAdornmentProps={ { "className": isEndDateOpen && !isStartDateOpen ? "Active_Datepicker" : "" } }
          PopperProps={ {
            "data-testid": "endDate-popup",
            placement: "bottom"
          } }
          renderInput={ (params) => <TextField { ...params } /> }
        />
      </div>
    </LocalizationProvider>
  )
}

DateRangePicker.defaultProps = {
  dateInterval: 364,
  maxDate: DEFAULT_DATE_RANGE.maxDate,
  minDate: DEFAULT_DATE_RANGE.minDate
}

DateRangePicker.propTypes = {
  dateInterval: PropTypes.number,
  endDate: PropTypes.instanceOf(Date),
  endMaxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  reset: PropTypes.any,
  startDate: PropTypes.instanceOf(Date),
  startDateChange: PropTypes.func
}

export default DateRangePicker
