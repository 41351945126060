import InputBase from "@mui/material/InputBase"
import PropTypes from "prop-types"
import React, { useState } from "react"

import style from "./InputField.module.scss"
import ActionButtons from "./internals/ActionButtons"
import InputErrorPanel from "./internals/InputErrorPanel"

import useEscClickHandler from "../../utils/CustomHooks/useEscClickHandler"
import { COMPARE_VALUE, ICON_NAME } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const InputField = ({
  handleInputValidation,
  label,
  onSubmit,
  onCancel,
  loading,
  error,
  maxLength,
  focus,
  placeholder
}) => {
  const [value, setValue] = useState(label)

  const handleSubmit = (e) => {
    e?.preventDefault()
    if (!!value && !error) onSubmit?.(value)
  }

  const handleKeyUp = (e) => {
    handleInputValidation(e.target.value)
    if (e.key === COMPARE_VALUE.enter && e.target.value !== (label || "")) {
      handleSubmit(e)
    }
  }

  const handleCancel = (e) => {
    e?.preventDefault()
    onCancel?.(label)
  }

  useEscClickHandler({ onClickEsc: handleCancel })

  const getInputControls = () => {
    if (error) {
      return <InputErrorPanel error={ error } />
    } else {
      return <ActionButtons defVal={ label } value={ value } onSubmit={ handleSubmit } onCancel={ handleCancel } />
    }
  }

  return (
    <span className={ error ? style.invalidInput : style.inputField }>
      <InputBase
        defaultValue={ label }
        onKeyUp={ handleKeyUp }
        onChange={ (event) => setValue(event.target.value) }
        inputProps={ {
          "aria-label": "InputField",
          maxLength: maxLength + 1
        } }
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ focus }
        onClick={ (event) => event.preventDefault() }
        placeholder={ placeholder }
      />
      { loading ? <Icon icon={ ICON_NAME.smallLoader } iconStyles={ style.loadingIcon } /> : getInputControls() }
    </span>
  )
}

InputField.propTypes = {
  error: PropTypes.string,
  focus: PropTypes.bool,
  handleInputValidation: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  maxLength: PropTypes.number,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string
}

export default InputField
