import OutlinedInput from "@mui/material/OutlinedInput"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import style from "./EditAssetDetailsPopup.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import { fleetMutationSVC } from "../../services/reactQueries/fleetsvc"
import { ALL_DUPLICATE_NAME_ERROR_CODES, FLEET_SERVICE_ERRORS } from "../../utils/Constants/ErrorCodes"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import {
  CANCEL,
  COMPARE_VALUE,
  EDIT_OVERLAY_CHAR_LENGTH,
  EDIT_OVERLAY_DEFAULT_MIN_CHARACTERS,
  EDIT_OVERLAY_ERROR_MESSAGE,
  EDIT_OVERLAY_GENERAL_INFO,
  ERROR_MESSAGE,
  SAVE,
  SEARCH_DEFAULT_MIN_CHARACTERS,
  TEXT_FIELD_MAX_CHAR,
  UPDATE,
  specialCharactersNotAllowed,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import ErrorInfo from "../CreateUpdateLabelPopup/Internal/ErrorInfo/ErrorInfo"
import { PAYLOAD_DATA } from "../EditableLabel/EditableLabelUtils"

const UPDATE_MUTATIONS = {
  assetName: fleetMutationSVC.updateAssetName
}

const EditAssetDetailsPopup = ({
  dataId,
  isEditPopupOpen,
  setIsEditPopupOpen,
  popupStyles,
  inputStyle,
  queryKey,
  ...props
}) => {
  const { t } = useTranslation("asset")
  const { name, notes, isOpen, type: getType } = isEditPopupOpen
  const { action, type } = getType
  const [inputName, setInputName] = useState(name)
  const [inputNotes, setInputNotes] = useState(notes)
  const [error, setError] = useState(null)
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()
  const inputRef = useRef(null)

  useEffect(() => {
    setInputName(name)
    setInputNotes(notes)
    inputRef?.current?.focus()
  }, [name, notes])

  const handleCancel = () => {
    setInputName(name)
    setInputNotes(notes)
    setIsEditPopupOpen({ isOpen: false })
  }

  const onMutationComplete = () => {
    displaySuccessToast(t("editAssetNamePopUp.successfulToasterMessage"))
    setError(null)
    setIsEditPopupOpen({ isOpen: false })
  }

  const onMutationError = (err) => {
    if (ALL_DUPLICATE_NAME_ERROR_CODES.includes(err?.data?.error?.code)) {
      setError({ error: true, message: ERROR_MESSAGE[type].uniqueName })
    } else if (FLEET_SERVICE_ERRORS.FORBIDDEN_OR_INSUFFICIENT_PERMISSIONS.includes(err?.data?.error?.code)) {
      setError({ error: true, message: ERROR_MESSAGE[type].accessError })
    } else {
      const errorMessage = action === UPDATE && ERROR_MESSAGE[type].updateError
      displayMediumErrorToast(errorMessage)
      setIsEditPopupOpen({ isOpen: false })
    }
  }

  const { requestMutation: updateField, isLoading } = useMutationWithHandlers({
    onCompletedCallback: onMutationComplete,
    onErrorCallback: onMutationError,
    queryFn: UPDATE_MUTATIONS[type],
    refetchQueries: [{ id: queryKey[1], key: queryKey[0] }]
  })

  const isSaveButtonEnabled = () => {
    return action === UPDATE
      ? Boolean(
        inputName?.trim().length < SEARCH_DEFAULT_MIN_CHARACTERS ||
            (name === inputName?.trim() && notes === inputNotes?.trim()) ||
            error?.error
      )
      : false
  }

  const handleSubmit = (value, type, isEnter) => {
    const trimmedValue = value
      .split(" ")
      .filter((e) => e.replaceAll(" ", ""))
      .join(" ")
    if (trimmedValue.length <= (props.minCharacters || EDIT_OVERLAY_DEFAULT_MIN_CHARACTERS)) {
      setError({
        error: true,
        message: EDIT_OVERLAY_ERROR_MESSAGE[type].emptyText
      })
    } else if (
      trimmedValue
        .split("")
        .map((ele) => specialCharactersNotAllowed.includes(ele))
        .includes(true)
    ) {
      setError({
        error: true,
        message: EDIT_OVERLAY_ERROR_MESSAGE[type].specialCharactersNotAllowed
      })
    } else if (value.length > EDIT_OVERLAY_CHAR_LENGTH[type]) {
      setError({
        error: true,
        message: EDIT_OVERLAY_ERROR_MESSAGE[type].characterLength
      })
    } else {
      setError(null)
      if (!checkUpdateButtonEnableState()) {
        if (isEnter && !(action === UPDATE ? !name || name === inputName?.trim() || error?.error : "")) {
          handleSave(trimmedValue)
        }
      }
    }
  }

  const checkUpdateButtonEnableState = () => {
    return inputName.trim().length < SEARCH_DEFAULT_MIN_CHARACTERS || name === inputName.trim() || error?.error
  }

  const handleSave = (value, assetNotes) => {
    const trimmedValue = value
      .split(" ")
      .filter((e) => e.replaceAll(" ", ""))
      .join(" ")
    const trimmedNotesValue = (assetNotes ?? "").replace(/\s+/g, " ")?.trim()
    setInputName(trimmedValue)
    setInputNotes(trimmedNotesValue)
    if (action === UPDATE) {
      updateField({
        payload: {
          id: dataId,
          variables: PAYLOAD_DATA[type](trimmedValue, trimmedNotesValue)
        },
        showToast: false
      })
    }
  }

  const handleOnChangeTextMsg = (event) => {
    const value = event.target.value.replace(specialCharactersNotAllowedForSearch, "")
    let msg = removeEmoji(value)
    setInputNotes(msg)
  }

  const handleOnChange = (event) => {
    const value = event.target.value
    handleSubmit(value, type)
    let msg = removeEmoji(value)
    setInputName(msg)
  }

  return (
    <ConfirmationPopup
      popupStyles={ popupStyles }
      heading={ t("editAssetNamePopUp.popUpHeading") }
      isOpen={ isOpen }
      isLoading={ isLoading }
      confirm={ SAVE }
      isConfirmEnable={ isSaveButtonEnabled() }
      cancel={ CANCEL }
      onSubmit={ () => handleSave(inputName, inputNotes) }
      onCancel={ handleCancel }
    >
      <div className={ style.textFieldContainer }>
        <div className={ style.assetName }>{ t("editAssetNamePopUp.assetName") }</div>
        <OutlinedInput
          className={ classNames("overlay", error ? "error" : "noError") }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={ true }
          inputRef={ inputRef }
          inputProps={ {
            "aria-label": "InputField",
            maxLength: EDIT_OVERLAY_CHAR_LENGTH[type] + 1
          } }
          sx={ { ...inputStyle } }
          value={ inputName }
          onChange={ handleOnChange }
          onKeyUp={ () => handleSubmit(inputName, type) }
          onKeyDown={ (e) => e.key === COMPARE_VALUE.enter && handleSubmit(e.target.value, type, true) }
        />
        <span className={ style.infoContainer }>
          { error?.error ? (
            <ErrorInfo message={ error.message } />
          ) : (
            EDIT_OVERLAY_GENERAL_INFO[type].split(".").join(".\n")
          ) }
        </span>
        <div className={ style.addNote }>{ t("editAssetNamePopUp.addNote") }</div>
        <textarea
          className={ classNames("textArea", style.textField) }
          maxLength={ TEXT_FIELD_MAX_CHAR }
          value={ inputNotes }
          onChange={ handleOnChangeTextMsg }
        />
        <div className={ style.instructionText }>
          { t("editAssetNamePopUp.maxLength", {
            maxLength: TEXT_FIELD_MAX_CHAR
          }) }
        </div>
      </div>
    </ConfirmationPopup>
  )
}

EditAssetDetailsPopup.propTypes = {
  dataId: PropTypes.string,
  handleNewFleetSubmitted: PropTypes.func,
  ignoreRefetch: PropTypes.bool,
  inputStyle: PropTypes.string,
  isClose: PropTypes.func,
  isEditPopupOpen: PropTypes.object,
  minCharacters: PropTypes.number,
  popupStyles: PropTypes.string,
  queryKey: PropTypes.array,
  setIsEditPopupOpen: PropTypes.func
}

export default EditAssetDetailsPopup
