export const aboutReducer = (newState, action) => {
  switch (action.type) {
  case "close":
    newState.onClose()
    return { ...newState, ...action.payload }
  case "addToast":
    return { ...newState, ...{ isOpen: true }, ...action.payload }
    /* istanbul ignore next */
  default:
    return newState
  }
}
  
export const ABOUT_INITIAL_STATE = {
  isOpen: false,
  onClose: () => {
    return
  },
  type: ""
}
    
  
