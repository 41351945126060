import margins from "../../styles/jsExports/marginExport.module.scss"

export const Snackbar = {
  styleOverrides: {
    root: {
      "& .MuiPaper-root": {
        "& .MuiButtonBase-root": {
          padding:0
        },
        "& .MuiButtonBase-root:hover": {
          backgroundColor: "transparent"
        },
        "& .MuiSnackbarContent-action": {
          gap: margins["marginInPixel-px24"],
          height: "auto",
          justifyContent: "space-between",
          marginRight: 0,
          paddingLeft: 0,
          width: margins["marginInPercentage-per100"]
        },
        minWidth: margins["marginInPercentage-per100"]
      },
      "&.MuiSnackbar-root": {
        gap: margins["marginInPixel-px24"],
        height: margins["marginInPixel-px48"],
        right: margins["marginInPixel-px8"],
        top: margins["marginInPixel-px54"],
        width: margins["marginInPixel-px480"]
      }
    }
  }
}
