import React from "react"

const CalenderIcon = (props) => {
  return (
    <svg
      { ...props }
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M5 10H3V8H5V10ZM5 11H3V13H5V11ZM12 8H10V10H12V8ZM12 11H10V13H12V11ZM8.5 8H6.5V10H8.5V8ZM8.5 11H6.5V13H8.5V11ZM15 4V14C15 15.1 14.1 16 13 16H2C0.9 16 0 15.1 0 14V4C0 2.9 0.9 2 2 2H3V0.5C3 0.2 3.2 0 3.5 0C3.8 0 4 0.2 4 0.5V2H11V0.5C11 0.2 11.2 0 11.5 0C11.8 0 12 0.2 12 0.5V2H13C14.1 2 15 2.9 15 4ZM14 14V6H1V14C1 14.6 1.4 15 2 15H13C13.6 15 14 14.6 14 14Z"
        fill="#2B2D2E"
      />
    </svg>
  )
}

export default CalenderIcon
