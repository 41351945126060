import { InputBase } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./PromoteDemotePopUp.module.scss"

import {
  CANCEL,
  DEMOTE,
  OK,
  PROMOTE,
  TEXT_FIELD_MAX_CHAR,
  specialCharactersNotAllowedForSearch
} from "../../utils/GlobalConstants"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"

const PromoteDemotePopUp = ({
  message,
  type,
  handleSubmit,
  handleCancel,
  isOpen,
  name
}) => {
  const { t } = useTranslation("assetFault")
  const [note, setNote] = useState("")
  const popType = type === PROMOTE ? PROMOTE : DEMOTE

  useEffect(() => {
    setNote("")
  }, [isOpen])

  const handleOnChangeTextMsg = (event) => {
    const value = event.target.value.replace(specialCharactersNotAllowedForSearch, "")
    let msg = removeEmoji(value)
    setNote(msg)
  }

  return (
    <ConfirmationPopup
      popupStyles={ styles.popupStyles }
      heading={ popType }
      isOpen={ isOpen }
      confirm={ OK }
      cancel={ CANCEL }
      onSubmit={ () => handleSubmit(note) }
      onCancel={ handleCancel }
    >
      { message && <div className={ styles.messageContainer }>{ message }</div> }
      { type === PROMOTE ? (
        <>
          <div
            className={
              type === PROMOTE ? styles.popupHeader : styles.popupDemoteHeader
            }
          >
            { t("promoteAndDemote.configName") }
          </div>
          <InputBase
            inputProps={ { "aria-label": "InputField" } }
            className="promoteInput"
            defaultValue={ name }
            disabled
          />
        </>
      ) : (
        ""
      ) }
      <div className={ styles.textFieldContainer }>
        <div className={ styles.addNote }>{ t("promoteAndDemote.addNote") }</div>
        <textarea
          className={  classNames("textArea", styles.textField) }
          maxLength={ TEXT_FIELD_MAX_CHAR }
          value={ note }
          onChange={ handleOnChangeTextMsg }
          aria-label="Add note"
        />
        <div className={ styles.instructionText }>
          { t("promoteAndDemote.maxLength", { maxLength: TEXT_FIELD_MAX_CHAR }) }
        </div>
      </div>
    </ConfirmationPopup>
  )
}

PromoteDemotePopUp.propTypes = {
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string
}

export default PromoteDemotePopUp
