import React from "react"
import { useTranslation } from "react-i18next"
 
import styles from "./NoFleet.module.scss"

const NoFleets = () => {
  const { t } = useTranslation(["fleet"])
  return (
    <div className={ styles.noFleetsDetails }>
      <span className={ styles.title }>{ t("noFleet.title") }</span>
      <span className={ styles.message }>{ t("noFleet.message") }</span>
    </div>
  )
}

export default NoFleets
