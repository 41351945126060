import colors from "../../../utils/styles/jsExports/colorExport.module.scss"

export const getSubFleetListStyle = (isDraggingOver) => {
  if (isDraggingOver) {
    return {
      backgroundColor: colors["white"],
      border: `1px solid ${colors["secondary"]}`,
      borderRadius: "8px",
      marginBottom: "8px",
      maxHeight: "52px",
      position: "relative"
    }
  }
  return { marginBottom: "8px" }
}

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  backgroundColor: colors["white"],
  borderRadius: "8px",
  boxShadow: isDragging ? `0 3px 8px 0 ${colors["dragAssetBoxShadow"]}` : "unset",
  userSelect: "none",
  width: isDragging ? "calc(25% - 7px)" : "",

  ...draggableStyle
})
