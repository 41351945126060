import PropTypes from "prop-types"
import React, { createRef, forwardRef, useImperativeHandle, useRef, useState } from "react"

import AssetFaultAccordion from "./AssetFaultAccordion"
import HeadSection from "./internal/HeadSection"

import Loading from "../../../components/Loading/Loading"
import SearchErrorPage from "../../../components/SearchErrorPage/SearchErrorPage"
import { DEFAULT_DATE_RANGE } from "../../../utils/Constants/AssetLogs"
import styles from "../AssetFaults.module.scss"
import { useAssetFaultsContext } from "../Context/AssetFaultsContext"

// eslint-disable-next-line react/display-name
const AssetFaultSummary = forwardRef(({ assetSummary, handleSelectedAssets, selectedAssets, setIsFilterOpen }, ref) => {
  const { filter, t, getFaultsQueryResults, state } = useAssetFaultsContext()
  const { isSuccess, isFetching: isLoading } = getFaultsQueryResults
  const assetContainerRef = useRef([])
  assetContainerRef.current = assetSummary.map((_, i) => assetContainerRef.current[i] ?? createRef())

  useImperativeHandle(ref, () => ({
    resetCheckboxSelection() {
      resetTableSelection()
    }
  }))

  const resetTableSelection = () => {
    assetContainerRef?.current?.forEach((item) => item?.current?.resetSelection(false))
  }

  const [logsDateRange, setLogsDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })

  return (
    <>
      <HeadSection
        logsDateRange={ logsDateRange }
        setLogsDateRange={ setLogsDateRange }
        selectedAssets={ selectedAssets }
        setIsFilterOpen={ setIsFilterOpen }
        handleFilterUpdate={ resetTableSelection }
        assetSummary={ assetSummary }
      />
      <div className={ styles.assetFaultsSummary }>
        { isLoading && <Loading /> }

        { isSuccess && !state.searchError && !state.searchString && !isLoading && assetSummary.length === 0 && (
          <div className={ styles.noFaults }>{ t("noFaultsMessage") }</div>
        ) }

        { !isLoading && state.searchError && <SearchErrorPage /> }

        { Object.keys(filter).length > 0 &&
          !state.searchError &&
          !isLoading &&
          assetSummary.map((item, i) => {
            return (
              <AssetFaultAccordion
                key={ item.title }
                id={ i }
                faultsDetails={ item }
                selectedAssets={ selectedAssets }
                handleSelectedAssets={ handleSelectedAssets }
                ref={ assetContainerRef.current[i] }
                handleResetTableSelection={ resetTableSelection }
              />
            )
          }) }
      </div>
    </>
  )
})

AssetFaultSummary.propTypes = {
  assetSummary: PropTypes.arrayOf(
    PropTypes.shape({
      assets: PropTypes.array,
      id: PropTypes.string,
      severityName: PropTypes.string
    })
  ),
  handleSelectedAssets: PropTypes.func,
  selectedAssets: PropTypes.array,
  setIsFilterOpen: PropTypes.func
}

export default AssetFaultSummary
