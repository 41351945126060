import { IconButton, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./AssetSummary.module.scss"
import { ASSET_PROPERTIES } from "./AssetSummaryConstants"

import { modelImage } from "../../pages/AssetView/AssetUtils"
import { AssetAuthElement } from "../../utils/Constants/Auth/asset"
import useAccess from "../../utils/CustomHooks/useAccess"
import { ASSET_DETAILS, DEFAULT_IMAGE, ICON_NAME, MUTE, UNMUTE } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import AlertSeverity from "../AssetAlertsSeverity/AlertSeverity"
import AssetStatusIndicator from "../AssetStatusIndicator/AssetStatusIndicator"
import AuthGuard from "../AuthGuard/AuthGuard"
import Icon from "../Icon/Icon"
import IconLabel from "../IconLabel/IconLabel"
import MuteUnmutePopup from "../MuteUnmutePopup/MuteUnmutePopup"
import NotificationBar from "../NotificationBar/NotificationBar"
import ReadOnlyField from "../ReadOnlyField/ReadOnlyField"
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch"

const AssetSummary = ({
  assetDetails,
  displayEmptyProperties,
  summaryCardStyles,
  handleEditAssetDetails,
  muted
}) => {
  const { t } = useTranslation(["asset"])
  const hasMuteUnMuteAccess = useAccess(assetDetails.muted ? AssetAuthElement.UnMuteAsset : AssetAuthElement.MuteAsset)
  const [assetImage, setAssetImage] = useState(modelImage(assetDetails.assetImage))

  const hideDisableDeviceModelList = process.env.REACT_APP_HIDE_DISABLE_DEVICE_MODELS
  const formatedDeviceModelList = hideDisableDeviceModelList?.split(",")?.map(each => each?.toLowerCase()?.trim())
  const hideMuteUnMuteButton = formatedDeviceModelList?.includes(assetDetails?.model?.toLowerCase())

  useEffect(() => {
    const image = new Image()
    image.src = assetImage
    image.onload = () => {}
    image.onerror = () => {
      setAssetImage(DEFAULT_IMAGE)
    }
  }, [assetImage])

  const intialState = {
    isOpen: false,
    message: "",
    type: ""
  }

  const [muteUnmutePopup, setMuteUnmutePopup] = useState(intialState)
  const [openNotification, setOpenNotification] = useState(false)
  const handleToggleClick = (isChecked) => {
    if (isChecked) {
      setMuteUnmutePopup({
        ...muteUnmutePopup,
        isOpen: true,
        message: t("muteUnmuteMessage.mute"),
        type: MUTE
      })
    } else {
      setMuteUnmutePopup({
        ...muteUnmutePopup,
        isOpen: true,
        message: t("muteUnmuteMessage.unMute"),
        type: UNMUTE
      })
    }
  }

  const handleSubmit = ({ note }) => {
    setMuteUnmutePopup(intialState)
    setOpenNotification((prevState) => {
      return { ...prevState, isOpen: true, note }
    })
  }
  return (
    <div className={ muted ? "assetMutedBar" : undefined }>
      { assetDetails.muted && (
        <NotificationBar
          isOpen={ assetDetails.muted }
          mutedDate={ assetDetails.mutedDate }
          notificationMsg={ assetDetails.latestMuteNote || openNotification?.note || "" }
        />
      ) }
      <div className={ classNames(styles.assetDetailsHeader, summaryCardStyles) }>
        <div className={ styles.itemImage }>
          <img className={ styles.imageStyles } alt="Asset" src={ assetImage } />
        </div>
        <div className={ styles.detailsSection }>
          <div className={ styles.assetTitleSection }>
            <div className={ styles.assetTitleSectionLeft }>
              <IconLabel
                label={ assetDetails.name }
                labelStyles={ styles.assetName }
                displayAssetNameTooltip={ true }
                note={ assetDetails.assetNote }
              />
              { assetDetails.severity && <AlertSeverity severity={ assetDetails.severity } /> }
            </div>
            <div className={ styles.assetTitleSectionActions }>
              {
                hasMuteUnMuteAccess && !hideMuteUnMuteButton &&
                (
                  <ToggleSwitch
                    labelIcon={ ASSET_DETAILS.muteIcon }
                    isChecked={ assetDetails.muted }
                    label={ ASSET_DETAILS.mute }
                    handleOnClick={ handleToggleClick }
                    displayIconTooltip={ !assetDetails.muted }
                    iconTooltipLabel={ t("muteUnmuteMessage.disableMuteTooltip") }
                  />
                )
              }
              <AuthGuard actionType={ AssetAuthElement.Properties.UpdateAssetProperty }>
                <Tooltip arrow title={ t("editIconTooltip") } classes={ { tooltip: "editTooltip" } }>
                  <IconButton className={ styles.editButton } onClick={ handleEditAssetDetails } disableRipple>
                    <Icon icon={ ICON_NAME.editPurple } iconStyles={ styles.editIcon } />
                  </IconButton>
                </Tooltip>
              </AuthGuard>
            </div>
          </div>
          <div className={ styles.assetInformation }>
            <div className={ styles.spaceBetweenProperties }>
              <ReadOnlyField
                valueStyles={ styles.properties }
                labelStyles={ styles.properties }
                label={ t("assetSummaryLabels.id") }
                value={ assetDetails.id }
              />
            </div>
            <div className={ styles.spaceBetweenProperties }>
              <ReadOnlyField
                valueStyles={ styles.properties }
                labelStyles={ styles.properties }
                label={ t("assetSummaryLabels.status") }
                value={
                  <AssetStatusIndicator
                    displayStatusText
                    className={ styles.assetStatusIndicator }
                    status={ assetDetails.status }
                  />
                }
              />
            </div>
          </div>
          <div className={ styles.assetInformation }>
            { Object.entries(ASSET_PROPERTIES).map((eachPropertyLabelAndKey) => {
              if (!displayEmptyProperties && !assetDetails[eachPropertyLabelAndKey[1]]) {
                return null
              }
              return (
                <div key={ eachPropertyLabelAndKey[1] } className={ styles.spaceBetweenProperties }>
                  <ReadOnlyField
                    valueStyles={ styles.properties }
                    labelStyles={ styles.properties }
                    label={ t(`assetSummaryLabels.${eachPropertyLabelAndKey[1]}`) }
                    value={ assetDetails[eachPropertyLabelAndKey[1]] }
                  />
                </div>
              )
            }) }
          </div>
          <MuteUnmutePopup
            isOpen={ muteUnmutePopup.isOpen }
            popupHeaderMsg={ muteUnmutePopup.message }
            type={ muteUnmutePopup.type }
            onCancel={ () => setMuteUnmutePopup(intialState) }
            onSubmit={ handleSubmit }
            assetDetails={ assetDetails }
          />
        </div>
      </div>
    </div>
  )
}

AssetSummary.propTypes = {
  assetDetails: PropTypes.shape({
    assetId: PropTypes.string,
    assetImage: PropTypes.string,
    assetNote: PropTypes.string,
    fleet: PropTypes.string,
    hardwareVersion: PropTypes.string,
    id: PropTypes.string,
    ipAddress: PropTypes.string,
    lastDeployedConfig: PropTypes.string,
    lastLocation: PropTypes.string,
    latestMuteNote: PropTypes.string,
    modality: PropTypes.string,
    model: PropTypes.string,
    muted: PropTypes.bool,
    mutedDate: PropTypes.string,
    name: PropTypes.string,
    severity: PropTypes.oneOf(["low", "moderate", "critical", "NOT_IN_PAYLOAD", ""]).isRequired,
    softwareVersion: PropTypes.string,
    status: PropTypes.oneOf(["Offline", "Available", "In use", ""]).isRequired
  }),
  displayEmptyProperties: PropTypes.bool,
  handleEditAssetDetails: PropTypes.func,
  muted: PropTypes.bool,
  summaryCardStyles: PropTypes.any
}

export default AssetSummary
