/* eslint-disable sort-keys */
export const ASSET_PROPERTIES = {
  "Fleet": "fleet",
  "Model": "model",
  "Modality": "modality",
  "SW ver.": "softwareVersion",
  "HW ver.": "hardwareVersion",
  "IP address": "ipAddress",
  "Last location": "lastLocation",
  "Last deployed config": "lastDeployedConfig",
  "Last connected": "lastConnected"
}
export const STATUS_INDICATOR_FIELD = "status"
