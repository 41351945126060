import React from "react"

import { BREADCRUMB_ROUTE } from "../../../../components/Breadcrumb/BreadcrumbUtils"
import FleetExpandedCard from "../../../../components/FleetExpandedCard/FleetExpandedCard"
import PageLink from "../../../../components/PageLink/PageLink"
import { useSiteViewContext } from "../../../../contexts/siteView/SiteView"
import { ROUTE_CONFIG } from "../../../../utils/GlobalConstants"
import cssStyles from "../FleetsSummary.module.scss"

const GroupByModel = () => {
  const currentURL = window.location.pathname
  const { siteViewState } = useSiteViewContext()

  const models = siteViewState?.contents
  return (
    <div className={ cssStyles.groupByCards }>
      { models?.map((type) => {
        return (
          <PageLink
            key={ type?.[siteViewState.groupByMappingObject.titleKey] }
            className={ cssStyles.removeLinkStyles }
            to={ currentURL.concat(
              `/${ROUTE_CONFIG.SITE_ASSET_TYPE.routeName}/${type?.[siteViewState.groupByMappingObject.titleKey]}`
            ) }
            type={ BREADCRUMB_ROUTE.SITE_ASSET_TYPE }
            pageName={ type?.[siteViewState.groupByMappingObject.titleKey] }
          >
            <FleetExpandedCard
              titleKey={ siteViewState.groupByMappingObject.titleKey }
              fleetDetails={ type }
              isConfirmPopUp={ siteViewState?.confirmPopup }
              showCardAction={ false }
            />
          </PageLink>
        )
      }) }
    </div>
  )
}

export default GroupByModel
