import { ICON_NAME } from "../GlobalConstants"

export const FEATURES_DATA = [
  {
    desc: "Remotely access real-time device data, including fault logs, for quicker diagnosis and repair.",
    icon: ICON_NAME.monitorBlue,
    id: 1,
    name: "Proactive maintenance"
  },
  {
    // eslint-disable-next-line max-len
    desc: "Deploy immediate or scheduled firmware and software updates by care setting for more efficiency and flexibility.",
    icon: ICON_NAME.cloudBlue,
    id: 2,
    name: "Validate & control updates"
  },
  {
    desc: "Centrally administer or backup configurations by care area to enable a consistent user experience.",
    icon: ICON_NAME.settingsBlue,
    id: 3,
    name: "Configuration management"
  },
  {
    desc: "Peace of mind that device data and communications are secure.",
    icon: ICON_NAME.securityBlue,
    id: 4,
    name: "Strong security"
  }
]

export const SOLUTION_DATA = [
  {
    desc: "Improve time spent troubleshooting devices",
    icon: ICON_NAME.topArrow,
    id: 1
  },
  {
    desc: "Faster response time to identified security risks",
    icon: ICON_NAME.security,
    id: 2
  },
  {
    desc: "Reduce Interruptions to patient care",
    icon: ICON_NAME.healthCare,
    id: 3
  }
]

export const NavItems = [
  {
    key: "solution",
    labelKey: "solution"
  },
  {
    key: "features",
    labelKey: "features"
  },
  {
    key: "contact_us",
    labelKey: "contactUs"
  }
]

export const FooterItem = [
  {
    id: 1,
    label: "Accessibility"
  },
  {
    id: 2,
    label: "Contact"
  },
  {
    id: 3,
    label: "Terms of use"
  },
  {
    id: 4,
    label: "Privacy"
  },
  {
    id: 5,
    label: "Cookies"
  },
  {
    id: 6,
    label: "Github"
  },
  {
    id: 7,
    label: "Legal"
  }
]
