import PropTypes from "prop-types"
import React from "react"

import { BREADCRUMB_ROUTE } from "../../../components/Breadcrumb/BreadcrumbUtils"
import FleetExpandedCard from "../../../components/FleetExpandedCard/FleetExpandedCard"
import PageLink from "../../../components/PageLink/PageLink"
import { useFleetViewContext } from "../../../contexts/fleetView/FleetView"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"
import style from "../FleetView.module.scss"
import cssStyles from "../SubFleetsSummary/SubFleetsSummary.module.scss"

const ModalityType = ({ modalities }) => {
  const currentURL = window.location.pathname
  const { fleetViewState: state } = useFleetViewContext()

  return (
    <div className={ classNames(style.fleets, cssStyles.groupByCards) }>
      { modalities?.map((modality) => {
        return (
          <PageLink
            key={ modality?.modality }
            className={ cssStyles.removeLinkStyles }
            to={ currentURL.concat(
              `/${ROUTE_CONFIG.FLEET_MODALITY.routeName}/${modality.modality}`
            ) }
            type={ BREADCRUMB_ROUTE.FLEET_MODALITY }
            pageName={ modality?.modality }
          >
            <FleetExpandedCard
              titleKey={ state.groupByMappingObject.titleKey }
              fleetDetails={ modality }
              showCardAction={ false }
            />
          </PageLink>
        )
      }) }
    </div>
  )
}

ModalityType.propTypes = {
  modalities: PropTypes.array
}

export default ModalityType
