import jwt_decode from "jwt-decode"

import { checkTokenIsExpired } from "./utils"

import { renewUserSession } from "../../services/idam/login"
import { StorageKeys, getSession, setSession } from "../SessionHelper/session"

export const parseJwt = (token) => {
  try {
    return jwt_decode(token)
  } catch (e) {
    return null
  }
}

let tokenRefreshInProgress = false
export const validateTokenExpiration = async () => {
  if (tokenRefreshInProgress) {
    return { status: REFRESH_TOKEN_RES.REFRESHING }
  }
  if (checkTokenIsExpired()) {
    tokenRefreshInProgress = true
    const res = await renewUserSession()
    tokenRefreshInProgress = false
    return { data: res, status: REFRESH_TOKEN_RES.REFRESHED }
  } else {
    return { status: REFRESH_TOKEN_RES.VALID_TOKEN }
  }
}

export const REFRESH_TOKEN_RES = {
  REFRESHED: "REFRESHED",
  REFRESHING: "REFRESHING",
  VALID_TOKEN: "VALID_TOKEN"
}

export const getUserSession = () => {
  let tokenInfo
  try {
    tokenInfo = JSON.parse(getSession(StorageKeys.USER_SESSION))
  } catch(e) {
    tokenInfo = {}
  }
  return {
    accessToken: tokenInfo?.[StorageKeys.ACCESS_TOKEN],
    idToken: tokenInfo?.[StorageKeys.ID_TOKEN],
    refreshToken: tokenInfo?.[StorageKeys.REFRESH_TOKEN]
  }
}

export const setUserSession = (data) => {
  const tokenInfo = {
    [StorageKeys.ACCESS_TOKEN]: data?.access_token,
    [StorageKeys.ID_TOKEN]: data?.id_token,
    [StorageKeys.REFRESH_TOKEN]: data?.refresh_token
  }
  setSession(StorageKeys.USER_SESSION, JSON.stringify(tokenInfo))
}
