import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

import styles from "./MenuListItem.module.scss"

import { getApplicationRoutes } from "../../../utils/Common/common"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"

const MenuListItem = ({ isMenuExpanded, menuItem, isActive, disabled, onClickMenuItem }) => {
  const { t } = useTranslation(["userMenu"])
  const queryParams = useParams()
  const CUSTOMER_ROUTE = `/${ROUTE_CONFIG.CUSTOMER.routeName}/${
    queryParams[ROUTE_CONFIG.CUSTOMER.param]
  }`
  const appRoutes = getApplicationRoutes(queryParams)
  const navigationURL = appRoutes[appRoutes.length - 1]?.route

  const route =
    menuItem.routePath === `/${ROUTE_CONFIG.CUSTOMER.routeName}`
      ? CUSTOMER_ROUTE
      : navigationURL?.concat(menuItem.routePath)

  return (
    <div
      role={ "listitem" }
      className={ classNames(
        styles.menuItem,
        isMenuExpanded && styles.expandedMenu,
        isActive && styles.active,
        disabled && styles.disabled
      ) }
      key={ menuItem.id }
      title={ !isMenuExpanded ? t(`sideNavigation.${menuItem.translationKey}`) : "" }
    >
      <Link
        role={ "link" }
        onClick={ () => onClickMenuItem(menuItem) }
        to={ !disabled ? route : null }
        className={ styles.menuItemLink }
      >
        <img
          className={ styles.menuItemIcon }
          src={ disabled ? menuItem.disabledIcon : menuItem.icon }
          alt={ t(`sideNavigation.${menuItem.translationKey}`) }
        />
        { isMenuExpanded && (
          <span aria-label="title" className={ styles.menuItemTitle }>
            { t(`sideNavigation.${menuItem.translationKey}`) }
          </span>
        ) }
      </Link>
    </div>
  )
}

MenuListItem.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isMenuExpanded: PropTypes.bool,
  menuItem: PropTypes.object,
  onClickMenuItem: PropTypes.func
}

export default MenuListItem
