/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"
import { useIsFetching } from "react-query"

import AssetCard from "../../../../components/AssetCard/AssetCard"
import DataTable from "../../../../components/DataTable/DataTable"
import NoRows from "../../../../components/DataTable/internals/NoRows/NoRows"
import FilterPopup from "../../../../components/FilterPopup/FilterPopup"
import Loading from "../../../../components/Loading/Loading"
import SearchErrorPage from "../../../../components/SearchErrorPage/SearchErrorPage"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import useCheckBoxSelection from "../../../../utils/CustomHooks/useCheckBoxSelection"
import { COMPARE_VALUE } from "../../../../utils/GlobalConstants"
import HeadSection from "../internals/HeadSection"
import styles from "../SiteGroupAssetView.module.scss"
import SiteGroupByColumnsData from "../SiteGroupByColumnsData"

// eslint-disable-next-line react/display-name
const AssetSummary = forwardRef(
  (
    {
      assets,
      assetsQueryKey,
      setGroupByOption,
      groupOption,
      handleMoveFleet,
      setFilterAPIError,
      setSelectedAsset,
      handleSearch,
      searchResult,
      searchError,
      siteViewGroupFilterData,
      getFilterAssets,
      fleetCount,
      selectedFilters,
      setSelectedFilters,
      isFetchingModalityName
    },
    ref
  ) => {
    const tableRef = useRef(null)
    const ṣearchInputRef = useRef(null)
    const [isCardView, setIsCardView] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)

    const {
      selectedRows: selectedAssets,
      handleSelectRow,
      handleSelectAll,
      resetSelection
    } = useCheckBoxSelection({
      data: assets,
      onReset: () => setSelectedAsset([]),
      onSelectAllCallback: (data) => {
        setSelectedAsset(data)
        if (data.length === 0 && tableRef?.current) {
          tableRef.current.toggleAll(false)
        } else if (tableRef?.current) {
          tableRef.current.toggleAll(true)
        }
      },
      onSelectRowCallback: (selectedRow) => setSelectedAsset(selectedRow)
    })

    useImperativeHandle(ref, () => ({
      resetSelection() {
        tableRef?.current?.toggleAll(false)
        resetSelection()
      }
    }))

    const nowRows = useCallback(Boolean(assets?.length === 0))
    const isFetchingAssets = useIsFetching({ queryKey: assetsQueryKey })

    const updateFilterItem = (value) => {
      ṣearchInputRef?.current?.reset()
      const modalities = value.filter((item) => item.type === COMPARE_VALUE.modality).map((item) => item.label)
      const filterItem = value.filter((item) =>
        item.type === COMPARE_VALUE.model ? modalities.includes(item.parent.trim()) : true
      )
      const isModalityAvailable = filterItem.filter((option) => option.type === COMPARE_VALUE.modality)
      const setFilterValue = isModalityAvailable.length ? filterItem : []
      setSelectedFilters(setFilterValue)
      getFilterAssets(setFilterValue)
    }

    const getAssetsCard = () => {
      if ((assetsQueryKey && isFetchingAssets) || isFetchingModalityName) {
        return <Loading />
      } else if (searchError || searchResult?.length === 0) {
        return <SearchErrorPage />
      } else if (nowRows) {
        return <NoRows type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET } />
      } else {
        return (
          <div className={ styles.assetCardContainer }>
            { assets.map((asset) => (
              <div key={ asset.id } className={ styles.assetCard }>
                <AssetCard
                  isSelectable={ true }
                  assetDetails={ asset }
                  isSelected={ selectedAssets.some(({ id }) => asset.id === id) }
                  onChange={ handleSelectRow }
                  status={ asset.status }
                />
              </div>
            )) }
          </div>
        )
      }
    }

    const getAssetsTable = () => {
      if ((assetsQueryKey && isFetchingAssets) || isFetchingModalityName) {
        return <Loading />
      } else if (searchError || searchResult?.length === 0) {
        return <SearchErrorPage />
      } else {
        return (
          <DataTable
            selectableRows
            internalSelectAll
            displayRowColorBar
            rowHoverEffect
            highlightSelectedRows
            tableData={ !isFetchingAssets ? assets : [] }
            ref={ tableRef }
            columnsData={ SiteGroupByColumnsData() }
            onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
            onSelectAllCallBack={ handleSelectAll }
            selectedRows={ selectedAssets }
            type={ TABLE_INSTANCES.ASSETS_NOT_IN_FLEET }
            queryKey={ assetsQueryKey }
          />
        )
      }
    }

    return (
      <div className={ styles.assetSummary }>
        <HeadSection
          groupOption={ groupOption }
          setGroupByOption={ setGroupByOption }
          isCardView={ isCardView }
          setIsCardView={ setIsCardView }
          setOpenFilter={ setOpenFilter }
          filter={ selectedFilters }
          setSelectedFilter={ updateFilterItem }
          selectedAssets={ selectedAssets }
          handleMoveFleet={ handleMoveFleet }
          handleSearch={ handleSearch }
          searchResult={ searchResult }
          searchError={ searchError }
          fleetCount={ fleetCount }
        />
        <div className={ isCardView ? styles.assetContainerCardView : styles.assetContainer }>
          { isCardView ? getAssetsCard() : getAssetsTable() }
        </div>
        { openFilter && (
          <FilterPopup
            popupStyles={ styles.popupStyles }
            data={ siteViewGroupFilterData }
            onCancel={ setOpenFilter }
            onSubmit={ updateFilterItem }
            filters={ selectedFilters }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
          />
        ) }
      </div>
    )
  }
)
AssetSummary.propTypes = {
  assets: PropTypes.array,
  assetsQueryKey: PropTypes.array,
  fleetCount: PropTypes.number,
  getFilterAssets: PropTypes.any,
  groupOption: PropTypes.number,
  handleMoveFleet: PropTypes.func,
  handleSearch: PropTypes.func,
  isFetchingModalityName: PropTypes.bool,
  searchError: PropTypes.any,
  searchResult: PropTypes.array,
  selectedFilters: PropTypes.array,
  setFilterAPIError: PropTypes.any,
  setGroupByOption: PropTypes.func,
  setSelectedAsset: PropTypes.func,
  setSelectedFilters: PropTypes.func,
  siteViewGroupFilterData: PropTypes.any
}

AssetSummary.defaultProps = {
  assets: []
}

export default AssetSummary
