import { SiteAuthElement } from "../../utils/Constants/Auth/site"
import { EMPTY_CELL_DEFAULT_VALUE } from "../../utils/Constants/DataTable"
import { GROUP_BY_CHANGE_METHOD } from "../../utils/Constants/SiteView"
import { defaultFleetCardDetails } from "../../utils/GlobalConstants"
import { softwareStatus } from "../../utils/helper"

const getAssetsStatistics = (assets) => {
  return {
    countValue: assets?.total ?? 0,
    distribution: [
      assets?.available ?? 0,
      assets?.inUse ?? 0,
      assets?.offline ?? 0
    ],
    mutedAssetCount: assets?.disabled ?? 0,
    showMutedCount: true,
    type: "assets"
  }
}

const getFaultsStatistics = (faults) => {
  return {
    countValue: faults?.total ?? 0,
    distribution: [
      faults?.critical ?? 0,
      faults?.moderate ?? 0,
      faults?.low ?? 0
    ],
    mutedAssetCount: faults?.disabled ?? 0,
    showMutedCount: true,
    type: "alerts"
  }
}

const getConfigsStatistics = (configs) => {
  return {
    countValue: configs?.total ?? 0,
    distribution: [
      configs?.inInstall ?? 0,
      configs?.inDownload ?? 0,
      configs?.failed ?? 0
    ],
    type: "configs"
  }
}

const getUpdatesStatistics = (updates) => {
  return {
    countValue: updates?.total ?? 0,
    distribution: [
      updates?.inProgress ?? updates?.inprogress ?? 0,
      updates?.pending ?? 0,
      updates?.failed ?? 0
    ],
    type: "updates"
  }
}

const getDistributionData = (data) => {
  const { assets, faults, updates, configs } = data ?? {}
  return [
    getAssetsStatistics(assets),
    getFaultsStatistics(faults),
    getConfigsStatistics(configs),
    getUpdatesStatistics(updates)
  ]
}

export const transformSiteStatistics = (data) => {
  return {
    distributionData: getDistributionData(data)
  }
}

export const transformSiteInformation = (
  groupByData,
  assets,
  assetsNotInFleetData
) => {
  const contents = groupByData || []

  for (const content of contents) {
    content["distributionData"] = getDistributionData(content?.statistics)
  }

  const assetsNotInFleet = {
    assets: transformAssets(assets || []),
    distributionData: getDistributionData(assetsNotInFleetData?.statistics),
    name: "Assets not in a fleet"
  }

  return {
    assetsNotInFleet: assetsNotInFleet,
    contents
  }
}

export const transformAssets = (data) => {
  const assets = []

  for (const [index, asset] of data.entries()) {
    assets.push({
      assetNote: asset.assetNote ?? "--",
      availableUpdates: mockAvailableUpdates(asset.softwareVersion, index),
      ...asset,
      configStatus: asset?.configStatus?.toLowerCase(),
      connection: asset.connectionType,
      deviceId: asset.serialNumber,
      fleet: asset.fleetName || EMPTY_CELL_DEFAULT_VALUE,
      id: asset.id,
      ipAddress: asset.ipAddress,
      lastLocation: "Minnesota",
      mute: asset.muted,
      name: asset?.friendlyName,
      scheduledTime: index === 4 ? new Date() : null,
      severity: asset.faultSeverity,
      softwareVersion: asset?.softwareVersion,
      status: asset?.connectivityStatus || "Offline",
      swStatus: softwareStatus(
        asset?.swStatus?.toLowerCase(),
        asset?.swInProgressStatus?.toLowerCase()
      ),
      uuid: asset.id
    })
  }
  return assets
}

export const mockAvailableUpdates = (softwareVersion, index) => {
  if (softwareVersion === "5.2") {
    return 22
  } else {
    switch (index) {
    case 0:
      return 22
    case 1:
      return 11
    case 2:
      return 22
    case 3:
      return 333
    case 4:
      return 4444
    default:
      return 0
    }
  }
}

export const getCreateFleetPayload = (fleetName, siteId) => {
  return {
    variables: {
      fleetName: fleetName,
      parentFleetId: "",
      siteId: siteId
    }
  }
}

export const getAddRemoveAssetsPayload = (assets, fleetId) => {
  return {
    fleetId: fleetId,
    variables: { assetIds: assets }
  }
}

export const getRemoveAssetsPayload = (assets, fleetId) => {
  return {
    fleetId: fleetId,
    variables: (assets ?? []).join()
  }
}

export const getUpdateFleetPayload = (fleetId, fleetName) => {
  return {
    fleetId,
    variables: {
      fleetName
    }
  }
}

export const getDeleteFleetPayload = (fleetId) => {
  return {
    fleetId
  }
}

export const searchAssetsPayload = (searchString) => ({
  variables: {
    input: searchString
  }
})

export const GROUP_BY_TYPES = {
  1: {
    idKey: "fleetId",
    responseKey: "fleets",
    serviceName: "fleets",
    titleKey: "fleetName"
  },
  2: {
    responseKey: "modalities",
    serviceName: "modalities",
    titleKey: "modality"
  },
  3: {
    responseKey: "models",
    serviceName: "models",
    titleKey: "model"
  },
  6: {
    responseKey: "connections",
    serviceName: "connections",
    titleKey: "connection"
  },
  7: {
    responseKey: "assets",
    serviceName: "assets",
    titleKey: "name"
  }
}

export const siteViewReducer = (newState, action) => {
  let prepareNewState = {}
  switch (action.type) {
  case SITE_ACTION_TYPES.OPEN_MOVE_FLEET_POPUP:
    return {
      ...newState,
      ...{
        moveFleet: action.payload
      }
    }

  case SITE_ACTION_TYPES.FILTER_INPUT:
    return {
      ...newState,
      ...{
        filterValue: action.payload
      }
    }

  case SITE_ACTION_TYPES.FILTER_RESULT:
    return {
      ...newState,
      ...{
        filterResult: action.payload
      }
    }

  case SITE_ACTION_TYPES.CONFIRM_POPUP:
    return {
      ...newState,
      ...{
        confirmPopup: action.payload
      }
    }
  case SITE_ACTION_TYPES.TOGGLE_VIEW:
    return {
      ...newState,
      ...{
        isCardView: !newState.isCardView
      }
    }
  case SITE_ACTION_TYPES.SET_SELECTED_ASSETS:
    return {
      ...newState,
      ...{
        selectedAssets: action.payload
      }
    }
  case SITE_ACTION_TYPES.SET_GROUP_BY_DROPDOWN:
    return {
      ...newState,
      ...{
        filterValue: [],
        groupByMappingObject: GROUP_BY_TYPES[action.payload],
        groupBySelectedValue: action.payload
      }
    }
  case SITE_ACTION_TYPES.SET_GROUP_BY_CHANGE_METHOD:
    return {
      ...newState,
      ...{
        groupByChangeMethod: action.payload
      }
    }
  case SITE_ACTION_TYPES.SEARCH_ERROR:
    return {
      ...newState,
      ...{
        searchError: action.payload.hasError,
        searchInput: action.payload.value,
        searchResults: null,
        selectedAssets: []
      }
    }
  case SITE_ACTION_TYPES.SEARCH_INPUT:
    prepareNewState = searchInputReducerFn(action, newState)
    return {
      ...newState,
      ...prepareNewState
    }
  case SITE_ACTION_TYPES.SEARCH_RESULTS:
    return {
      ...newState,
      ...{
        searchResults: action.payload
      }
    }
  case SITE_ACTION_TYPES.SET_NEW_FLEET_SELECTION:
    return {
      ...newState,
      ...{
        defaultFleetCardDetails: {
          ...newState.defaultFleetCardDetails,
          distributionData:
              newState?.defaultFleetCardDetails?.distributionData?.map(
                (item) => {
                  if (item.type === "assets")
                    item.distribution[0] = newState?.selectedAssets?.length
                  return item
                }
              )
        },
        showNewFleetSection: action.payload
      }
    }
  case SITE_ACTION_TYPES.DRAG_DETAILS:
    return {
      ...newState,
      ...action.payload
    }
  case SITE_ACTION_TYPES.TOGGLE_ACCORDION_EXPAND:
    return {
      ...newState,
      ...{
        isAssetsNotInFleetExpanded: action.payload
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

const searchInputReducerFn = (action, newState) => {
  let prepareNewState = {
    selectedAssets: []
  }
  if (action.payload.closeAction) {
    prepareNewState.searchError = false
    prepareNewState.searchResults = null
    prepareNewState.searchInput = null
    prepareNewState.closeAction = true
    if (newState?.groupByChangeMethod === GROUP_BY_CHANGE_METHOD.SEARCH) {
      prepareNewState.groupBySelectedValue = 1
      prepareNewState.groupByMappingObject = GROUP_BY_TYPES[1]
    }
    return prepareNewState
  }
  if (action.payload.value?.length >= 3) {
    if (newState?.groupByChangeMethod !== GROUP_BY_CHANGE_METHOD.DROPDOWN) {
      prepareNewState.groupByChangeMethod = GROUP_BY_CHANGE_METHOD.SEARCH
    }
    prepareNewState.groupBySelectedValue = 7
    prepareNewState.groupByMappingObject = GROUP_BY_TYPES[7]
    prepareNewState.searchInput = action.payload.value
    prepareNewState.searchError = false
    prepareNewState.closeAction = false
  } else if (action.payload.value?.length < 3) {
    if (newState?.groupByChangeMethod !== GROUP_BY_CHANGE_METHOD.DROPDOWN) {
      prepareNewState.groupByChangeMethod = GROUP_BY_CHANGE_METHOD.SEARCH
    }
    prepareNewState.groupBySelectedValue = 7
    prepareNewState.groupByMappingObject = GROUP_BY_TYPES[7]
    prepareNewState.searchResults = null
    prepareNewState.searchError = true
  }

  return prepareNewState
}

export const SITE_ACTION_TYPES = {
  CLOSE_CONFIRM_MOVE_FLEET_POPUP: "closeConfirmMoveFleetPopup",
  CLOSE_MOVE_FLEET_POPUP: "closeMoveFleetPopup",
  CONFIRM_POPUP: "openConfirmMoveFleetPopup",
  DRAG_DETAILS: "dragDetails",
  FILTER_INPUT: "filterInput",
  FILTER_RESULT: "filterResult",
  OPEN_MOVE_FLEET_POPUP: "openMoveFleetPopup",
  SEARCH_ERROR: "searchError",
  SEARCH_INPUT: "searchInput",
  SEARCH_RESULTS: "searchResults",
  SET_GROUP_BY_CHANGE_METHOD: "setGroupByChangeMethod",
  SET_GROUP_BY_DROPDOWN: "setGroupBySelectedValue",
  SET_NEW_FLEET_SELECTION: "showNewFleetSection",
  SET_SELECTED_ASSETS: "setSelectedAssets",
  TOGGLE_ACCORDION_EXPAND: "toggleAccordionExpand",
  TOGGLE_VIEW: "toggleView"
}

export const SITE_VIEW_STATE = {
  confirmPopup: false,
  defaultFleetCardDetails: defaultFleetCardDetails,
  draggingDetails: {
    assets: [],
    fleet: {}
  },
  filterValue: [],
  groupByChangeMethod: GROUP_BY_CHANGE_METHOD.DEFAULT,
  groupByMappingObject: GROUP_BY_TYPES[1],
  groupBySelectedValue: 1,
  isAssetsNotInFleetExpanded: false,
  isCardView: false,
  moveFleet: false,
  searchError: false,
  searchInput: null,
  searchResults: null,
  selectedAssets: [],
  showNewFleetSection: false
}

export const transformFilterData = () => {
  return [
    {
      defaultValue: [],
      key: "modality",
      label: "Modality",
      multiple: true,
      options: [],
      required: true
    },
    {
      defaultValue: [],
      key: "model",
      label: "Model",
      multiple: true,
      options: []
    },
    {
      defaultValue: [],
      key: "status",
      label: "Status",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Available"
        },
        {
          id: 2,
          label: "In use"
        },
        {
          id: 3,
          label: "Offline"
        }
      ]
    },
    {
      defaultValue: [],
      key: "severity",
      label: "Fault severity",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Critical"
        },
        {
          id: 2,
          label: "Moderate"
        },
        {
          id: 3,
          label: "Low"
        }
      ]
    }
  ]
}

export const SITE_ACCESS = {
  moveAsset: SiteAuthElement.MoveAsset
}
