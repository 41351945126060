/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef
} from "react"

import AssetCard from "../../../components/AssetCard/AssetCard"
import DataTable from "../../../components/DataTable/DataTable"
import { useFleetViewContext } from "../../../contexts/fleetView/FleetView"
import { CommonAuthElement } from "../../../utils/Constants/Auth/common"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import useAccess from "../../../utils/CustomHooks/useAccess"
import { transformAssets } from "../FleetUtils"
import GroupByAssetsSubFleetColumnsData from "../SubFleetsSummary/internals/GroupByAssetsSubFleetColumnsData"
import cssStyles from "../SubFleetsSummary/SubFleetsSummary.module.scss"

// eslint-disable-next-line react/display-name
const AssetView = forwardRef(
  ({ assets, handleSelectAll, handleSelectRow, selectedAssets }, ref) => {
    const { fleetViewState } = useFleetViewContext()
    const hasSelectAccess = useAccess(CommonAuthElement.Any)
    const tableData = useCallback(transformAssets(assets), [assets])
    const tableRef = useRef(null)
    useImperativeHandle(ref, () => ({
      resetSelection() {
        tableRef?.current?.toggleAll(false)
      }
    }))
    if (fleetViewState.isCardView) {
      return (
        <div className={ cssStyles.cardViewContainer }>
          { tableData?.map((asset) => (
            <div key={ asset.id }>
              <AssetCard
                isSelectable
                assetDetails={ asset }
                isSelected={ selectedAssets.some(({ id }) => asset.id === id) }
                onChange={ handleSelectRow }
                status={ asset.status }
              />
            </div>
          )) }
        </div>
      )
    } else {
      return (
        <div className={ cssStyles.groupByAssetsTable }>
          <DataTable
            ref={ tableRef }
            displayRowColorBar
            rowHoverEffect
            highlightSelectedRows
            selectableRows={ hasSelectAccess }
            internalSelectAll
            tableData={ tableData || [] }
            selectedRows={ selectedAssets }
            columnsData={ GroupByAssetsSubFleetColumnsData() }
            onSelectAllCallBack={ handleSelectAll }
            onSelectRowCallBack={ ({ checked, data }) =>
              handleSelectRow(checked, data)
            }
            type={ TABLE_INSTANCES.ASSETS_NOT_IN_SUB_FLEET }
          />
        </div>
      )
    }
  }
)

AssetView.propTypes = {
  assets: PropTypes.array,
  handleSelectAll: PropTypes.func,
  handleSelectRow: PropTypes.func,
  selectedAssets: PropTypes.array
}

export default AssetView
