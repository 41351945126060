import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./SoftwarePackagesOverlay.module.scss"

import { CLOSE } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"

const SoftwarePackagesOverlay = ({ packageDetails, handleCancel, isOpen }) => {
  const { t } = useTranslation(["asset"])

  return (
    <ConfirmationPopup
      popupStyles={ styles.popupStyles }
      heading={ t("softwarePackages.infoOverlay.packagedetails") }
      isOpen={ isOpen }
      cancel={ CLOSE }
      onCancel={ handleCancel }
      isSubmit={ false }
    >
      <div className={ styles.messageContainer }>
        <div>
          { t("softwarePackages.infoOverlay.packageName") } | { t("softwarePackages.infoOverlay.version") }
        </div>
        <div className={ styles.packageDetailData }>
          <div className={ styles.packageName } >{ packageDetails?.name }</div>
          &nbsp;|&nbsp;
          <div className={ styles.packageVersion } >{ packageDetails?.version }</div>
        </div>
      </div>

      <div className={ styles.softwaredetails }>
        { packageDetails?.files?.map((details) => (
          <span className={ styles.contentSection } key={ details.fileName }>
            <span className={ classNames(styles.sectionName, styles.textEllipsis) }>
              { details.fileName }
              <span className={ styles.sectionFileSize }>{ details.fileSize }</span>
            </span>
          </span>
        )) }
      </div>
    </ConfirmationPopup>
  )
}

SoftwarePackagesOverlay.propTypes = {
  handleCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  packageDetails: PropTypes.object
}

export default SoftwarePackagesOverlay
