/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { useParams } from "react-router-dom"

import styles from "./RowRedirection.module.scss"

import {
  TABLE_ROW_REDIRECTION,
  getRedirectioURLFromGroupByToAsset
} from "../../../../utils/Constants/DataTable"
import { classNames } from "../../../../utils/styles/helper"
import { BREADCRUMB_ROUTE } from "../../../Breadcrumb/BreadcrumbUtils"
import PageLink from "../../../PageLink/PageLink"

const RowRedirection = ({
  cell,
  nameKey,
  data,
  type,
  children,
  classes,
  isRedirectableCell,
  isResizing
}) => {
  const queryParams = useParams()
  const targetURL = useCallback(TABLE_ROW_REDIRECTION(data)[type])

  const isGroupByToAssetURL = useMemo(
    () => getRedirectioURLFromGroupByToAsset(type, queryParams, data),
    []
  )

  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const headerFullSize = cell?.column?.getSize()
  const getCanResize = cell?.column?.getCanResize()
  const textElementRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      compareSize()
    }, 0)
  }, [headerFullSize, getCanResize, textElementRef, isResizing])

  const compareSize = () => {
    const compare =
      textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize)
    },
    []
  )

  const renderRowView = () => {
    return (
      <Tooltip
        title={ <span className={ "tooltipTitle" }>{ children }</span> }
        interactive="true"
        disableHoverListener={ !showInfoTooltip }
        arrow
        placement="bottom-start"
      >
        <div ref={ textElementRef } className="children">
          { children }
        </div>
      </Tooltip>
    )
  }

  if (!isRedirectableCell) {
    return renderRowView()
  }

  return (
    <PageLink
      key={ data.id }
      className={ classNames(styles.root, "removeLinkStyles", classes) }
      to={ isGroupByToAssetURL || targetURL?.URL }
      type={ BREADCRUMB_ROUTE.ASSET }
      pageName={ data[nameKey || "name"] }
      title=""
    >
      { renderRowView() }
    </PageLink>
  )
}

RowRedirection.propTypes = {
  cell: PropTypes.shape({
    column: PropTypes.object
  }),
  children: PropTypes.any,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.object.isRequired,
  isRedirectableCell: PropTypes.bool,
  isResizing: PropTypes.any,
  nameKey: PropTypes.string,
  type: PropTypes.string
}

RowRedirection.defaultProps = {
  isRedirectableCell: true
}

export default RowRedirection
