import PropTypes from "prop-types"
import React from "react"
import { QueryClientProvider } from "react-query"

import { AboutProvider } from "./AboutContext"
import { AppProvider } from "./app/AppContext"
import { AuthProvider } from "./Auth/auth"
import { HelpProvider } from "./help/HelpContext"
import { QRScannerProvider } from "./qrScanner/QRScannerContext"
import { ToastProvider } from "./ToastContext"

import About from "../components/About/About"
import Help from "../components/Help/Help"
import QRScanner from "../components/QRScanner/QRScanner"
import Toaster from "../components/Toaster/Toaster"
import { queryClient } from "../reactQuery/config"

const GlobalContextWrapper = ({ children }) => {
  return (
    <QueryClientProvider client={ queryClient }>
      <ToastProvider>
        <Toaster />
        <HelpProvider>
          <AboutProvider>
            <QRScannerProvider>
              <AuthProvider>
                <Help />
                <About />
                <QRScanner />
                <AppProvider>{ children }</AppProvider>
              </AuthProvider>
            </QRScannerProvider>
          </AboutProvider>
        </HelpProvider>
      </ToastProvider>
    </QueryClientProvider>
  )
}

GlobalContextWrapper.propTypes = {
  children: PropTypes.any
}

export default GlobalContextWrapper
