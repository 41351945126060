export const UNAUTHORIZED_USER = {
  ErrorCode: "UNAUTHORIZED",
  ErrorStatus: 401,
  ErrorType: "invalid_user_token",
  Message:"Unauthorized"
}

export const ERROR_MESSAGE = {
  InvalidState: "Login failed, State mismatch"
}
