import React from "react"

import SubFleetViewContainer from "./SubFleetViewContainer"

import { SubFleetViewProvider } from "../../contexts/subFleetView/subFleetView"

const SubFleetView = () => {
  return (
    <SubFleetViewProvider>
      <SubFleetViewContainer />
    </SubFleetViewProvider>
  )
}
export default SubFleetView
