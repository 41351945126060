import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import styles from "./Action.module.scss"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../components/Icon/Icon"
import { useSoftwareUpdateContext } from "../../../../contexts/swUpdate/SoftwareUpdateContext"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { SoftwareAuthElement } from "../../../../utils/Constants/Auth/software"
import { ICON_NAME, ROUTE_CONFIG } from "../../../../utils/GlobalConstants"

function Action({ row, handleRowClickActions, isAssetMuted }) {
  const { t } = useTranslation(["asset"])
  const { filter } = useSoftwareUpdateContext()
  const currentURL = window.location.pathname

  return (
    <div className={ styles.actionsCell }>
      <Tooltip arrow title={ t("softwarePackages.tooptipText.info") }>
        <IconButton
          aria-label={ row?.original?.id + "-" + "info" }
          onClick={ () => handleRowClickActions(TABLE_ACTION.INFO, row?.original) }
        >
          <Icon icon={ ICON_NAME.swPackageDetails } iconStyles={ styles.actionIcon } />
        </IconButton>
      </Tooltip>

      <AuthGuard actionType = { SoftwareAuthElement.ReadCentalSoftDeployPage }>
        <Tooltip
          arrow
          title={ !isAssetMuted ? t("softwarePackages.tooptipText.deploy") : "" }
        >
          <span>
            <IconButton
              aria-label={ row?.original?.id + "-deploy" }
              disabled={ isAssetMuted }
            >
              <Link
                to={ currentURL.concat(
                  `/${ROUTE_CONFIG.SOFTWARE_DEPLOYMENT.routeName}/${row?.original?.id}`
                ) }
                state={ { filter, packageDetails: row?.original } }
              >
                <Icon
                  icon={
                    isAssetMuted ? ICON_NAME.swDeployDisabled : ICON_NAME.swDeploy
                  }
                  iconStyles={ styles.actionIcon }
                />
              </Link>
            </IconButton>
          </span>
        </Tooltip>
      </AuthGuard>
    </div>
  )
}

Action.propTypes = {
  handleRowClickActions: PropTypes.any,
  isAssetMuted: PropTypes.bool,
  row: PropTypes.any
}

export default Action
