/* eslint-disable max-len */
import { format } from "date-fns"

import { FleetAuthElement } from "./Constants/Auth/fleet"
import { SubFleetAuthElement } from "./Constants/Auth/sub-fleet"
import { DATE_FORMAT } from "./Constants/TimeZones"
import colors from "./styles/jsExports/colorExport.module.scss"

export const GLOBAL_THROTTLE_TIME = 1000
export const TEXT_FIELD_MAX_CHAR = 256

export const COMPARE_VALUE = {
  acknowledge: "acknowledge",
  acknowledgement: "acknowledgement",
  configStatus: "configStatus",
  enter: "Enter",
  faultSeverity: "faultSeverity",
  modality: "modality",
  model: "model",
  severity: "severity",
  status: "status",
  swUpdateStatus: "swUpdateStatus",
  swVersion: "swVersion",
  unAcknowledge: "unacknowledge"
}

export const CONFIG_CONTEXT_TYPE = {
  ASSET_FAULTS: "ASSET_FAULTS",
  CENTRAL_CONFIG: "CENTRAL_CONFIG",
  CENTRAL_SOFTWARE: "CENTRAL_SOFTWARE"
}

export const REG_EXP = {
  checkSPLChar: /[&<>]/
}

export const CHART_COLOR = {
  acknowledged: "#AEBC83",
  modality: "#9FBADA",
  model: "#D0B196",
  sucessModality: "#23979F",
  unAcknowledged: "#D9D9D9"
}

// EDITABLE FIELD
export const FIELD_CHAR_LENGTH = {
  assetName: 50,
  customerName: 50,
  fleetName: 50,
  siteName: 50,
  subfleet: 50
}

export const ERROR_CODE = {
  CONFLICT: "E9002-FLT",
  FORBIDDEN: ["E003-FLT", "E300S-FLT"]
}

export const ERROR_MESSAGE = {
  assetName: {
    accessError: "No permission to update asset name.",
    characterLength: `Asset name shouldn't exceed ${FIELD_CHAR_LENGTH.assetName} characters.`,
    defaultInfo: `Please provide a unique valid asset name. \n Special characters "<", ">", "&" are not allowed. \n Only ${FIELD_CHAR_LENGTH.deviceName} characters are allowed \n Field should not be empty.`,
    invalidValue: "Asset name can't be empty, please provide asset name.",
    specialCharactersNotAllowed: "Special characters \"<\", \">\", \"&\" are not allowed.",
    synchingMessage:
      "Asset name not synchronized, please try again later or contact PCS Support Team at ReadyFix_L2_SupportTeam@gehealthcare.com",
    uniqueName: "Provided asset name already exists.",
    updateError: "An error occurred, please contact ReadyFix Helpdesk."
  },
  customerName: {
    accessError: "No permission to update enterprise name.",
    characterLength: `Only ${FIELD_CHAR_LENGTH.customerName} characters are allowed for enterprise name`,
    defaultInfo:
      // eslint-disable-next-line max-len
      `Please provide a unique valid enterprise name \n Special characters "<", ">", "&" are not allowed \n Only ${FIELD_CHAR_LENGTH.customerName} characters are allowed \n Field should not be empty`,
    invalidValue: "Please provide a valid enterprise name",
    specialCharactersNotAllowed: "Special characters \"<\", \">\", \"&\" are not allowed",
    uniqueName: "The provided enterprise name already exists",
    updateError:
      // eslint-disable-next-line max-len
      "Unable to update the Enterprise name, please refresh the page or contact PCS Support team at ReadyFix_L2_SupportTeam@gehealthcare.com"
  },
  deviceName: {
    accessError: "No permission to update device name.",
    characterLength: `Device name shouldn't exceed ${FIELD_CHAR_LENGTH.deviceName} characters`,
    defaultInfo: `Please provide a unique valid device name \n Special characters "<", ">", "&" are not allowed \n Only ${FIELD_CHAR_LENGTH.deviceName} characters are allowed \n Field should not be empty`,
    invalidValue: "Device name can't be empty, please provide device name",
    specialCharactersNotAllowed: "Special characters \"<\", \">\", \"&\" are not allowed",
    synchingMessage:
      "Device name not synchronized, please try again later or contact PCS Support Team at ReadyFix_L2_SupportTeam@gehealthcare.com",
    uniqueName: "Device name should be unique, please provide unique device name"
  },
  fleetName: {
    accessError: "No permission to update fleet name.",
    characterLength: `Only ${FIELD_CHAR_LENGTH.fleetName} characters are allowed for fleet name`,
    createError:
      "Unable to create the fleet, please refresh the page or contact PCS Support team at ReadyFix_L2_SupportTeam@gehealthcare.com",
    defaultInfo: `Please provide a unique valid fleet name \n Special characters "<", ">", "&" are not allowed \n Only ${FIELD_CHAR_LENGTH.fleetName} characters are allowed \n Field should not be empty`,
    invalidValue: "Please provide a valid fleet name",
    specialCharactersNotAllowed: "Special characters \"<\", \">\", \"&\" are not allowed",
    uniqueName: "The provided fleet name already exists.",
    updateError:
      "Unable to update the fleet, please refresh the page or contact PCS Support team at ReadyFix_L2_SupportTeam@gehealthcare.com"
  },
  siteName: {
    accessError: "No permission to update site name.",
    characterLength: `Only ${FIELD_CHAR_LENGTH.siteName} characters are allowed for site name`,
    defaultInfo: `Please provide a unique valid site name \n Special characters "<", ">", "&" are not allowed \n Only ${FIELD_CHAR_LENGTH.siteName} characters are allowed \n Field should not be empty`,
    invalidValue: "Please provide a valid site name",
    specialCharactersNotAllowed: "Special characters \"<\", \">\", \"&\" are not allowed",
    uniqueName: "The provided site name already exists.",
    updateError:
      "Unable to update the site name, please refresh the page or contact PCS Support team at ReadyFix_L2_SupportTeam@gehealthcare.com"
  },
  subfleet: {
    accessError: "No permission to update sub-fleet name.",
    characterLength: `Only ${FIELD_CHAR_LENGTH.subfleet} characters are allowed for sub-fleet name`,
    createError:
      "Unable to create the sub-fleet, please refresh the page or contact PCS Support team at ReadyFix_L2_SupportTeam@gehealthcare.com",
    defaultInfo: `Please provide a unique valid sub-fleet name \n Special characters "<", ">", "&" are not allowed \n Only ${FIELD_CHAR_LENGTH.subfleet} characters are allowed \n Field should not be empty`,
    invalidValue: "Please provide a valid sub-fleet name",
    specialCharactersNotAllowed: "Special characters \"<\", \">\", \"&\" are not allowed",
    uniqueName: "The provided sub-fleet name already exists.",
    updateError:
      "Unable to update the sub-fleet, please refresh the page or contact PCS Support team at ReadyFix_L2_SupportTeam@gehealthcare.com"
  }
}
// EDITABLE FIELD  END

export const CHECKBOX_STATE_ICONS = {
  checked: "checkboxChecked",
  disableChecked: "disableCheckedBox",
  disableUnchecked: "disableUnCheckedBox",
  indeterminate: "checkboxIndeterminate",
  unChecked: "checkboxUnchecked"
}

export const ICON_NAME = {
  aboutIcon: "aboutIcon",
  acknowledge: "acknowledge",
  acknowledgeButton: "acknowledgeButton",
  addFleet: "addFleet",
  alertRed: "alertRed",
  alertWhite: "alertWhite",
  arrowLeft: "arrow-left",
  arrowRight: "arrow-right",
  assetSummaryMute: "assetSummaryMute",
  back: "back",
  backBlack: "backBlack",
  bgClose: "bgClose",
  breadcrumb: "breadcrumb",
  btnLoader: "btnLoader",
  cancel: "cancel",
  cancelBlack: "cancelBlack",
  cancelCircle: "cancelCircle",
  cancelInput: "cancelInput",
  cancelWhite: "cancelWhite",
  checkedSwitch: "checkedSwitch",
  checkmark: "checkmark",
  clear: "clear",
  clock: "clock",
  close: "close",
  closeIcon: "closeIcon",
  cloudBlue: "cloud_blue",
  collapseArrow: "downArrow",
  configDeployToggleView: "configDeployToggleView",
  demote: "demote",
  deploy: "deploy",
  deploySoftware: "deploySoftware",
  deploySoftwareDisabled: "deploySoftwareDisabled",
  deploymentExport: "deploymentExport",
  deploymentExportDisabled: "deploymentExportDisabled",
  disabledIcon: "disabledIcon",
  document: "document",
  documentIcon: "documentIcon",
  download: "download",
  dragGrey: "drag-grey",
  edit: "edit",
  editIcon: "editIcon",
  editPurple: "editPurple",
  ellipsis: "ellipsis",
  errorBlack: "errorBlack",
  errorIcon: "errorIcon",
  expandArrow: "expandMoreIcon",
  expandMoreIcon: "expandMoreIcon",
  expandMoreIconActive: "expandMoreIconActive",
  export: "export",
  exportDisabled: "exportDisabled",
  exportIcon: "exportIcon",
  failed: "failed",
  favourite: "favourite",
  filter: "filter",
  filterBadge: "filterBadge",
  fleet: "fleet",
  fleetActive: "fleetActive",
  folderAdd: "folderAdd",
  formError: "formError",
  getStart: "getStart1",
  gridView: "gridView",
  hamburger: "hamburger",
  healthCare: "healthCare",
  help: "help",
  hospital: "hospital",
  hospitalActive: "hospitalActive",
  iconFill: "iconFill",
  inProgress: "InProgress",
  info: "info",
  infoBlack: "infoBlack",
  loading: "loading",
  lock: "lock",
  lockBlack: "lockBlack",
  mailIconGrey: "mailIconGrey",
  mailIon: "mailIcon",
  monitorBlue: "monitor_blue",
  moreBlackIcon: "moreBlackIcon",
  moreBlackIconDisabled: "moreBlackIconDisabled",
  morePurpleIcon: "morePurpleIcon",
  moveIcon: "moveIcon",
  moveTo: "moveTo",
  muteIcon: "muteIcon",
  nonSort: "nonSort",
  notes: "notes",
  pause: "pause",
  phoneIcon: "phoneIcon",
  phoneIconGrey: "phoneIconGrey",
  promote: "promote",
  refresh: "refresh",
  refreshDisabled: "refreshDisabled",
  refreshWhite: "refreshWhite",
  remove: "remove",
  report: "report",
  reportDisabled: "reportDisabled",
  requestDisabled: "requestDisabled",
  requestFile: "requestFile",
  requestFileDisabled: "requestFileDisabled",
  requestLatest: "requestLatest",
  required: "required",
  resize: "resize",
  resizeVertical: "resizeVertical",
  resume: "resume",
  saveInput: "saveInput",
  search: "search",
  searchDisabled: "searchDisabled",
  searchIcon: "searchIcon",
  searchInfo: "searchInfo",
  security: "security",
  securityBlue: "security_blue",
  selectedRadio: "selectedRadio",
  sendDisabled: "sendDisabled",
  sendEnabled: "sendEnabled",
  settingsBlue: "settings_blue",
  site: "site",
  smallLoader: "smallLoader",
  sortArrowUp: "sort_arrowUp",
  sortIdle: "sortIdle",
  swDeploy: "swDeploy",
  swDeployDisabled: "swDeployDisabled",
  swInfo: "swInfo",
  swPackageDetails: "swPackageDetails",
  tick: "circleTick_black",
  tickmark: "tickmark",
  toasterIconFill: "toasterIconFill",
  toggleMute: "toggleMute",
  toggleUnmute: "toggleUnmute",
  topArrow: "topArrow",
  trashBlue: "trashBlue",
  trashIcon: "trashIcon",
  unAcknowledge: "unAcknowledge",
  unAcknowledgeButton: "unAcknowledgeButton",
  unSelectedRadio: "unSelectedRadio",
  unlock: "unlock",
  upArrow: "UpArrows",
  userGroups: "userGroups",
  users: "users",
  whiteCloseIcon: "whiteCloseIcon"
}

export const APPLICATION_DETAILS = {
  applicationName: "ReadyFix",
  continueButton: "Continue",
  dontHaveAccount: "Don't have an account?",
  enterDetail: "*Please enter",
  forgotUserName: "Forget user name?",
  inValidID: "*Invalid",
  labelCity: "City",
  labelID: "ID",
  register: "Register"
}

export const BAR_CONFIG = {
  alerts: {
    colors: [colors["alertsSeverity-critical"], colors["alertsSeverity-moderate"], colors["alertsSeverity-low"]],
    icon: "alert",
    label: "In fault",
    toolTipMessages: ["Assets with critical alerts: ", "Assets with moderate alerts: ", "Assets with low alerts: "]
  },
  assets: {
    colors: [colors["assetStatus-available"], colors["assetStatus-inUse"], colors["assetStatus-offline"]],
    icon: "assets",
    label: "Assets",
    toolTipMessages: ["Assets in available: ", "Assets in use: ", "Assets in offline: "]
  },
  configs: {
    colors: [colors["updatesStatus-inProgress"], colors["updatesStatus-pending"], colors["updatesStatus-cancel"]],
    icon: "configs_bar",
    label: "In config",
    toolTipMessages: [
      "Assets with config in install: ",
      "Assets with config in download: ",
      "Assets with config failed: "
    ]
  },
  defaults: [colors["defaultBars-inProgress"], colors["defaultBars-pending"], colors["defaultBars-cancel"]],
  swpackages: {
    colors: [colors["updatesStatus-inProgress"], colors["updatesStatus-pending"], colors["updatesStatus-cancel"]],
    icon: "update",
    label: "swpackages",
    toolTipMessages: [
      "Assets with updates in progress: ",
      "Assets with updates pending: ",
      "Assets with updates canceled: "
    ]
  },
  updates: {
    colors: [colors["updatesStatus-inProgress"], colors["updatesStatus-pending"], colors["updatesStatus-cancel"]],
    icon: "updates_bar",
    label: "In update",
    toolTipMessages: [
      "Assets with updates in progress: ",
      "Assets with updates pending: ",
      "Assets with updates failed: "
    ]
  }
}

export const DISTRIBUTION_CONFIG = {
  alerts: {
    bars: [
      {
        barTitle: "Critical",
        color: colors["alertsSeverity-critical"]
      },
      {
        barTitle: "Moderate",
        color: colors["alertsSeverity-moderate"]
      },
      {
        barTitle: "Low",
        color: colors["alertsSeverity-low"]
      }
    ],
    label: "Alerts"
  },
  assets: {
    bars: [
      {
        barTitle: "Available",
        color: colors["assetStatus-available"]
      },
      {
        barTitle: "InUse",
        color: colors["assetStatus-inUse"]
      },
      {
        barTitle: "Offline",
        color: colors["assetStatus-offline"]
      }
    ],
    label: "Assets"
  },
  assets_in_config: {
    bars: [
      {
        barTitle: "InInstall",
        color: colors["configsStatus-inInstall"]
      },
      {
        barTitle: "InDownload",
        color: colors["configsStatus-inDownload"]
      },
      {
        barTitle: "Failed",
        color: colors["configsStatus-failed"]
      }
    ],
    label: "assets_in_config"
  },
  config_activities: {
    bars: [
      {
        barTitle: "InInstall",
        color: colors["configsStatus-inInstall"]
      },
      {
        barTitle: "InDownload",
        color: colors["configsStatus-inDownload"]
      },
      {
        barTitle: "Failed",
        color: colors["configsStatus-failed"]
      }
    ],
    label: "config_activities"
  },
  configs: {
    bars: [
      {
        barTitle: "InInstall",
        color: colors["configsStatus-inInstall"]
      },
      {
        barTitle: "InDownload",
        color: colors["configsStatus-inDownload"]
      },
      {
        barTitle: "Failed",
        color: colors["configsStatus-failed"]
      }
    ],
    label: "configs"
  },
  faults: {
    bars: [
      {
        barTitle: "Critical",
        color: colors["alertsSeverity-critical"]
      },
      {
        barTitle: "Moderate",
        color: colors["alertsSeverity-moderate"]
      },
      {
        barTitle: "Low",
        color: colors["alertsSeverity-low"]
      }
    ],
    label: "faults"
  },
  swpackages: {
    bars: [
      {
        barTitle: "InProgress",
        color: colors["updatesStatus-inProgress"]
      },
      {
        barTitle: "Pending",
        color: colors["updatesStatus-pending"]
      },
      {
        barTitle: "Failed",
        color: colors["updatesStatus-cancel"]
      }
    ],
    label: "swpackages"
  },
  swpackagesactivity: {
    bars: [
      {
        barTitle: "InProgress",
        color: colors["updatesStatus-inProgress"]
      },
      {
        barTitle: "Pending",
        color: colors["updatesStatus-pending"]
      },
      {
        barTitle: "Failed",
        color: colors["updatesStatus-cancel"]
      }
    ],
    label: "swpackagesactivity"
  },
  swpackagesupdate: {
    bars: [
      {
        barTitle: "InProgress",
        color: colors["updatesStatus-inProgress"]
      },
      {
        barTitle: "Pending",
        color: colors["updatesStatus-pending"]
      },
      {
        barTitle: "Failed",
        color: colors["updatesStatus-cancel"]
      }
    ],
    label: "swpackagesupdate"
  },
  updates: {
    bars: [
      {
        barTitle: "InProgress",
        color: colors["updatesStatus-inProgress"]
      },
      {
        barTitle: "Pending",
        color: colors["updatesStatus-pending"]
      },
      {
        barTitle: "Failed",
        color: colors["updatesStatus-cancel"]
      }
    ],
    label: "Updates"
  }
}

export const POPUP_NAMES = {
  confirmationPopUp: "confirmationPopUp",
  moveFleetPopUp: "moveFleetPopUp"
}

export const DEFAULT_IMAGE = "/assets/images/assetModels/default.svg"
export const ASSET_IMAGE = "/assets/images/monitor.svg"
const ALERTS_SEVERITY_ICONS_BASE_PATH = "/assets/icons/alertSeverity/"
const ALERTS_SW_STATUS_ICONS_BASE_PATH = "/assets/icons/alertSWStatus/"
const ALERTS_CONFIG_STATUS_ICONS_BASE_PATH = "/assets/icons/alertConfigStatus/"
export const ACKNOWLEDGE = "Acknowledge"
export const UNACKNOWLEDGE = "Un-acknowledge"
export const PROMOTE = " Promote configuration"
export const DEMOTE = "Confirm demote configuration"
export const MOVE_ASSETS_HEADING = "Move assets to"
export const DOWN_ARROW = "downArrow"
export const ADD = "Add"
export const ASSIGN = "Assign"
export const UP_ARROW = "upArrow"
export const SIZE = "11%"
export const CONFIRM = "Confirm"
export const CANCEL = "Cancel"
export const APPLY = "Apply"
export const RESET = "Reset"
export const OK = "OK"
export const FILTER_HEADING = "Filter"
export const FILTER_BY = "Filter by"
export const GROUP_BY = "Group by"
export const ALERT_ICON = "severityCritical"
export const SAVE = "Save"
export const UPDATE = "Update"
export const CREATE = "Create"
export const MOVE = "Move"
export const NOTES = "Notes"
export const CLOSE = "Close"
export const MUTE = "Disable"
export const UNMUTE = "Enable"

export const MUTE_UNMUTE_POPUPTITLE = {
  mute: "Mute device",
  unMute: "Un-mute device"
}

export const CREATE_ACTION_TITLE = {
  fleetName: "New fleet",
  subfleet: "New sub-fleet"
}

export const DRAG_ICON_BASE_PATH = {
  altText: "drag-icon",
  icon: "/assets/icons/drag.svg"
}

export const SEVERITY_LEVELS = {
  CRITICAL: {
    ICON: `${ALERTS_SEVERITY_ICONS_BASE_PATH}severityCritical.svg`,
    TEXT: "Critical",
    TOOLTIP: "Critical"
  },
  LOW: {
    ICON: `${ALERTS_SEVERITY_ICONS_BASE_PATH}severityLow.svg`,
    TEXT: "Low",
    TOOLTIP: "Low"
  },
  MODERATE: {
    ICON: `${ALERTS_SEVERITY_ICONS_BASE_PATH}severityModerate.svg`,
    TEXT: "Moderate",
    TOOLTIP: "Moderate"
  }
}

export const ALERT_CONFIG_STATUS = {
  CANCELED: {
    BG_COLOR: "#D1D2D4",
    COLOR: "#313235",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}cancelled.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Canceled",
    TOOLTIP: "Config deployment canceled"
  },
  CANCELLED: {
    BG_COLOR: "#D1D2D4",
    COLOR: "#313235",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}cancelled.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Cancelled",
    TOOLTIP: "Config deployment cancelled"
  },
  FAILED: {
    BG_COLOR: "#A32940",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}failed.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Failed",
    TOOLTIP: "Config deployment failed"
  },
  INDOWNLOAD: {
    BG_COLOR: "#9FBADA",
    COLOR: "#313235",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}inProgress.svg`,
    TEXT: "In download",
    TOOLTIP: "Config deployment in download"
  },
  ININSTALL: {
    BG_COLOR: "#4B74A3",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}inProgress.svg`,
    TEXT: "In install",
    TOOLTIP: "Config deployment in install"
  },
  PAUSED: {
    BG_COLOR: "#9FBADA",
    COLOR: "#313235",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}pause.svg`,
    TEXT: "Paused",
    TOOLTIP: "Config deployment paused"
  },
  REJECTED: {
    BG_COLOR: "#DBA4A4",
    COLOR: "#313235",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}rejected.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Rejected",
    TOOLTIP: "Config deployment rejected"
  },
  SUCCESS: {
    BG_COLOR: "#8FBF9D",
    COLOR: "#313235",
    ICON: `${ALERTS_CONFIG_STATUS_ICONS_BASE_PATH}success.svg`,
    TEXT: "Success",
    TOOLTIP: "Config deployment success"
  }
}

export const SOFTWARE_RELEASE_STATE_LEVELS = {
  CURRENTINSTALLED: {
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}greenRadio.svg`,
    TOOLTIP: "Current installed"
  },
  NEWRELEASE: {
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}blueRadio.svg`,
    TOOLTIP: "New release"
  }
}

export const ALERT_SW_STATUS = {
  CANCELED: {
    BG_COLOR: "#D1D2D4",
    COLOR: "#313235",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}cancelled.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Canceled",
    TOOLTIP: "SW package canceled"
  },
  CANCELLED: {
    BG_COLOR: "#D1D2D4",
    COLOR: "#313235",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}cancelled.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Cancelled",
    TOOLTIP: "SW package cancelled"
  },
  FAILED: {
    BG_COLOR: "#A32940",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}failed.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Failed",
    TOOLTIP: "SW package failed"
  },
  INPROGRESS: {
    BG_COLOR: "#4B74A3",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgress.svg`,
    TEXT: "In progress",
    TOOLTIP: "SW update in-progress"
  },
  INPROGRESSSTEP1: {
    BG_COLOR: "#4B74A3",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgress.svg`,
    STEP_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgressStep1.svg`,
    TEXT: "In progress",
    TOOLTIP: "SW update in progress, step 1 waiting for connection"
  },
  INPROGRESSSTEP2: {
    BG_COLOR: "#4B74A3",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgress.svg`,
    STEP_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgressStep2.svg`,
    TEXT: "In progress",
    TOOLTIP: "SW update in progress, step 2 download in progress"
  },
  INPROGRESSSTEP3: {
    BG_COLOR: "#4B74A3",
    COLOR: "#F9F9FA",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgress.svg`,
    STEP_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}inProgressStep3.svg`,
    TEXT: "In progress",
    TOOLTIP: "SW update in progress, step 3 install in progress"
  },
  PAUSED: {
    BG_COLOR: "#9FBADA",
    COLOR: "#313235",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}pause.svg`,
    TEXT: "Paused",
    TOOLTIP: "SW installation paused"
  },
  REJECTED: {
    BG_COLOR: "#DBA4A4",
    COLOR: "#313235",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}rejected.svg`,
    INFO_ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}swInfo.svg`,
    TEXT: "Rejected",
    TOOLTIP: "SW package rejected"
  },
  SCHEDULED: {
    BG_COLOR: "#9FBADA",
    COLOR: "#313235",
    DATE_TIME: (inputDate) => format(new Date(inputDate), DATE_FORMAT.dateTime),
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}scheduled.svg`,
    TEXT: "Scheduled",
    TOOLTIP: "SW package scheduled"
  },
  SUCCESS: {
    BG_COLOR: "#8FBF9D",
    COLOR: "#313235",
    ICON: `${ALERTS_SW_STATUS_ICONS_BASE_PATH}installed.svg`,
    TEXT: "Success",
    TOOLTIP: "SW package installed"
  }
}

export const ASSET_STATUS = {
  AVAILABLE: {
    COLOR_CODE: colors["assetStatus-available"],
    TEXT: "Available"
  },
  INUSE: {
    COLOR_CODE: colors["assetStatus-inUse"],
    TEXT: "In use"
  },
  OFFLINE: {
    COLOR_CODE: colors["assetStatus-offline"],
    TEXT: "Offline"
  }
}

export const ASSETS_NOT_IN_FLEET = {
  icon: "assets"
}

export const CARD_VIEW_ICON = {
  altText: "view-icon",
  icon: "/assets/icons/ellipsis.svg"
}

export const FLEET_CARD = {
  icon: "fleet"
}

export const BREADCRUMB_ICONS = {
  breadcrumbAltText: "breadcrumb",
  breadcrumbIcon: "/assets/icons/breadcrumb.svg",
  separatorAltText: "breadcrumb-separator",
  separatorIcon: "/assets/icons/arrow-right.svg"
}

export const ROUTE_CONFIG = {
  ACCESS_DENIED: {
    routeName: "access-denied"
  },
  ASSET: {
    param: "assetId",
    routeName: "assets"
  },
  ASSET_FAULTS: {
    routeName: "asset-faults"
  },
  CENTRAL_CONFIG: {
    routeName: "configurations"
  },
  CUSTOMER: {
    param: "customerId",
    routeName: "customers"
  },
  DEPLOY_CONFIG: {
    param: "deployConfigId",
    routeName: "configurations-deployment"
  },
  FLEET: {
    param: "fleetId",
    routeName: "fleets"
  },
  FLEET_ACCESS_DENIED: {
    routeName: "fleets-error"
  },
  FLEET_ASSET_TYPE: {
    param: "fleetModel",
    routeName: "fleet-asset-type"
  },
  FLEET_CONNECTION: {
    param: "fleetConnectionType",
    routeName: "fleet-connection-type"
  },
  FLEET_MODALITY: {
    param: "fleetModalityType",
    routeName: "fleet-modality"
  },
  LOGIN: {
    routeName: "login"
  },
  LOGIN_CALLBACK: {
    routeName: "signin-oauth/code/callback/idam"
  },
  MANAGE_USERS: {
    routeName: "manage"
  },
  REPORTS: {
    param: "",
    routeName: "reports"
  },
  SITE: {
    param: "siteId",
    routeName: "sites"
  },
  SITE_ASSET_TYPE: {
    param: "siteModel",
    routeName: "site-asset-type"
  },
  SITE_CONNECTION: {
    param: "siteConnectionType",
    routeName: "site-connection-type"
  },
  SITE_MODALITY: {
    param: "siteModalityType",
    routeName: "site-modality"
  },
  SOFTWARE_DEPLOYMENT: {
    param: "deploySoftwareId",
    routeName: "software-deployment"
  },
  SOFTWARE_UPDATE: {
    param: "",
    routeName: "swupdate"
  },
  SUB_FLEET: {
    param: "subFleetId",
    routeName: "sub_fleets"
  },
  USER: {
    param: "",
    routeName: "user"
  },
  VIEW_PROFILE: {
    param: "",
    routeName: "profile"
  }
}

export const ToasterConfig = {
  TYPE: {
    CRITICAL: "critical",
    INFO: "info",
    LOW: "low",
    MEDIUM: "medium",
    SUCCESS: "success"
  },
  duration: 5000,
  position: {
    horizontal: "right",
    vertical: "top"
  }
}

export const EDITABLE_FIELD_TYPE = {
  ASSET_NAME: "assetName",
  CUSTOMER_NAME: "customerName",
  FLEET_CARD: "fleetName",
  SITE_CARD: "siteName",
  SUB_FLEET_CARD: "subfleet"
}

export const POPUP_TITLE = {
  customerName: "enterprise name",
  fleetName: "fleet name",
  siteName: "site name",
  subfleet: "sub-fleet name"
}

export const EDIT_OVERLAY_CHAR_LENGTH = {
  assetName: 50,
  customerName: 100,
  fleetName: 50,
  siteName: 50,
  subfleet: 50
}

export const EDIT_OVERLAY_GENERAL_INFO = {
  assetName: `Minimum 3 char, maximum ${EDIT_OVERLAY_CHAR_LENGTH.assetName} characters.Allowed characters: A-Z a-z 0-9 - _ : , .`,
  customerName: `Minimum 3 char, maximum ${EDIT_OVERLAY_CHAR_LENGTH.customerName} characters.Allowed characters: A-Z a-z 0-9 - _ : , .`,
  fleetName: `Minimum 3 char, maximum ${EDIT_OVERLAY_CHAR_LENGTH.fleetName} characters.Allowed characters: A-Z a-z 0-9 - _ : , .`,
  siteName: `Minimum 3 char, maximum ${EDIT_OVERLAY_CHAR_LENGTH.siteName} characters.Allowed characters: A-Z a-z 0-9 - _ : , .`,
  subfleet: `Minimum 3 char, maximum ${EDIT_OVERLAY_CHAR_LENGTH.subfleet} characters.Allowed characters: A-Z a-z 0-9 - _ : , .`
}

export const EDIT_OVERLAY_ERROR_MESSAGE = {
  assetName: {
    characterLength: `Asset name cannot exceed ${EDIT_OVERLAY_CHAR_LENGTH.assetName} characters.`,
    emptyText: "Asset name cannot be empty.",
    specialCharactersNotAllowed: "Allowed characters: A-Z a-z 0-9 - _ : , .",
    uniqueName: "The provided asset name already exists."
  },
  customerName: {
    characterLength: `Enterprise name cannot exceed ${EDIT_OVERLAY_CHAR_LENGTH.customerName} characters.`,
    emptyText: "Enterprise name cannot be empty.",
    uniqueName: "The provided customer name already exists."
  },
  fleetName: {
    characterLength: `Fleet name cannot exceed ${EDIT_OVERLAY_CHAR_LENGTH.fleetName} characters.`,
    emptyText: "Fleet name cannot be empty.",
    uniqueName: "The provided fleet name already exists."
  },
  siteName: {
    characterLength: `Site name cannot exceed ${EDIT_OVERLAY_CHAR_LENGTH.siteName} characters.`,
    emptyText: "Site name cannot be empty.",
    uniqueName: "The provided site name already exists."
  },
  subfleet: {
    characterLength: `Sub-fleet name cannot exceed ${EDIT_OVERLAY_CHAR_LENGTH.subfleet} characters.`,
    emptyText: "Sub-fleet name cannot be empty.",
    uniqueName: "The provided sub-fleet name already exists."
  }
}

export const specialCharactersNotAllowed = [
  "~",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "?",
  "/",
  "\\",
  "|",
  "<",
  ">",
  "'",
  "\"",
  "`",
  "!",
  "=",
  "+",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
  ";"
]

export const specialCharactersNotAllowedForSearch = /[\\%|'{}^&#]/g

export const DISTRIBUTION = [
  {
    distribution: [5, 2, 3],
    type: "assets"
  },
  {
    distribution: [5, 1, 4],
    type: "alerts"
  },
  {
    distribution: [5, 3, 2],
    type: "configs"
  },
  {
    distribution: [5, 3, 2],
    type: "updates"
  }
]

export const EXPANDED_CARD_DISTRIBUTION = [
  {
    distribution: [0, 0, 0],
    type: "assets"
  },
  {
    distribution: [0, 0, 0],
    type: "alerts"
  },
  {
    distribution: [0, 0, 0],
    type: "configs"
  },
  {
    distribution: [0, 0, 0],
    type: "updates"
  }
]

export const DROPDOWN_OPTIONS = {
  FLEET_VIEW_GROUP_BY: [
    {
      id: 1,
      label: "Sub-fleet"
    },
    {
      id: 2,
      label: "Modality"
    },
    {
      id: 3,
      label: "Model"
    },
    // {
    //   id: 4,
    //   label: "Status"
    // },
    // {
    //   id: 5,
    //   label: "Fault"
    // },
    {
      id: 6,
      label: "Connection"
    },
    {
      id: 7,
      label: "Assets"
    }
  ],
  SITE_VIEW_GROUP_BY: [
    {
      id: 1,
      label: "Fleet"
    },
    {
      id: 2,
      label: "Modality"
    },
    {
      id: 3,
      label: "Model"
    },
    // {
    //   id: 4,
    //   label: "Status"
    // },
    // {
    //   id: 5,
    //   label: "Fault"
    // },
    {
      id: 6,
      label: "Connection"
    },
    {
      id: 7,
      label: "Assets"
    }
  ],

  SUB_FLEET_VIEW_GROUP_BY: [
    {
      id: 2,
      label: "Modality"
    },
    {
      id: 3,
      label: "Model"
    },
    // {
    //   id: 4,
    //   label: "Status"
    // },
    // {
    //   id: 5,
    //   label: "Fault"
    // },
    {
      id: 6,
      label: "Connection"
    },
    {
      id: 7,
      label: "Assets"
    }
  ]
}

export const FLEET_CARD_MENU_DATA = [
  {
    accessKey: FleetAuthElement.UpdateFleet || SubFleetAuthElement.UpdateSubFleet,
    icon: ICON_NAME.editIcon,
    id: 1,
    name: "edit"
  },
  {
    accessKey: FleetAuthElement.DeleteFleet || SubFleetAuthElement.DeleteSubFleet,
    icon: ICON_NAME.trashIcon,
    id: 3,
    name: "delete"
  }
]

export const CONFIG_DEPLOY_DROPDOWN_OPTIONS = {
  SITE_VIEW_GROUP_BY: [
    {
      id: 1,
      label: "Asset"
    }
    // {
    //   id: 2,
    //   label: "Asset type"
    // },
    // {
    //   id: 3,
    //   label: "SW Version"
    // }
  ]
}

export const ASSET_FAULTS_DROPDOWN_OPTIONS = {
  SITE_VIEW_GROUP_BY: [
    {
      id: 1,
      label: "Severity"
    },
    {
      id: 2,
      label: "Model"
    }
  ]
}

export const FLEET_CARD_MENU = {
  horizontal: "right",
  vertical: "top"
}

export const defaultDistributionBarData = [6, 2, 2]

export const defaultFleetCardDetails = {
  distributionData: [
    {
      distribution: [0, 0, 0],
      type: "assets"
    },
    {
      distribution: [0, 0, 0],
      type: "alerts"
    },
    {
      distribution: [0, 0, 0],
      type: "updates"
    }
  ],
  name: "New fleet"
}

export const defaultSubFleetCardDetails = {
  distributionData: [
    {
      distribution: [0, 0, 0],
      type: "assets"
    },
    {
      distribution: [0, 0, 0],
      type: "alerts"
    },
    {
      distribution: [0, 0, 0],
      type: "updates"
    }
  ],
  name: "New sub-fleet"
}

export const SEARCH_ERROR = {
  assetFaults: {
    name: "siteFaults",
    options: ["Code"]
  },
  centralConfig: {
    name: "centralConfig",
    options: ["Serial number", "Asset Name"]
  },
  centralConfigDeployment: {
    name: "centralConfigDeployment",
    options: ["Serial number", "Asset Name", "IP Address"]
  },
  customerView: {
    name: "customerView",
    options: ["Site name", "Site ID"]
  },
  siteView: {
    name: "siteView",
    options: ["Serial nbr", "Asset Name", "IP Address"]
  },
  userManagementGroup: {
    name: "groups",
    options: ["User group"]
  },
  userManagementUser: {
    name: "users",
    options: ["User name"]
  }
}

export const SEARCH_DEFAULT_MIN_CHARACTERS = 3
export const EDIT_OVERLAY_DEFAULT_MIN_CHARACTERS = 0

/**
 *  Global Search is useful to search string and numbers over all ARRAY of OBJECTS
 *  search string is not null
 */
export function globalSearch({ data, searchString = "", includeKeys }) {
  if (searchString != "" && data?.length > 0) {
    return data.filter((entry) => {
      if (includeKeys && includeKeys.length > 0) {
        let tempState = {}
        includeKeys.map((eachKey) => {
          tempState[eachKey] = entry[eachKey]
        })
        entry = tempState
      }
      return recurse(entry, searchString)
    })
  } else {
    return data
  }
}

const recurse = (object, searchString) => {
  return Object.values(object).some((val) => {
    if (val && typeof val == "object") {
      return recurse(val)
    } else {
      if (typeof val !== "string" && val !== null) {
        val = (val || "").toString()
      }
      return (val || "").toLowerCase().includes(searchString.toLowerCase())
    }
  })
}

export const ACTIVITY_TYPE_DROPDOWN_OPTIONS = {
  ACTIVITY_TYPE: [
    {
      id: 1,
      label: "Fleet"
    },
    {
      id: 2,
      label: "Configuration"
    },
    {
      id: 3,
      label: "File transfer"
    },
    {
      id: 4,
      label: "Preference"
    },
    {
      id: 5,
      label: "Profile"
    },
    {
      id: 6,
      label: "Asset"
    }
  ]
}

export const ACTIVITY_TYPE = "Activity type"

export const DROPDOWN = {
  selectAll: "selectAll",
  selectAllLabelText: "Select all",
  selected: " selected"
}

export const FLEET_CARD_MENU_ORIGIN = {
  horizontal: "right",
  vertical: "bottom"
}

export const ASSET_DETAILS = {
  mute: "Disable",
  muteIcon: "disableAsset"
}

export const getDropdownPlaceholder = (option, selectedValues, defaultPlaceholder) =>
  (option.key === COMPARE_VALUE.model && selectedValues.modality === undefined) ||
  (option.key === COMPARE_VALUE.severity && selectedValues.modality === undefined) ||
  (option.key === COMPARE_VALUE.acknowledgement && selectedValues.modality === undefined)
    ? ""
    : defaultPlaceholder

export const CONFIG_SW_STATUS = {
  CANCELED: "canceled",
  CANCELLATION_REQUESTED: "cancellation-requested",
  DELIVERED: "delivered",
  FAILED: "failed",
  INPROGRESSSTEP1: "waiting for connection",
  INPROGRESSSTEP2: "download in progress",
  INPROGRESSSTEP3: "installation in progress",
  INSTALLED: "installed",
  IN_PROGRESS: "in-progress",
  PAUSED: "paused",
  PENDING: "pending",
  QUEUED: "queued",
  REJECTED: "rejected",
  SCHEDULED: "scheduled",
  SUCCESS: "success",
  TIMED_OUT: "timed-out",
  TIMEOUT_REQUESTED: "timeout-requested"
}
