import React from "react"


import ErrorBoundary from "./components/Error/ErrorBoundary"
import { AwsAppLoggerProvider } from "./contexts/AwsAppLoggerContext"
import GlobalContextWrapper from "./contexts/GlobalContextWrapper"
import PageRoutes from "./routes/PageRoutes"
import "./utils/styles/globalStyles.scss"

function App() {
  return (
    <AwsAppLoggerProvider>
      <GlobalContextWrapper>
        <ErrorBoundary>
          <PageRoutes />
        </ErrorBoundary>
      </GlobalContextWrapper>
    </AwsAppLoggerProvider>
  )
}

export default App
