import axios from "axios"

import { apiServer } from "../../config/serverConfig"
import { UNAUTHORIZED_USER } from "../../utils/Auth/auth"
import { REFRESH_TOKEN_RES, getUserSession, validateTokenExpiration } from "../../utils/Auth/token"
import { logoutUser } from "../idam/login"

const axiosClient = axios.create({
  baseURL: apiServer,
  headers: {
    Accept: "application/json"
  }
})

axiosClient.interceptors.request.use(
  async (config) => {
    const res = await validateTokenExpiration()
    if (res?.data?.error) {
      await logoutUser()
      return
    }
    const { accessToken } = getUserSession()
    if (accessToken) {
      config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` }
    }
    return config
  },
  (error) => {
    Promise.reject(error.response || error.message)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (
      error?.response?.status === UNAUTHORIZED_USER.ErrorStatus &&
      (error?.response?.data?.message == UNAUTHORIZED_USER.Message ||
        error?.response?.data?.error?.type == UNAUTHORIZED_USER.ErrorType)
    ) {
      const originalRequest = error.config
      if (!originalRequest._retry) {
        originalRequest._retry = true
        const res = await validateTokenExpiration()
        if (res?.data?.error || res?.status === REFRESH_TOKEN_RES.VALID_TOKEN) {
          await logoutUser()
          return Promise.reject(error?.response || error?.message)
        }
        const { accessToken } = getUserSession()
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
        return axiosClient(originalRequest)
      }
    }
    return Promise.reject(error?.response || error?.message)
  }
)

export default axiosClient
