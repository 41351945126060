import React from "react"

const DropdownIcon = (props) => {
  return (
    <svg
      { ...props }
      width="14"
      height="8"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M8.00005 8.49957C7.93448 8.50037 7.86946 8.48743 7.80918 8.4616C7.7489 8.43577 7.6947 8.3976 7.65005 8.34957L1.15005 1.84957C1.10222 1.80468 1.0641 1.75046 1.03804 1.69026C1.01198 1.63006 0.998535 1.56516 0.998535 1.49957C0.998535 1.43397 1.01198 1.36907 1.03804 1.30887C1.0641 1.24867 1.10222 1.19446 1.15005 1.14957C1.19494 1.10173 1.24916 1.06361 1.30936 1.03755C1.36956 1.01149 1.43446 0.998047 1.50005 0.998047C1.56565 0.998047 1.63055 1.01149 1.69075 1.03755C1.75095 1.06361 1.80516 1.10173 1.85005 1.14957L8.00005 7.28957L14.1501 1.14957C14.196 1.1036 14.2506 1.06714 14.3106 1.04227C14.3707 1.01739 14.4351 1.00459 14.5001 1.00459C14.5651 1.00459 14.6294 1.01739 14.6895 1.04227C14.7495 1.06714 14.8041 1.1036 14.8501 1.14957C14.896 1.19553 14.9325 1.25009 14.9574 1.31015C14.9822 1.3702 14.995 1.43456 14.995 1.49957C14.995 1.56457 14.9822 1.62893 14.9574 1.68898C14.9325 1.74904 14.896 1.8036 14.8501 1.84957L8.35005 8.34957C8.30541 8.3976 8.2512 8.43577 8.19093 8.4616C8.13065 8.48743 8.06563 8.50037 8.00005 8.49957Z"
        fill="#2B2D2E"
      />
    </svg>
  )
}

export default DropdownIcon
