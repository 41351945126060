import { generateUUID } from "../../../../utils/Common/common"
import { DATE_FORMAT } from "../../../../utils/Constants/TimeZones"
import { convertToServerTimeZone, softwareStatus } from "../../../../utils/helper"

export const transformSWPackage = (data) => {
  const swPackage = []
  for (const software of data) {
    swPackage.push({
      assetId: software.id,
      assetName: software.assetName,
      description: software.description,
      inProgressStatus: software.inProgressStatus,
      initiatedOn: software.deployInitiatedDate,
      lastUpdated: software.lastUpdated,
      name: software.name,
      notes: software.note,
      serialNumber: software.serialNumber,
      status: softwareStatus(software?.status?.toLowerCase(), software?.inProgressStatus?.toLowerCase()),
      user: software.userName,
      version: software.version
    })
  }
  return swPackage
}

export const mergeDateAndTime = (date1, date2) => {
  const date = new Date(date1)
  const time = new Date(date2)
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    0
  )
}

export const getDeploySoftwarePayload = (
  packageDetails,
  deployDetails,
  assetSerialNumber,
  siteUcmId
) => {
  return {
    packageId: packageDetails?.id,
    requestPayload: {
      correlationId: generateUUID(),
      description: packageDetails?.description,
      name: packageDetails?.name,
      note: deployDetails?.notes,
      serialNumbers: Array.isArray(assetSerialNumber) ? assetSerialNumber : [assetSerialNumber],
      siteId: siteUcmId,
      uploadedDate: convertToServerTimeZone(new Date(packageDetails?.uploadedDate), DATE_FORMAT.dateTime24),
      version: packageDetails?.version
    }
  }
}

export const getScheduledSoftwarePackagePayload = (
  packageDetails,
  deployDetails,
  updatedDate,
  updatedTime,
  assetSerialNumber,
  siteUcmId
) => {
  const scheduledDate = mergeDateAndTime(updatedDate, updatedTime)
  return {
    packageId: packageDetails?.id,
    requestPayload: {
      correlationId: generateUUID(),
      description: packageDetails?.description,
      name: packageDetails?.name,
      note: deployDetails?.notes,
      scheduledDate: convertToServerTimeZone(scheduledDate, DATE_FORMAT.dateTime24),
      serialNumbers: Array.isArray(assetSerialNumber) ? assetSerialNumber : [assetSerialNumber],
      siteId: siteUcmId,
      uploadedDate: convertToServerTimeZone(new Date(packageDetails?.uploadedDate), DATE_FORMAT.dateTime24),
      version: packageDetails?.version
    }
  }
}

export const getReScheduledSoftwarePackagePayload = (
  packageDetails,
  deployDetails,
  updatedDate,
  updatedTime,
  assetSerialNumber
) => {
  const scheduledDate = mergeDateAndTime(updatedDate, updatedTime)
  return {
    packageId: packageDetails?.id,
    requestPayload: {
      note: deployDetails?.notes,
      scheduledDate: convertToServerTimeZone(scheduledDate, DATE_FORMAT.dateTime24)
    },
    serialNumber: assetSerialNumber 
  }
}
