import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./Distribution.module.scss"
import EmptyDistributionBar from "./EmptyDistributionBar.jsx"

import { BAR_CONFIG } from "../../utils/GlobalConstants.js"
import { classNames } from "../../utils/styles/helper.js"
import IconLabel from "../IconLabel/IconLabel"
import NumberDistributionBar from "../NumberDistributionBar/NumberDistributionBar"

const Distribution = ({ displayLabelText, displayBars, type, distribution, isDefaultFleet, customStyles }) => {
  const { t } = useTranslation(["asset"])
  const label = t(BAR_CONFIG[type].label)

  const getCount = (count) => {
    let totalCount = 0
    count.forEach((element) => {
      totalCount += element
    })
    return totalCount
  }

  return (
    <div className={ customStyles }>
      <span className={ classNames(styles.iconLabel, !displayLabelText && styles.iconWithoutLabel) }>
        <span className={ styles.iconLabelWrapper }>
          <IconLabel
            displayLabelText={ displayLabelText }
            iconName={ BAR_CONFIG[type].icon }
            label={ label }
            iconStyles={ styles.icon }
          />
        </span>
        <span data-testid={ `${type}-count` } className={ `${styles.count}` }>
          { getCount(distribution) }
        </span>
      </span>
      { displayBars && getCount(distribution) > 0 ? (
        <NumberDistributionBar type={ type } distribution={ distribution } isDefaultFleet={ isDefaultFleet } />
      ) : (
        <EmptyDistributionBar />
      ) }
    </div>
  )
}

Distribution.propTypes = {
  customStyles: PropTypes.any,
  displayBars: PropTypes.bool,
  displayLabelText: PropTypes.bool,
  distribution: PropTypes.arrayOf(PropTypes.number),
  isDefaultFleet: PropTypes.bool,
  type: PropTypes.oneOf(["assets", "alerts", "updates", "swpackages", "configs"]).isRequired
}

Distribution.defaultProps = {
  displayBars: true,
  displayLabelText: true,
  distribution: [],
  isDefaultFleet: false
}

export default Distribution
