import colors from "../../styles/jsExports/colorExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const Accordion = {
  styleOverrides: {
    root: {
      "& .MuiAccordionSummary-root": {
        "&.Mui-expanded": {
          minHeight: "unset"
        },
        "&.Mui-expanded.siteView": {
          border: `${margins["marginInPixel-px2"]} solid ${colors["secondary"]}`,
          height: margins["marginInPixel-px52"],
          minHeight: "unset"
        },
        height: margins["marginInPixel-px52"],
        minHeight: "unset",
        paddingLeft: margins["margins-px12"],
        paddingRight: margins["margins-px15"]
      },
      "& .MuiAccordionSummary-root.SiteNavigatorSummary": {
        "& .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters": {
          margin: 0
        },
        "&.MuiAccordionSummary-root:active": {
          "&.Mui-expanded": {
            backgroundColor: colors["siteNavigator-activeBg"]
          },
          backgroundColor: colors["siteNavigator-activeBg"]
        },
        "&.MuiAccordionSummary-root:hover": {
          "&.Mui-expanded": {
            backgroundColor: colors["siteNavigator-hoverBg"]
          },
          backgroundColor: colors["siteNavigator-hoverBg"]
        },
        cursor: "inherit",
        flexDirection: "row-reverse",
        gap: margins["marginInPixel-px8"],
        height: margins["marginInPixel-px32"],
        padding: `${margins["marginInPixel-px4"]} ${margins["marginInPixel-px8"]}`
      },
      "& .MuiButtonBase-root": {
        "&.MuiAccordionSummary-root": {
          "&.Mui-expanded.centralPages": {
            backgroundColor: colors["white"],
            border: `${margins["marginInPixel-px2"]} solid ${colors["secondary"]}`,
            height: margins["marginInPixel-px52"],
            minHeight: "unset"
          }
        },
        "&.MuiAccordionSummary-root:active": {
          "&.Mui-expanded": {
            backgroundColor: colors["white"]
          },
          backgroundColor: colors["buttons-contentPrimaryhover"]
        },
        "&.MuiAccordionSummary-root:hover": {
          "&.Mui-expanded": {
            backgroundColor: colors["white"]
          },
          backgroundColor: colors["cardHover"]
        },
        borderRadius: "8px"
      },
      "&.MuiAccordion-root": {
        "&.Mui-expanded": {
          margin: "8px 0"
        }
      },
      "&.MuiAccordion-rounded": {
        borderRadius: margins["marginInPixel-px1"],
        marginBottom: margins["marginInPixel-px8"]
      },
      border:"unset",
      boxShadow: "none"
    }
  }
}
