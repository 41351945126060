import { SubFleetAuthElement } from "../../utils/Constants/Auth/sub-fleet"
import { EMPTY_CELL_DEFAULT_VALUE } from "../../utils/Constants/DataTable"
import { softwareStatus } from "../../utils/helper"
import { mockAvailableUpdates } from "../SiteView/SiteUtils"

export const GROUP_BY_TYPES = {
  1: {
    responseKey: "fleets",
    serviceName: "fleets",
    titleKey: "fleetName"
  },
  2: {
    responseKey: "modalities",
    serviceName: "modalities",
    titleKey: "modality"
  },
  3: {
    responseKey: "models",
    serviceName: "models",
    titleKey: "model"
  },
  6: {
    responseKey: "connections",
    serviceName: "connections",
    titleKey: "connection"
  },
  7: {
    responseKey: "assets",
    serviceName: "assets",
    titleKey: "name"
  }
}

const getAssetsStatistics = (assets) => {
  return {
    countValue: assets?.total,
    distribution: [assets?.available, assets?.inUse, assets?.offline],
    mutedAssetCount: assets?.disabled,
    showMutedCount: true,
    type: "assets"
  }
}

const getFaultsStatistics = (faults) => {
  return {
    countValue: faults?.total,
    distribution: [faults?.critical, faults?.moderate, faults?.low],
    mutedAssetCount: faults?.disabled,
    showMutedCount: true,
    type: "alerts"
  }
}

const getConfigsStatistics = (configs) => {
  return {
    countValue: configs?.total,
    distribution: [configs?.inInstall, configs?.inDownload, configs?.failed],
    type: "configs"
  }
}

const getUpdatesStatistics = (updates) => {
  return {
    countValue: updates?.total,
    distribution: [updates?.inProgress, updates?.pending, updates?.failed],
    type: "updates"
  }
}

const getDistributionData = (data) => {
  const { assets, faults, updates, configs } = data
  return [
    getAssetsStatistics(assets),
    getFaultsStatistics(faults),
    getConfigsStatistics(configs),
    getUpdatesStatistics(updates)
  ]
}

export const transformSubFleetStatistics = ({ statistics }) => {
  const data = statistics || []
  return {
    distributionData: getDistributionData(data)
  }
}

export const transformFilterData = () => {
  return [
    {
      defaultValue: [],
      key: "modality",
      label: "Modality",
      multiple: true,
      options: [],
      required: true
    },
    {
      defaultValue: [],
      key: "model",
      label: "Model",
      multiple: true,
      options: []
    },
    {
      defaultValue: [],
      key: "status",
      label: "Status",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Available"
        },
        {
          id: 2,
          label: "In use"
        },
        {
          id: 3,
          label: "Offline"
        }
      ]
    },
    {
      defaultValue: [],
      key: "severity",
      label: "Fault severity",
      multiple: true,
      options: [
        {
          id: 1,
          label: "Critical"
        },
        {
          id: 2,
          label: "Moderate"
        },
        {
          id: 3,
          label: "Low"
        }
      ]
    }
  ]
}

export const transformAssets = (data = []) => {
  const assets = []

  for (const [index, asset] of data.entries()) {
    assets.push({
      availableUpdates: mockAvailableUpdates(asset.softwareVersion, index),
      ...asset,
      configStatus: asset?.configStatus?.toLowerCase(),
      connection: asset.connectionType,
      deviceId: asset.serialNumber,
      id: asset.id,
      ipAddress: asset.ipAddress,
      lastLocation: "Minnesota",
      mute: asset?.muted,
      name: asset?.friendlyName,
      scheduledTime: index === 4 ? new Date() : null,
      severity: asset?.faultSeverity,
      softwareVersion: asset?.softwareVersion,
      status: asset?.connectivityStatus || "Offline",
      subFleet: EMPTY_CELL_DEFAULT_VALUE,
      swStatus: softwareStatus(asset?.swStatus?.toLowerCase(), asset?.swInProgressStatus?.toLowerCase()),
      uuid: asset.id
    })
  }
  return assets
}

export const subFleetViewReducer = (newState, action) => {
  let prepareNewState = {}
  switch (action.type) {
  case SUB_FLEET_ACTION_TYPES.MOVE_ASSET_POPUP:
    return {
      ...newState,
      ...{
        moveAssetPopup: action.payload
      }
    }

  case SUB_FLEET_ACTION_TYPES.FILTER_INPUT:
    return {
      ...newState,
      ...{
        selectedFilters: action.payload
      }
    }

  case SUB_FLEET_ACTION_TYPES.CONFIRM_POPUP:
    return {
      ...newState,
      ...{
        confirmMovePopup: action.payload
      }
    }
  case SUB_FLEET_ACTION_TYPES.TOGGLE_VIEW:
    return {
      ...newState,
      ...{
        isCardView: !newState.isCardView
      }
    }
  case SUB_FLEET_ACTION_TYPES.SET_SELECTED_ASSETS:
    return {
      ...newState,
      ...{
        selectedAssets: action.payload
      }
    }
  case SUB_FLEET_ACTION_TYPES.SEARCH_ERROR:
    return {
      ...newState,
      ...{
        searchError: action.payload.hasError,
        searchInput: action.payload.value,
        searchResults: null,
        selectedAssets: []
      }
    }
  case SUB_FLEET_ACTION_TYPES.SEARCH_INPUT:
    prepareNewState = {
      selectedAssets: []
    }
    if (action.payload.closeAction) {
      prepareNewState.searchError = false
      prepareNewState.searchInput = null
      prepareNewState.closeAction = true
      return {
        ...newState,
        ...prepareNewState
      }
    }
    if (action.payload.value?.length >= 3) {
      prepareNewState.groupByMappingObject = GROUP_BY_TYPES[7]
      prepareNewState.searchInput = action.payload.value
      prepareNewState.searchError = false
      prepareNewState.closeAction = false
    } else if (action.payload.value?.length < 3) {
      prepareNewState.groupByMappingObject = GROUP_BY_TYPES[7]
      prepareNewState.searchError = true
    }
    return {
      ...newState,
      ...prepareNewState
    }
  case SUB_FLEET_ACTION_TYPES.FILTER_POPUP_STATE:
    return {
      ...newState,
      ...{
        isFilterOpen: action.payload
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

export const SUB_FLEET_ACTION_TYPES = {
  CONFIRM_POPUP: "openConfirmMoveFleetPopup",
  FILTER_INPUT: "filterInput",
  FILTER_POPUP_STATE: "filterPopupState",
  MOVE_ASSET_POPUP: "openMoveAssetPopup",
  SEARCH_ERROR: "searchError",
  SEARCH_INPUT: "searchInput",
  SET_GROUP_BY_CHANGE_METHOD: "setGroupByChangeMethod",
  SET_GROUP_BY_DROPDOWN: "setGroupBySelectedValue",
  SET_SELECTED_ASSETS: "setSelectedAssets",
  TOGGLE_VIEW: "toggleView"
}

export const SUB_FLEET_VIEW_STATE = {
  confirmMovePopup: false,
  groupByMappingObject: GROUP_BY_TYPES[1],
  groupBySelectedValue: 7,
  isCardView: false,
  isFilterOpen: false,
  moveAssetPopup: false,
  searchError: false,
  searchInput: null,
  selectedAssets: [],
  selectedFilters: []
}

export const SUB_FLEET_ACCESS = {
  moveAsset: SubFleetAuthElement.MoveAsset
}
