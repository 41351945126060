import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useReducer } from "react"

import { ABOUT_INITIAL_STATE, aboutReducer } from "../components/About/AboutUtils"

export const AboutContext = createContext(null)

export const AboutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(aboutReducer, ABOUT_INITIAL_STATE)

  const displayAboutPopup = () => {
    dispatch({
      type: "addToast"
    })
  }

  const context = useMemo(
    () => ({
      dispatch,
      displayAboutPopup,
      state
    }),
    [state]
  )
  return <AboutContext.Provider value={ context }>{ children }</AboutContext.Provider>
}

AboutProvider.propTypes = {
  children: PropTypes.any
}

export const useAbout = () => {
  return useContext(AboutContext)
}
