/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react"

import { useAuthContext } from "../../contexts/Auth/auth"

const useAccess = (accessKey) => {
  const { getAccess } = useAuthContext()
  return useMemo(() => getAccess(accessKey), [accessKey])
}

export default useAccess
