/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Tooltip } from "@mui/material"
import { flexRender } from "@tanstack/react-table"
import { PropTypes } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import style from "./DataTable.module.scss"

import {
  AssetsTableColumnConfig,
  REDIRECTABLE_TABLE_INSTANCES,
  TABLE_COLUMN_SPECIAL_ID_ACCESSORS
} from "../../utils/Constants/DataTable"
import { classNames } from "../../utils/styles/helper"

const GridRowCell = ({ cell, dndProvider, ...rest }) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const textElementRef = useRef(null)

  const compareSize = () => {
    if (!cell.column?.disableTooltip) {
      setTimeout(() => {
        const compare =
          textElementRef?.current?.scrollWidth >
          textElementRef?.current?.clientWidth
        setShowInfoTooltip(compare)
      }, 0)
    }
  }

  const headerFullSize = cell?.column?.getSize()
  const getCanResize = cell.column?.getCanResize()
  const isResizing =
    rest?.tableProps?.getState().columnSizingInfo.isResizingColumn

  useEffect(() => {
    compareSize()
  }, [headerFullSize, getCanResize, isResizing])

  useEffect(() => {
    compareSize()
  }, [])

  let view = flexRender(cell.column.columnDef.cell, {
    ...cell.getContext(),
    ...dndProvider
  })
  const getGridRowCell = () => {
    let isRedirectableCell = false
    if (
      REDIRECTABLE_TABLE_INSTANCES.includes(rest.type) &&
      !["temp", "selection", "action", "actions"].includes(cell?.column?.id)
    ) {
      isRedirectableCell = true
    }

    if (
      Object.values(TABLE_COLUMN_SPECIAL_ID_ACCESSORS).includes(
        cell.column.id
      ) ||
      isRedirectableCell
    ) {
      view = AssetsTableColumnConfig({
        cell,
        dndProvider,
        isRedirectableCell,
        isResizing,
        row: cell.row,
        type: rest.type
      })
    }

    if (
      !view?.props?.children &&
      !view.props.cell &&
      !["temp", "selection", "action", "actions"].includes(
        cell?.column?.columnDef?.accessorKey
      ) &&
      !["temp", "selection", "action", "actions"].includes(cell?.column?.id)
    ) {
      view = "--"
    }
    return (
      <div
        ref={ textElementRef }
        role="cell"
        key={ cell.id }
        className={ classNames(
          style.columnCellStyles,
          cell.column.id === "selection" && style.selectionCell,
          cell.column.columnDef?.className,
          isRedirectableCell && "noPadding",
          cell?.column?.getIsResizing() && style.resizingRowCell,
          rest?.tableProps?.getState().currentResizingColumnHover ==
            cell.column.id && style.hoveredOnResize
        ) }
      >
        { view }
      </div>
    )
  }

  return (
    <Tooltip
      title={ <span className={ style.tooltipTitle }>{ view }</span> }
      interactive="true"
      disableHoverListener={ !showInfoTooltip }
      arrow
      placement="bottom-start"
    >
      { getGridRowCell() }
    </Tooltip>
  )
}

GridRowCell.propTypes = {
  cell: PropTypes.shape({
    column: PropTypes.shape({
      columnDef: PropTypes.any,
      disableTooltip: PropTypes.bool,
      id: PropTypes.string,
      width: PropTypes.any
    }),
    getCellProps: PropTypes.func,
    getContext: PropTypes.func,
    render: PropTypes.func,
    row: PropTypes.any,
    value: PropTypes.any
  }),
  disableCellTooltip: PropTypes.bool,
  dndProvider: PropTypes.any
}

export default GridRowCell
