import { IconButton, Menu, MenuItem } from "@mui/material"
import PropTypes from "prop-types"
import { React, useId, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import style from "./FleetCardMenu.module.scss"

import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { FLEET_CARD_MENU, FLEET_CARD_MENU_DATA, FLEET_CARD_MENU_ORIGIN, ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import AuthGuard from "../AuthGuard/AuthGuard"
import Icon from "../Icon/Icon"

const FleetCardMenu = ({ fleetId, onClick, selected, setSelected }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation(["fleetCardMenu"])
  const buttonId = useId()
  const menuId = useId()
  const [menu, setMenu] = useState(null)
  const open = Boolean(menu)

  const handleClick = (event) => {
    event.preventDefault()
    setMenu(event.currentTarget)
    setSelected?.(true)
  }
  const handleClose = (event, name) => {
    event.preventDefault()
    onClick?.(name)
    setMenu(null)
    setSelected?.(false)
  }

  const prefetchFleetDetails = (type) => {
    if (type === "delete") {
      queryClient.prefetchQuery([fleetSVCKeys.FLEET_DETAILS_BY_ID, fleetId], () =>
        fleetGetSVC.getFleetDetailsByFleetId(fleetId)
      )
    }
  }

  return (
    <>
      <IconButton
        id={ buttonId }
        aria-controls={ open ? menuId : undefined }
        aria-haspopup="true"
        aria-expanded={ open ? "true" : undefined }
        onClick={ handleClick }
        data-testid="moreIcon"
        className={ classNames(style.fleetButton, selected && style.active) }
      >
        <Icon icon={ ICON_NAME.moreBlackIcon } />
      </IconButton>
      <Menu
        id={ menuId }
        anchorEl={ menu }
        open={ open }
        onClose={ (event) => handleClose(event) }
        MenuListProps={ {
          "aria-labelledby": buttonId
        } }
        transformOrigin={ FLEET_CARD_MENU }
        className="fleetMenuBorder"
        anchorOrigin={ FLEET_CARD_MENU_ORIGIN }
      >
        { FLEET_CARD_MENU_DATA.map((menuItem) => (
          <AuthGuard key={ menuItem.id } actionType={ menuItem.accessKey }>
            <MenuItem
              onClick={ (event) => handleClose(event, menuItem.name) }
              className="FleetmenuItem"
              onMouseOver={ () => prefetchFleetDetails(menuItem.name) }
            >
              <Icon icon={ menuItem.icon } iconStyles={ style.icon } />
              { t(menuItem.name) }
            </MenuItem>
          </AuthGuard>
        )) }
      </Menu>
    </>
  )
}

FleetCardMenu.propTypes = {
  fleetId: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  setSelected: PropTypes.func
}

export default FleetCardMenu
