export const deployModalInitialState = {
  isOpen: false
}

export const editScheduledModalInitialState = {
  isOpen: false
}

export const cancelDeployModalInitialState = {
  isOpen: false
}

export const assetSWPackageInitialStage = {
  cancelDeployModal: {
    isOpen: false
  },
  deployModal: {
    isOpen: false
  },
  editScheduledModal: {
    isOpen: false
  }
}

export const ACTION_TYPES = {
  CLOSE_CANCEL_DEPLOY_MODAL: "closeCancelDeployModal",
  CLOSE_DEPLOY_MODAL: "closeDeployModal",
  CLOSE_EDIT_SCHEDULED_MODAL: "closeEditScheduledModal",
  OPEN_CANCEL_DEPLOY_MODAL: "openCancelDeployModal",
  OPEN_DEPLOY_MODAL: "openDeployModal",
  OPEN_EDIT_SCHEDULED_MODAL: "openEditScheduledModal"
}

export const reducer = (newState, action) => {
  switch (action.type) {
  case ACTION_TYPES.CLOSE_DEPLOY_MODAL:
    return {
      ...newState,
      ...{
        deployModal: deployModalInitialState
      }
    }
  case ACTION_TYPES.OPEN_DEPLOY_MODAL:
    return {
      ...newState,
      ...{
        deployModal: {
          isOpen: true,
          packageDetails: action.payload
        }
      }
    }
  case ACTION_TYPES.OPEN_EDIT_SCHEDULED_MODAL:
    return {
      ...newState,
      ...{
        editScheduledModal: {
          isOpen: true,
          packageDetails: action.payload
        }
      }
    }
  case ACTION_TYPES.CLOSE_EDIT_SCHEDULED_MODAL:
    return {
      ...newState,
      ...{
        editScheduledModal: editScheduledModalInitialState
      }
    }
  case ACTION_TYPES.OPEN_CANCEL_DEPLOY_MODAL:
    return {
      ...newState,
      ...{
        cancelDeployModal: {
          isOpen: true,
          packageDetails: action.payload
        }
      }
    }
  case ACTION_TYPES.CLOSE_CANCEL_DEPLOY_MODAL:
    return {
      ...newState,
      ...{
        cancelDeployModal: cancelDeployModalInitialState
      }
    }
  }
}
