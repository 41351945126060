import PropTypes from "prop-types"
import React, { createContext, useContext, useMemo, useReducer } from "react"

export const QRScannerContext = createContext(null)

export const TYPES = {
  closePopup: "closePopup",
  displayQRScannerPopup: "displayQRScannerPopup"
}

export const QR_SCANNER_INITIAL_STATE = {
  isOpen: false,
  onClose: () => {
    return
  },
  type: ""
}

export const qrScannerReducer = (newState, action) => {
  switch (action.type) {
  case TYPES.closePopup:
    newState.onClose()
    return { ...newState, ...action.payload }
  case TYPES.displayQRScannerPopup:
    return { ...newState, ...{ isOpen: true }, ...action.payload }
  default:
    return newState
  }
}

export const QRScannerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(qrScannerReducer, QR_SCANNER_INITIAL_STATE)

  const displayQRScannerPopup = () => {
    dispatch({
      type: TYPES.displayQRScannerPopup
    })
  }

  const qrScannerValue = useMemo(
    () => ({
      dispatch,
      displayQRScannerPopup,
      state
    }),
    [state]
  )

  return <QRScannerContext.Provider value={ qrScannerValue }>{ children }</QRScannerContext.Provider>
}

QRScannerProvider.propTypes = {
  children: PropTypes.any
}

export const useQRScanner = () => {
  return useContext(QRScannerContext)
}
