import { IconButton, Menu, MenuItem } from "@mui/material"
import PropTypes from "prop-types"
import { React, useId, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Action.module.scss"

import Icon from "../../../../components/Icon/Icon"
import { ICON_NAME } from "../../../../utils/GlobalConstants"
import { classNames } from "../../../../utils/styles/helper"
import {
  SOFTWARE_PACKAGES_MENU,
  SOFTWARE_PACKAGES_MENU_DATA,
  SOFTWARE_PACKAGES_MENU_ORIGIN
} from "../SoftwarePackagesUtils"

const InProgressActionMenu = ({ id, onClick, selected, setSelected, muted }) => {
  const { t } = useTranslation(["asset"])
  const buttonId = useId()
  const menuId = useId()
  const [menu, setMenu] = useState(null)
  const open = Boolean(menu)

  const handleClick = (event) => {
    event.preventDefault()
    setMenu(event.currentTarget)
    setSelected?.(true)
  }
  const handleClose = (event, type) => {
    event.preventDefault()
    if (type) onClick?.(type)
    setMenu(null)
    setSelected?.(false)
  }

  return (
    <>
      <IconButton
        id={ buttonId }
        aria-controls={ open ? menuId : undefined }
        aria-haspopup="true"
        aria-expanded={ open ? "true" : undefined }
        onClick={ handleClick }
        aria-label={ id + "-moreIcon" }
        className={ classNames(styles.menuButton, selected && styles.active) }
        disabled={ muted }
      >
        <Icon icon={ muted ? ICON_NAME.moreBlackIconDisabled : ICON_NAME.moreBlackIcon } />
      </IconButton>
      <Menu
        id={ menuId }
        anchorEl={ menu }
        open={ open }
        onClose={ (event) => handleClose(event) }
        MenuListProps={ {
          "aria-labelledby": buttonId
        } }
        transformOrigin={ SOFTWARE_PACKAGES_MENU }
        className="fleetMenuBorder"
        anchorOrigin={ SOFTWARE_PACKAGES_MENU_ORIGIN }
      >
        { SOFTWARE_PACKAGES_MENU_DATA.map((menuItem) => {
          
          return (
            <MenuItem
              key={ menuItem.id }
              onClick={ (event) =>
                handleClose(event, menuItem.type)
              }
              className="FleetmenuItem"
            >
              <Icon
                icon={ menuItem.icon }
                iconStyles={ classNames(styles.icon, menuItem.id === 2 && "BlackWhiteIcon") }
              />
              <span className={ styles.itemText }>{ t(`softwarePackages.actionMenu.${menuItem.name}`) }</span>
            </MenuItem>
          )
          
        }) }
      </Menu>
    </>
  )
}

InProgressActionMenu.propTypes = {
  id: PropTypes.string,
  isPaused: PropTypes.bool,
  isStatusScheduled: PropTypes.bool,
  muted: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  setSelected: PropTypes.func
}

export default InProgressActionMenu
