import PropTypes from "prop-types"
import { React, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import FleetItems from "./internals/FleetItems"
import SubFleetItem from "./internals/SubFleetItem"
import styles from "./MoveFleet.module.scss"

import { useApp } from "../../contexts/app/AppContext"
import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { CANCEL, MOVE, MOVE_ASSETS_HEADING, ROUTE_CONFIG } from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"

const MoveFleet = ({ isPopupOpen, onCancel, setIsConfirmPopUp }) => {
  const { setAppError } = useApp()
  const [selectedFleet, setSelectedFleet] = useState({})
  const [expandedFleet, setExpandedFleet] = useState("")
  const [isConfirmEnable, setIsConfirmEnable] = useState(true)
  const queryParamIds = useParams()
  const siteId = queryParamIds[ROUTE_CONFIG.SITE.param]

  const {
    isFetching,
    isFetched: fetchedFleetData,
    data: siteFleets
  } = useQuery([fleetSVCKeys.GET_ALL_FLEETS_BY_SITE_ID, siteId], () => fleetGetSVC.getAllFleetsBySiteId(siteId), {
    onError: () => setAppError({ showError: true })
  })

  const fleetData = fetchedFleetData ? siteFleets?.data?.fleets : []
  const siteRes = siteFleets?.data?.site
  const siteName = siteRes?.siteAliasName ?? siteRes?.siteName

  const unAssignAsset = {
    fleetId: 0,
    fleetName: siteName,
    isSite: true,
    siteId
  }

  const ExpandMenu = (id) => {
    setExpandedFleet((prevState) => (prevState == id ? "" : id))
  }

  const handleFleetClick = (isSite, _fleetId, fleetName, _subFleetId, subFleetName) => {
    setIsConfirmEnable(false)
    setExpandedFleet(_subFleetId ? _fleetId : "")
    setSelectedFleet({
      fleetId: _fleetId,
      fleetName: fleetName,
      isSite: isSite,
      subFleet: _subFleetId,
      subFleetName: subFleetName
    })
  }

  const handleSubmit = () => {
    const moveToFleetName = selectedFleet?.subFleetName ? selectedFleet.subFleetName : selectedFleet.fleetName
    setIsConfirmPopUp({
      heading: `Confirm ${selectedFleet.fleetId !== 0 ? "move" : "remove"} assets`,
      isOpen: true,
      message: `Move selected assets to fleet ${moveToFleetName} ?`,
      selectedFleet: selectedFleet,
      type: !selectedFleet.isSite ? "addDevice" : "removeDevice"
    })
    handleCancel()
  }

  const handleCancel = () => {
    setIsConfirmEnable(true)
    setSelectedFleet({})
    setExpandedFleet("")
    onCancel(false)
  }

  return (
    <ConfirmationPopup
      heading={ MOVE_ASSETS_HEADING }
      isOpen={ isPopupOpen }
      onSubmit={ handleSubmit }
      confirm={ MOVE }
      onCancel={ handleCancel }
      cancel={ CANCEL }
      isConfirmEnable={ isConfirmEnable }
      popupStyles={ styles.popupContainer }
      isContentLoading={ isFetching }
      customLoadingStyle={ styles.contentLoading }
    >
      <div className={ styles.fleetSection }>
        <span className={ selectedFleet.fleetId === 0 ? styles.selectedFleetContentClosed : undefined }>
          <FleetItems item={ unAssignAsset } selectedFleet={ selectedFleet } handleFleetClick={ handleFleetClick } />
        </span>
        { fleetData?.map((item) => {
          let className = ""
          if (selectedFleet.fleetId === item.fleetId && expandedFleet) {
            className = styles.selectedFleetContentOpen
          } else if (selectedFleet.fleetId === item.fleetId && !expandedFleet) {
            className = styles.selectedFleetContentClosed
          }
          return (
            <span key={ item.fleetId } className={ className }>
              <FleetItems
                key={ item.fleetId }
                item={ item }
                subFleets={ item.subFleets }
                selectedFleet={ selectedFleet }
                handleFleetClick={ handleFleetClick }
                ExpandMenu={ ExpandMenu }
                expandedFleet={ expandedFleet }
              />
              { expandedFleet === item.fleetId && (
                <>
                  { item.subFleets.map((subFleet) => (
                    <SubFleetItem
                      key={ subFleet.fleetId }
                      item={ item }
                      subFleet={ subFleet }
                      handleFleetClick={ handleFleetClick }
                      selectedFleet={ selectedFleet }
                    />
                  )) }
                </>
              ) }
            </span>
          )
        }) }
      </div>
    </ConfirmationPopup>
  )
}

MoveFleet.propTypes = {
  isPopupOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  setIsConfirmPopUp: PropTypes.func
}

export default MoveFleet
