import { PropTypes } from "prop-types"
import React from "react"

import style from "./DataTable.module.scss"
import GridHeaderCell from "./GridHeaderCell"

import { classNames } from "../../utils/styles/helper"

const GridHeader = ({ tableProps }) => {
  return tableProps.getHeaderGroups().map((headerGroup) => (
    <div
      role="row"
      key={ headerGroup.id }
      className={ classNames(style.headerRowStyles, "headerContainer") }
    >
      { headerGroup.headers.map((header) => {
        const widthAppied = ![150, 0].includes(header.getSize())
          ? { width: header.getSize() }
          : ""
        return (
          <div
            role="columnheader"
            key={ header.id }
            colSpan={ header.colSpan }
            className={ classNames(
              style.columnHeaderStyles,
              header?.column?.columnDef?.id === "selection" &&
                "noPadding text-right",
              !header?.column?.getCanSort() &&
                header?.column?.columnDef?.id !== "selection" &&
                "pointerEventsNone",
              !header?.column?.columnDef?.header && "text-center",
              header.column.getIsResizing() ? style.isResizing : "",
              header.id
            ) }
            style={ {
              maxWidth: header?.column?.columnDef?.maxSize || "100%",
              minWidth: header?.column?.columnDef?.minSize || 48,
              ...widthAppied
            } }
          >
            { !header.isPlaceholder && (
              <GridHeaderCell header={ header } tableProps={ tableProps } />
            ) }
          </div>
        )
      }) }
    </div>
  ))
}

GridHeader.propTypes = {
  tableProps: PropTypes.any
}
export default GridHeader
