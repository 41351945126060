export const toasterReducer = (newState, action) => {
  switch (action.type) {
  case "close":
    newState.onClose()
    return { ...newState, ...action.payload }
  case "addToast":
    return { ...newState, ...{ open: true }, ...action.payload }
    /* istanbul ignore next */
  default:
    return newState
  }
}

export const TOASTER_INITIAL_STATE = {
  message: "",
  onClose: () => {
    return
  },
  onUndoClick: () => {
    return
  },
  open: false,
  showUndo: false,
  type: ""
}
  
