/* eslint-disable react-hooks/exhaustive-deps */
import { Button, IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import { ExportCSV } from "../../../../components/ExportCSV/ExportCSV"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { FaultsAuthElement } from "../../../../utils/Constants/Auth/faults"
import { EXPORT_CSV_TYPES } from "../../../../utils/Constants/ExportCSVConstants"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import {
  ACKNOWLEDGE,
  ASSET_FAULTS_DROPDOWN_OPTIONS,
  COMPARE_VALUE,
  GROUP_BY,
  ICON_NAME,
  UNACKNOWLEDGE
} from "../../../../utils/GlobalConstants"
import styles from "../../AssetFaults.module.scss"
import { useAssetFaultsContext } from "../../Context/AssetFaultsContext"

const HeadSection = ({ selectedAssets, setIsFilterOpen, handleFilterUpdate, assetSummary }) => {
  const {
    t,
    filter,
    setIsFilterSet,
    siteName,
    faults,
    getFaultsQueryResults,
    setFilter,
    setGroupBy,
    state,
    setDateRange,
    setSearchString,
    searchBarRef,
    setAcknowledgeUnAcknowledgePopUp,
    exportData
  } = useAssetFaultsContext()

  const [buttonValues, setButtonValues] = useState({
    isAcknowledge: true,
    isDisabled: false,
    value: t("buttons.acknowledge")
  })

  const handleButtonStateUpdate = () => {
    const isAcknowledge = selectedAssets.filter((item) => !item.isAcknowledge).length
    const isUnAcknowledge = selectedAssets.filter((item) => item.isAcknowledge).length
    if (isAcknowledge === selectedAssets.length) {
      setButtonValues({
        isAcknowledge: true,
        isDisabled: false,
        value: t("buttons.acknowledge")
      })
    } else if (isUnAcknowledge === selectedAssets.length) {
      setButtonValues({
        isAcknowledge: false,
        isDisabled: false,
        value: t("buttons.unAcknowledge")
      })
    } else
      setButtonValues({
        isAcknowledge: true,
        isDisabled: true,
        value: t("buttons.acknowledge")
      })
  }

  useEffect(() => {
    handleButtonStateUpdate()
  }, [selectedAssets])

  const handleClearAll = () => {
    setFilter([])
    setIsFilterSet(false)
    setIsFilterOpen(true)
  }

  const updateFilterItem = (value) => {
    setFilter(value)
    if (value.filter((option) => option.type === COMPARE_VALUE.modality).length === 0) {
      setFilter([])
      setIsFilterOpen(true)
      handleFilterUpdate?.(value)
    } else if (value.length === 0) {
      setIsFilterOpen(true)
    }
  }

  const onClickAckUack = () => {
    if (buttonValues.isAcknowledge && !buttonValues.isDisabled) {
      setAcknowledgeUnAcknowledgePopUp({
        faults: selectedAssets,
        isOpen: true,
        message: "",
        type: ACKNOWLEDGE
      })
    } else if (!buttonValues.isAcknowledge && !buttonValues.isDisabled) {
      setAcknowledgeUnAcknowledgePopUp({
        faults: selectedAssets,
        isOpen: true,
        message: t("acknowledgeAndUnAcknowledge.unAcknowledgeMsg"),
        type: UNACKNOWLEDGE
      })
    }
  }

  const selectionText = useMemo(() => {
    if (Object.keys(filter).length > 0) {
      if (getFaultsQueryResults?.variables?.searchString && selectedAssets?.length > 0) {
        let searchCount = assetSummary.reduce((acc, each) => {
          acc += each.faults?.length || 0
          return acc
        }, 0)
        return t("faultsSelectedCountText", {
          searchCount,
          selectedCount: selectedAssets?.length
        })
      } else if (selectedAssets?.length > 0) {
        if (selectedAssets.length > 1) {
          return t("multipleAssetSelectionText", {
            count: selectedAssets?.length
          })
        } else {
          return t("singleAssetSelectionText")
        }
      } else if (state.searchString?.length >= 3 && !getFaultsQueryResults?.isFetching) {
        let searchCount = assetSummary.reduce((acc, each) => {
          acc += each.faults?.length || 0
          return acc
        }, 0)
        return t("faultsSearchedCountText", { searchCount })
      }
    } else {
      return null
    }
  }, [assetSummary, filter, selectedAssets.length, state.searchString, getFaultsQueryResults?.isFetching])

  const handleExport = () => {
    if (selectedAssets?.length > 0) {
      return selectedAssets
    } else {
      return Object.values(exportData)?.flat() || []
    }
  }

  return (
    <div className={ styles.filtersSection }>
      <div className={ styles.pageContentLeft }>
        <div>
          <SingleSelectDropdown
            defaultOption={ 1 }
            options={ ASSET_FAULTS_DROPDOWN_OPTIONS.SITE_VIEW_GROUP_BY }
            optionBy={ GROUP_BY }
            value={ state.groupBy }
            onChange={ setGroupBy }
            disabled={ getFaultsQueryResults?.isFetching || Boolean(!(assetSummary && assetSummary?.length > 0)) }
          />
        </div>

        { Object.keys(filter).length > 0 && (
          <div className={ styles.filterBlock }>
            <SelectedFilters
              handleClearAll={ handleClearAll }
              filters={ filter }
              setSelectedFilterItem={ updateFilterItem }
              selectedFilterItem={ Object.values(filter) }
              isPageLoading = { getFaultsQueryResults?.isFetching }
            />
          </div>
        ) }
      </div>
      <div className={ styles.pageContentRight }>
        <span className={ styles.selectedAssets }>{ selectionText }</span>
        <AuthGuard
          actionType={
            buttonValues.isAcknowledge ? FaultsAuthElement.AckCentralFaults : FaultsAuthElement.UnAckCentralFaults
          }
        >
          { selectedAssets.length > 0 && !buttonValues.isDisabled && (
            <Button
              variant="text"
              color="secondary"
              className={ styles.ackBtn }
              startIcon={
                <Icon icon={ buttonValues.isAcknowledge ? 
                  ICON_NAME.acknowledgeButton : ICON_NAME.unAcknowledgeButton } />
              }
              disabled={ buttonValues.isDisabled }
              aria-label={ buttonValues.value }
              onClick={ onClickAckUack }
            >
              <span>{ buttonValues.value }</span>
            </Button>
          ) }
        </AuthGuard>
        <ExportCSV
          fileName={ `${siteName}-faults` }
          data={ handleExport() }
          type={ EXPORT_CSV_TYPES.CENTRAL_FAULTS }
          isDisabled={ faults?.length === 0 }
        >
          <Button
            className={ styles.exportButton }
            color="secondary"
            variant="text"
            data-testid="exportButton"
            startIcon={ <Icon icon={ faults?.length === 0 ? ICON_NAME.exportDisabled : ICON_NAME.export } /> }
            disabled={ faults?.length === 0 }
          >
            { t("buttons.export") }
          </Button>
        </ExportCSV>
        <IconButton className={ styles.filterButton } 
          aria-label="Filter Button" onClick={ () => setIsFilterOpen(true) }>
          <Icon icon={ Object.keys(filter).length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter } />
        </IconButton>
        <div className={ styles.datePicker }>
          <DateRangePicker
            startDate={ state.dateRange.startDate }
            endDate={ state.dateRange.endDate }
            onChange={ (value) => setDateRange(value) }
            reset={ state.resetDateRange }
          />
        </div>
        <div className={ styles.searchField }>
          <SearchBar
            ref={ searchBarRef }
            onHandleSubmit={ setSearchString }
            type={ SEARCH_INSTANCES.assetFaults }
            isDisabled={ !(assetSummary && assetSummary?.length > 0) && !state.searchString && !state.searchError }
          />
        </div>
      </div>
    </div>
  )
}

HeadSection.propTypes = {
  assetSummary: PropTypes.array,
  handleFilterUpdate: PropTypes.func,
  selectedAssets: PropTypes.array,
  setIsFilterOpen: PropTypes.func
}

export default HeadSection
