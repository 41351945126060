import PropTypes from "prop-types"
import React from "react"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"

function CloseButton({
  removeItem,
  label,
  closeButtonStyle,
  closeIconStyle,
  icon,
  isPageLoading
}) {
  return (
    <button
      aria-label={ label }
      className={ closeButtonStyle }
      onClick={ removeItem }
      id={ label }
      disabled={ isPageLoading }
    >
      <Icon icon={ icon } iconStyles={ closeIconStyle } />
    </button>
  )
}

CloseButton.defaultProps = {
  icon: ICON_NAME.close
}

CloseButton.propTypes = {
  closeButtonStyle: PropTypes.string,
  closeIconStyle: PropTypes.string,
  icon: PropTypes.string,
  isPageLoading: PropTypes.bool,
  label: PropTypes.string,
  removeItem: PropTypes.func
}

export default CloseButton
