import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AlertSeverity.module.scss"

import { SEVERITY_LEVELS } from "../../utils/GlobalConstants"
import { formatStatus } from "../../utils/helper"
import { classNames } from "../../utils/styles/helper"

const AlertSeverity = ({
  severity,
  displaySeverityText,
  styles: customStyles,
  isGridView
}) => {
  const { t } = useTranslation(["asset"])

  const transFormSeverity = formatStatus(severity)
  const severityIcon = SEVERITY_LEVELS[transFormSeverity]
    ? SEVERITY_LEVELS[transFormSeverity].ICON
    : ""

  if (severityIcon) {
    return (
      <Tooltip
        disableHoverListener={ isGridView }
        arrow
        placement="bottom"
        title={ SEVERITY_LEVELS[transFormSeverity].TOOLTIP }
      >
        <div className={ classNames(styles.rootIndicator, customStyles) }>
          <img
            id="alert-severity"
            className={ styles.icons }
            src={ severityIcon }
            alt={ t("alertSeverity") }
          />
          { displaySeverityText && (
            <label aria-label="severityText">
              { t(`severityLevelText.${severity}`) }
            </label>
          ) }
        </div>
      </Tooltip>
    )
  } else return null
}

AlertSeverity.propTypes = {
  displaySeverityText: PropTypes.bool,
  isGridView: PropTypes.bool,
  severity: PropTypes.oneOf([
    "",
    "low",
    "moderate",
    "critical",
    "Low",
    "Moderate",
    "Critical",
    "NOT_IN_PAYLOAD"
  ]),
  styles: PropTypes.any
}

AlertSeverity.defaultProps = {
  displaySeverityText: false,
  severity: ""
}

export default AlertSeverity
