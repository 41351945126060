import PropTypes from "prop-types"
import { React } from "react"

import style from "./ConfirmationPopup.module.scss"

import PopupButtonGroup from "../PopupButtonGroup/PopupButtonGroup"

const ConfirmationPopup = ({
  isConfirmEnable,
  isOpen,
  children,
  heading,
  childrenStyle,
  onSubmit,
  onCancel,
  popupStyles,
  confirm,
  resetButton,
  cancel,
  reset,
  isSubmit,
  isLoading,
  isContentLoading,
  customLoadingStyle
}) => {
  if (isOpen) {
    return (
      <span className={ style.popupHeader }>
        <span className={ style.overlay }></span>
        <PopupButtonGroup
          isConfirmEnable={ isConfirmEnable }
          heading={ heading }
          childrenStyle={ childrenStyle }
          onCancel = { onCancel }
          onSubmit = { onSubmit }
          popupStyles = { popupStyles }
          confirm = { confirm }
          resetButton = { resetButton }
          cancel = { cancel }
          reset = { reset }
          isSubmit={ isSubmit }
          isLoading={ isLoading }
          isContentLoading={ isContentLoading }
          customLoadingStyle={ customLoadingStyle }
        >
          { children }
        </PopupButtonGroup>
      </span>
    )
  } else return
}

ConfirmationPopup.defaultProps = {
  isConfirmEnable: false,
  isSubmit: true
}

ConfirmationPopup.propTypes = {
  cancel: PropTypes.string,
  children: PropTypes.node,
  childrenStyle: PropTypes.any,
  confirm: PropTypes.string,
  customLoadingStyle: PropTypes.any,
  heading: PropTypes.string,
  isConfirmEnable: PropTypes.bool,
  isContentLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onCancel: PropTypes.any,
  onSubmit: PropTypes.any,
  popupStyles: PropTypes.any,
  reset: PropTypes.string,
  resetButton: PropTypes.bool
}

export default ConfirmationPopup
