import colors from "../../styles/jsExports/colorExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const SearchBar = {
  styleOverrides: {
    root: {
      "& .MuiButtonBase-root": {
        padding: margins["marginInPixel-px9"]
      },
      "& .MuiInputBase-root": {
        "& .MuiInputAdornment-root": {
          justifyContent: "flex-end",
          width: margins["marginInPixel-px50"]
        },
        "& .MuiInputBase-input": {
          "&::placeholder": {
            fontStyle: "italic"
          },
          color: colors["searchPlaceholder"],
          height: margins["marginInPixel-px22"],
          lineHeight: margins["marginInPixel-px22"],
          width: margins["marginInPercentage-per100"]
        },
        "&.DropdownSelect": {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors["dropdown-border"],
            borderWidth: margins["marginInPixel-px1"]
          },
          borderRadius: margins["marginInPixel-px4"]
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["searchBar-focused"],
          borderWidth: margins["marginInPixel-px2"]
        },
        boxShadow: "none",
        display: "flex",
        flexDirection: "row",
        height: margins["marginInPixel-px34"],
        padding: 0
      },
      "& .noError": {
        "&.Mui-disabled": {
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            background: colors["searchBar-disabledBackground"],
            border: "transparent"
          },
          background: colors["searchBar-disabledBackground"],
          border: "transparent"
        }, 
        "&.Mui-disabled:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            background: colors["searchBar-disabledBackground"],
            border: "transparent"
          },
          background: colors["searchBar-disabledBackground"],
          border: "transparent"
        }, 
        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${colors["input-border"]}`,
          borderRadius: margins["marginInPixel-px4"]
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${colors["searchBar-focused"]}`
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          background: colors["searchBar-inputBackgroundHover"],
          border: `1px solid ${colors["input-border"]}`
        },
        background: colors["input-background"]
      }
    }
  }
}
