/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react"
import { useTranslation } from "react-i18next"

import Error from "../../components/Error/Error"
import Loading from "../../components/Loading/Loading"
import {
  ACTION_TYPES,
  CURRENTLY_SELECTED_TYPES,
  USER_MANAGEMENT_STATE,
  restructureAccessPayload,
  searchGroupOrUsers,
  transformUsers,
  userManagementReducer
} from "../../pages/UserManagement/UserManagementUtils"
import {
  resourceGETSvc,
  resourcePOSTSvc,
  resourceSVCKeys
} from "../../services/reactQueries/resourcesvc"
import {
  useLazyQuery,
  useMutationWithHandlers,
  useQuery
} from "../../utils/CustomHooks/reactQuery"
import { SEARCH_DEFAULT_MIN_CHARACTERS } from "../../utils/GlobalConstants"
import { sort } from "../../utils/helper"
import { useAuthContext } from "../Auth/auth"

const UserManagement = React.createContext({})

export const UserManagementProvider = ({ children }) => {
  const { t } = useTranslation(["userManagement"])
  const { getTokenData } = useAuthContext()
  const [state, dispatch] = useReducer(
    userManagementReducer,
    USER_MANAGEMENT_STATE
  )

  const userSession = useMemo(() => getTokenData(), [])
  const getUserManageAccessQueryKey = [
    resourceSVCKeys.getManageAccessUser,
    state?.selectedRowDetails?.id
  ]
  const getUserGroupManageAccessQueryKey = [
    resourceSVCKeys.getManageAccessGroup,
    state?.selectedRowDetails?.id
  ]
  const assignedSiteCount = state.selectedAccess?.sites?.filter(
    (each) => each.assigned
  )?.length

  const {
    isError: isUserGroupsApiError,
    isFetched: isUserGroupsFetched,
    data: userGroupsData
  } = useQuery(
    [resourceSVCKeys.getUserGroupsByOrgId],
    () => resourceGETSvc.getUserGroupsByOrgId(userSession?.organizationId),
    {
      onSuccess: (data) => {
        if (data?.data?.usersGroup?.length > 0) {
          data.data.usersGroup = sort(data?.data?.usersGroup, "name")
          handleClickUserGroup(data?.data?.usersGroup[0])
          return data
        }
      }
    }
  )

  const {
    isError: isUsersApiError,
    isFetched: isUsersFetched,
    data: usersData
  } = useQuery(
    [resourceSVCKeys.getUsersByOrgId],
    () => resourceGETSvc.getUsersByOrgId(userSession?.organizationId),
    {
      onSuccess: (data) => {
        if (
          isUserGroupsFetched &&
          userGroupsData?.data?.usersGroup?.length == 0 &&
          data?.data?.users?.length > 0
        ) {
          data.data.users = sort(data?.data?.users, "userName")
          handleClickUser(data?.data?.users[0])
        }
        if (data?.data?.users?.length > 0) {
          data.data.users = transformUsers(data.data.users)
        }
        return data
      }
    }
  )

  const [getUserGroupAccessDetails, getUserGroupAccessDetailsQueryResults] =
    useLazyQuery(
      [resourceSVCKeys.getGroupAccess, state?.selectedRowDetails?.id],
      () =>
        resourceGETSvc.getAccessAssociatedToUserGroup(
          state?.selectedRowDetails?.id
        )
    )

  const [getUserSiteAccessDetails, getUserSiteAccessDetailsQueryResults] =
    useLazyQuery(
      [resourceSVCKeys.getUserAccess, state?.selectedRowDetails?.id],
      () =>
        resourceGETSvc.getAccessAssociatedToUser(state?.selectedRowDetails?.id)
    )

  const [fetchGroupMember, getMemberResults] = useLazyQuery(
    [resourceSVCKeys.getGroupsMembers, state?.selectedRowDetails?.id],
    () =>
      resourceGETSvc.getGroupsMembers(
        userSession?.organizationId,
        state?.selectedRowDetails?.id
      )
  )

  useEffect(() => {
    return () => {
      getMemberResults.remove()
      getUserGroupAccessDetailsQueryResults.remove()
      getUserSiteAccessDetailsQueryResults.remove()
    }
  }, [])

  const [
    fetchManageAccessAssociatedToUserGroup,
    getManageAccessAssociatedToUserGroupQueryResults
  ] = useLazyQuery(
    getUserGroupManageAccessQueryKey,
    resourceGETSvc.getManageAccessAssociatedToUserGroup
  )

  const [
    fetchManageAccessAssociatedToUser,
    getManageAccessAssociatedToUserQueryResults
  ] = useLazyQuery(
    getUserManageAccessQueryKey,
    resourceGETSvc.getManageAccessAssociatedToUser
  )

  const {
    requestMutation: saveUserGroupAccess,
    ...saveUserGroupAccessMutationResults
  } = useMutationWithHandlers({
    onCompletedCallback: () => handleCloseManageAccessPopup(),
    onErrorCallback: () => handleCloseManageAccessPopup(),
    queryFn: resourcePOSTSvc.saveManageAccessAssociatedToUserGroup,
    refetchQueries: [
      {
        id: state?.selectedRowDetails?.id,
        key: resourceSVCKeys.getGroupAccess
      }
    ]
  })

  const { requestMutation: saveUserAccess, ...saveUserAccessMutationResults } =
    useMutationWithHandlers({
      onCompletedCallback: () => handleCloseManageAccessPopup(),
      onErrorCallback: () => handleCloseManageAccessPopup(),
      queryFn: resourcePOSTSvc.saveManageAccessAssociatedToUser,
      refetchQueries: [
        {
          id: state?.selectedRowDetails?.id,
          key: resourceSVCKeys.getUserAccess
        }
      ]
    })

  const autoSelectDefaultRow = () => {
    if ((isUserGroupsFetched, isUsersFetched)) {
      if (userGroupsData?.data?.usersGroup?.length > 0) {
        handleClickUserGroup(userGroupsData?.data?.usersGroup[0])
      } else if (usersData?.data?.users?.length > 0) {
        handleClickUser(usersData?.data?.users[0])
      }
    }
  }
  useEffect(() => {
    if (state?.selectedRowDetails?.id) {
      if (state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.GROUP) {
        fetchGroupMember()
        getUserGroupAccessDetails()
      } else {
        getUserSiteAccessDetails()
      }
    }
  }, [state.selectedRowDetails])

  const handleClickUser = useCallback((userRow) => {
    dispatch({ payload: userRow, type: ACTION_TYPES.SELECT_USER })
  }, [])

  const handleClickUserGroup = useCallback((userGroupRow) => {
    dispatch({ payload: userGroupRow, type: ACTION_TYPES.SELECT_USER_GROUP })
  }, [])

  const handleCloseManageAccessPopup = () => {
    dispatch({ type: ACTION_TYPES.CLOSE_MANAGE_ACCESS })
  }

  const handleOpenManageAccessPopup = () => {
    if (state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.GROUP) {
      fetchManageAccessAssociatedToUserGroup()
    } else {
      /* istanbul ignore next */
      fetchManageAccessAssociatedToUser()
    }
    dispatch({ type: ACTION_TYPES.OPEN_MANAGE_ACCESS })
  }

  const closeConfirmAccessPrompt = (payload = { isOpen: false }) => {
    dispatch({ payload, type: ACTION_TYPES.HANDLE_CONFIRM_ACCESS_PROMPT })
  }

  const openConfirmAccessPrompt = (payload = { isOpen: true }) => {
    dispatch({ payload, type: ACTION_TYPES.HANDLE_CONFIRM_ACCESS_PROMPT })
  }

  // MAIN
  const handleOnSave = (promptResponse) => {
    if (promptResponse) {
      handleSavePropmtFirst()
    } else {
      handleSaveMakeAPIRequest()
    }
  }

  // SAVE 1st step
  const handleSavePropmtFirst = () => {
    if (state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.GROUP) {
      saveUserGroupAccess({
        errorMessage: t("manageAccess.assignAccessErrorMessage"),
        payload: restructureAccessPayload(
          state.selectedAccess,
          state.selectedRowDetails
        ),
        successMessage: t("manageAccess.assignAccessSuccessMessage", {
          noOfSites:
            assignedSiteCount > 1 ? `${assignedSiteCount} sites` : "1 site",
          targetUserOrGroup: state.selectedRowDetails.name
        })
      })
    } else {
      saveUserAccess({
        errorMessage: t("manageAccess.assignAccessErrorMessage"),
        payload: restructureAccessPayload(
          state.selectedAccess,
          state.selectedRowDetails
        ),
        successMessage: t("manageAccess.assignAccessSuccessMessage", {
          noOfSites:
            assignedSiteCount > 1 ? `${assignedSiteCount} sites` : "1 site",
          targetUserOrGroup: state.selectedRowDetails.name
        })
      })
    }
    closeConfirmAccessPrompt()
  }

  // SAVE 2nd step
  const handleSaveMakeAPIRequest = () => {
    if (assignedSiteCount > 0) {
      openConfirmAccessPrompt({
        heading: t("manageAccess.confirmPrompt.heading"),
        isOpen: true,
        message: t("manageAccess.confirmPrompt.message", {
          siteList: state.selectedAccess.sites
            .filter((each) => each.assigned)
            .map((eachSite) => eachSite.name)
            .join(", ")
        })
      })
    } else {
      if (state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.GROUP) {
        saveUserGroupAccess({
          errorMessage: t("manageAccess.assignAccessErrorMessage"),
          payload: restructureAccessPayload(
            state.selectedAccess,
            state.selectedRowDetails
          ),
          successMessage: t("manageAccess.assignAccessSuccessMessage", {
            noOfSites:
              assignedSiteCount > 1
                ? `${assignedSiteCount} sites have been`
                : "1 site has been",
            targetUserOrGroup: state.selectedRowDetails.name
          })
        })
      } else {
        saveUserAccess({
          errorMessage: t("manageAccess.assignAccessErrorMessage"),
          payload: restructureAccessPayload(
            state.selectedAccess,
            state.selectedRowDetails
          ),
          successMessage: t("manageAccess.assignAccessSuccessMessage", {
            noOfSites:
              assignedSiteCount > 1
                ? `${assignedSiteCount} sites have been`
                : "1 site has been",
            targetUserOrGroup: state.selectedRowDetails.name
          })
        })
      }
    }
  }

  const handleChangeAccessSelection = (values) => {
    dispatch({
      payload: { sites: values },
      type: ACTION_TYPES.SELECTED_ACCESS_DETAILS
    })
  }
  // eslint-disable-next-line no-unused-vars
  const searchUserOrGroups = (searchValue, isCloseAction) => {
    if (isCloseAction) {
      dispatch({ payload: null, type: ACTION_TYPES.SEARCH_STRING })
      autoSelectDefaultRow()
    } else {
      dispatch({ payload: searchValue, type: ACTION_TYPES.SEARCH_STRING })
    }
  }

  const getSelectionDetailsText = useMemo(() => {
    let text = []
    if (assignedSiteCount > 0) {
      text.push(
        `${assignedSiteCount} ${
          assignedSiteCount === 1 ? "site is" : "sites are"
        }`
      )
    }
    return text.join(" & ")
  }, [assignedSiteCount])

  const isSelectionEmpty = () => {
    return !JSON.stringify(state.selectedAccess?.sites).match(
      "\"assigned\":true"
    )
  }

  const UserManagementValue = useMemo(() => ({
    ...state,
    closeConfirmAccessPrompt,
    dispatch,

    getAccessAssociatedQueryResults:
      state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.USER
        ? getManageAccessAssociatedToUserQueryResults
        : getManageAccessAssociatedToUserGroupQueryResults,

    getManageAccessQueryKey:
      state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.USER
        ? getUserManageAccessQueryKey
        : getUserGroupManageAccessQueryKey,

    getMemberResults,
    getSelectionDetailsText,
    getUserGroupAccessDetailsQueryResults,
    getUserSiteAccessDetailsQueryResults,
    handleChangeAccessSelection,
    handleClickUser,
    handleClickUserGroup,
    handleCloseManageAccessPopup,
    handleOnSave,
    handleOpenManageAccessPopup,
    isSelectionEmpty,
    saveAccessMutationResults:
      state.currentlySelectedType === CURRENTLY_SELECTED_TYPES.USER
        ? saveUserAccessMutationResults
        : saveUserGroupAccessMutationResults,
    searchUserOrGroups,
    t,
    userGroupsData: {
      groups:
        state.searchString?.length >= SEARCH_DEFAULT_MIN_CHARACTERS
          ? searchGroupOrUsers({
            data: userGroupsData?.data?.usersGroup,
            includeKeys: ["name"],
            searchString: state.searchString
          })
          : userGroupsData?.data?.usersGroup,
      isError: isUserGroupsApiError,
      isUserGroupsFetched
    },
    userManagementState: state,
    usersData: {
      isError: isUsersApiError,
      isUsersFetched,
      users:
        state.searchString?.length >= SEARCH_DEFAULT_MIN_CHARACTERS
          ? searchGroupOrUsers({
            data: usersData?.data?.users,
            includeKeys: ["name", "emails"],
            searchString: state.searchString
          })
          : usersData?.data?.users
    }
  }))

  if (!(isUserGroupsFetched && isUsersFetched)) {
    return <Loading />
  }

  if (isUsersApiError || isUserGroupsApiError) {
    return <Error error={ { message: t("getUsersApiFailed") } } />
  }

  return (
    <UserManagement.Provider value={ UserManagementValue }>
      { children }
    </UserManagement.Provider>
  )
}

UserManagementProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const UserManagementConsumer = UserManagement.Consumer

export const useUserManagementContext = () => {
  return useContext(UserManagement)
}
