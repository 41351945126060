import { Button } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import styles from "./Error.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const Error = ({ error }) => {
  const { t } = useTranslation(["login"])
  const navigate = useNavigate()

  return (
    <div className={ styles.main }>
      <div className={ styles.errorContainer }>
        <Icon icon={ ICON_NAME.errorBlack } iconStyles={ styles.errorIcon } />
        <div className={ styles.title }>{ t("errorPage.heading") }</div>
        <div className={ styles.messageContainer }>
          <div>{ error.message || t("errorPage.firstLevelHeading") }</div>
          { /* <div>{ t("errorPage.firstLevelHeading") }</div> */ }
          { /* <div>{ t("errorPage.secondLevelHeading") }</div> */ }
        </div>
        <div className={ styles.buttonContainer }>
          <Button variant="contained" color="primary" onClick={ () => navigate(0) }>
            <Icon icon={ ICON_NAME.refreshWhite } iconStyles={ styles.refreshIcon } />
            { t("errorPage.button.refresh") }
          </Button>
        </div>
      </div>
    </div>
  )
}

Error.propTypes = {
  error: PropTypes.any
}

export default Error
