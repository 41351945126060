import * as CryptoJS from "crypto-js"

const generateRandomString = (length) => {
  let random = ""
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const randomValues = Array.from(crypto.getRandomValues(new Uint8Array(length)))
  randomValues.forEach(v => (random += charset[v % charset.length]))
  return random
}

const generateCodeVerifier = () => {
  return generateRandomString(128)
}

const generateCodeChallenge = (codeVerifier) => {
  return base64URL(CryptoJS.SHA256(codeVerifier))
}

const base64URL = (str) => {
  return str.toString(CryptoJS.enc.Base64).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_")
}
  
export const getCodeChallengeAndVerifier = () => {
  const codeVerifier = generateCodeVerifier()
  return {
    codeChallenge: generateCodeChallenge(codeVerifier),
    codeVerifier
  }
}
