import { TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import React, { useState } from "react"

import CalenderIcon from "./CalenderIcon"
import styles from "./DateRangePicker.module.scss"

const DatePicker = ({ ...rest }) => {
  const [value, setValue] = useState(new Date())
  const [isDateOpen, setIsDateOpen] = useState(false)

  const onChangeEvent = (value) => {
    setValue(value)
    rest?.onChangeDate?.(value)
  }
  return (
    <LocalizationProvider dateAdapter={ AdapterDateFns }>
      <div className={ styles.datePicker }>
        <DesktopDatePicker
          shouldDisableYear={ () => true }
          PopperProps={ {
            "data-testid": "datePicker",
            placement: "bottom"
          } }
          onClose={ () => setIsDateOpen(false) }
          onOpen={ () => setIsDateOpen(true) }
          inputProps={ { "aria-label": "datePicker", placeholder: "MM/DD/YYYY", readOnly: true } }
          InputAdornmentProps={ { "className": isDateOpen ? "Active_Datepicker" : "" } }
          components={ {
            OpenPickerIcon: CalenderIcon
          } }
          value={ value }
          onChange={ (value) => onChangeEvent(value) }
          renderInput={ (params) => <TextField sx={ { width: rest.width } } { ...params } /> }
          { ...rest }
        />
      </div>
    </LocalizationProvider>
  )
}

export default DatePicker
