import PropTypes from "prop-types"
import React from "react"

import { BREADCRUMB_ROUTE } from "../../../components/Breadcrumb/BreadcrumbUtils"
import FleetExpandedCard from "../../../components/FleetExpandedCard/FleetExpandedCard"
import PageLink from "../../../components/PageLink/PageLink"
import { useFleetViewContext } from "../../../contexts/fleetView/FleetView"
import { ROUTE_CONFIG } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"
import style from "../FleetView.module.scss"
import cssStyles from "../SubFleetsSummary/SubFleetsSummary.module.scss"

const ConnectionType = ({ connections }) => {
  const currentURL = window.location.pathname
  const { fleetViewState: state } = useFleetViewContext()

  return (
    <div className={ classNames(style.fleets, cssStyles.groupByCards) }>
      { connections?.map((connection) => {
        return (
          <PageLink
            key={ connection?.[state.groupByMappingObject.titleKey] }
            className={ cssStyles.removeLinkStyles }
            to={ currentURL.concat(
              `/${ROUTE_CONFIG.FLEET_CONNECTION.routeName}/${
                connection?.[state.groupByMappingObject.titleKey]
              }`
            ) }
            type={ BREADCRUMB_ROUTE.FLEET_CONNECTION }
            pageName={ connection?.[state.groupByMappingObject.titleKey] }
          >
            <FleetExpandedCard
              titleKey={ state.groupByMappingObject.titleKey }
              fleetDetails={ connection }
              showCardAction={ false }
            />
          </PageLink>
        )
      }) }
    </div>
  )
}

ConnectionType.propTypes = {
  connections: PropTypes.array
}

export default ConnectionType
