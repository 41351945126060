/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useIsFetching } from "react-query"

import SubFleetColumnConfig from "./SubFleetColumnConfig"

import AssetCard from "../../../components/AssetCard/AssetCard"
import DataTable from "../../../components/DataTable/DataTable"
import ErrorPanel from "../../../components/DataTable/internals/ErrorPanel/ErrorPanel"
import NoRows from "../../../components/DataTable/internals/NoRows/NoRows"
import Error from "../../../components/Error/Error"
import FilterPopup from "../../../components/FilterPopup/FilterPopup"
import Loading from "../../../components/Loading/Loading"
import SearchErrorPage from "../../../components/SearchErrorPage/SearchErrorPage"
import { useSubFletViewContext } from "../../../contexts/subFleetView/subFleetView"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import { COMPARE_VALUE } from "../../../utils/GlobalConstants"
import HeadSection from "../internals/HeadSection"
import style from "../SubFleetView.module.scss"

// eslint-disable-next-line react/display-name
const AssetSummary = forwardRef((props, ref) => {
  const tableRef = useRef(null)
  const { t } = useTranslation(["subFleet"])
  const { t: common } = useTranslation(["common"])
  const [filterAPIError, setFilterAPIError] = useState(false)

  const {
    getApiQuery,
    assets,
    setSelectedAssets,
    state,
    assetsQueryKey,
    handleFilterInput,
    subFleetViewFilterData,
    handleFilterState
  } = useSubFletViewContext()

  const {
    selectedRows: selectedAssets,
    handleSelectRow,
    handleSelectAll,
    resetSelection
  } = useCheckBoxSelection({
    data: assets,
    onReset: () => setSelectedAssets([]),
    onSelectAllCallback: (data) => {
      setSelectedAssets(data)
      if (data.length === 0 && tableRef && tableRef.current) {
        tableRef.current.toggleAll(false)
      } else if (tableRef?.current) {
        tableRef.current.toggleAll(true)
      }
    },
    onSelectRowCallback: (selectedRow) => setSelectedAssets(selectedRow)
  })

  useImperativeHandle(ref, () => ({
    resetSelection() {
      tableRef?.current?.toggleAll(false)
      resetSelection()
    }
  }))

  const nowRows = useCallback(Boolean(assets?.length === 0))
  const isFetchingAssets = useIsFetching({ queryKey: assetsQueryKey })

  if (getApiQuery?.isError && state?.selectedFilters?.length > 0) {
    return <Error error={ { message: t("filterError") } } />
  }

  const updateFilterItem = (value) => {
    const modalities = value.filter((item) => item.type === COMPARE_VALUE.modality).map((item) => item.label)
    const filterItem = value.filter((item) =>
      item.type === COMPARE_VALUE.model ? modalities.includes(item.parent.trim()) : true
    )

    const isModalityAvailable = value.filter((option) => option.type === COMPARE_VALUE.modality)
    const setFilterValue = isModalityAvailable.length ? filterItem : []

    handleFilterInput(setFilterValue)
  }

  if (filterAPIError) return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  const getAssetsCard = () => {
    if (assetsQueryKey && isFetchingAssets) {
      return <Loading />
    } else if (getApiQuery?.isError) {
      return <ErrorPanel type={ TABLE_INSTANCES.SUB_FLEET_VIEW } queryKey={ assetsQueryKey } />
    } else if (state?.searchError || (state?.searchInput && assets?.length === 0)) {
      return <SearchErrorPage />
    } else if (nowRows) {
      return <NoRows type={ TABLE_INSTANCES.SUB_FLEET_VIEW } />
    } else {
      return (
        <div className={ style.assetCardContainer }>
          { assets.map((asset) => (
            <div key={ asset.id } className={ style.assetCard }>
              <AssetCard
                isSelectable={ true }
                assetDetails={ asset }
                isSelected={ selectedAssets.some(({ id }) => asset.id === id) }
                onChange={ handleSelectRow }
                status={ asset.status }
              />
            </div>
          )) }
        </div>
      )
    }
  }

  const getAssetsTable = () => {
    if (assetsQueryKey && isFetchingAssets) {
      return <Loading />
    } else if (state?.searchError || (state?.searchInput && assets?.length === 0)) {
      return <SearchErrorPage />
    } else {
      return (
        <DataTable
          selectableRows
          internalSelectAll
          displayRowColorBar
          rowHoverEffect
          highlightSelectedRows
          tableData={ assets }
          ref={ tableRef }
          columnsData={ SubFleetColumnConfig() }
          onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
          onSelectAllCallBack={ handleSelectAll }
          selectedRows={ selectedAssets }
          type={ TABLE_INSTANCES.SUB_FLEET_VIEW }
          isError={ getApiQuery?.isError }
          queryKey={ assetsQueryKey }
        />
      )
    }
  }

  return (
    <div className={ style.assetSummary }>
      <HeadSection handleFilterInput={ updateFilterItem } />
      <div className={ state?.isCardView ? style.assetContainerCardView : style.assetContainer }>
        { state?.isCardView ? getAssetsCard() : getAssetsTable() }
      </div>
      { state?.isFilterOpen && (
        <FilterPopup
          popupStyles={ style.popupStyles }
          data={ subFleetViewFilterData }
          onCancel={ handleFilterState }
          onSubmit={ updateFilterItem }
          filters={ state?.selectedFilters }
          customLoadingStyle={ style.contentLoading }
          showToastError={ false }
          setFilterAPIError={ setFilterAPIError }
        />
      ) }
    </div>
  )
})

export default AssetSummary
