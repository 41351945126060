import colors from "../../styles/jsExports/colorExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const Checkbox = {
  styleOverrides: {
    root: {
      "&.Mui-checked":{
        color:colors["textLabel"]
      },
      "&.MuiCheckbox-indeterminate":{
        color:colors["textLabel"]
      },
      height: margins["marginInPixel-px16"],
      padding: 0,
      width:margins["marginInPixel-px16"]
    }
  }
}
