import React from "react"

import Error from "../../../../components/Error/Error"
import Loading from "../../../../components/Loading/Loading"
import { useSoftwareUpdateContext } from "../../../../contexts/swUpdate/SoftwareUpdateContext"
import styles from "../CentralSoftwareUpdate.module.scss"
import EmptySoftwarePackagesListing from "../internal/EmptySoftwarePackagesListing"
import HeaderSection from "../internal/HeaderSection"
import SoftwareByModelAccordion from "../internal/SoftwareByModelAccordion"

const SoftwareUpdateSummary = () => {
  const { t, softwareList, filter, getQueryResults } = useSoftwareUpdateContext()

  const getSoftwarePackagesTemplate = () => {
    if (getQueryResults?.isFetching) {
      return <Loading />
    } else if (getQueryResults?.isError) {
      return <Error error={ { message: t("errorMessage") } } />
    } else if (softwareList?.length > 0) {
      return (
        <div className={ styles.accordionGroup }>
          { softwareList?.map((software) => (
            <SoftwareByModelAccordion key={ software.model } softwareList={ software } />
          )) }
        </div>
      ) 
    } else if (Object.keys(filter).length > 0) {
      return <EmptySoftwarePackagesListing />
    }
  }
  return (
    <>
      <HeaderSection />
      <div className={ styles.softwareSummary }>
        { getSoftwarePackagesTemplate() }
      </div>
    </>
  )
}

export default SoftwareUpdateSummary
