import PropTypes from "prop-types"
import React, { useState } from "react"

import { PAYLOAD_DATA } from "./EditableLabelUtils"

import { useToaster } from "../../contexts/ToastContext"
import { fleetMutationSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc"
import { CustomerAuthElement } from "../../utils/Constants/Auth/customer"
import { SiteAuthElement } from "../../utils/Constants/Auth/site"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import { ERROR_CODE, ERROR_MESSAGE, FIELD_CHAR_LENGTH, UPDATE } from "../../utils/GlobalConstants"
import { validateInput } from "../../utils/InputValidator/validator"
import InputField from "../InputField/InputField"
import TextContainer from "../TextContainer/TextContainer"

const UPDATE_MUTATIONS = {
  customerName: fleetMutationSVC.editCustomerName,
  siteName: fleetMutationSVC.editSiteName
}

const REFETCH_QUERIES = {
  siteName: { key: fleetSVCKeys.GET_SITES }
}

const ACCESS_TYPE = {
  customerName: CustomerAuthElement.UpdateCustomerName,
  siteName: SiteAuthElement.UpdateSiteName
}

const EditableLabel = ({
  dataId,
  type,
  value,
  labelAlert,
  handleUpdateComplete,
  isPopupEnabled,
  openLabelEditPopup
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [error, setError] = useState(null)
  const [label, setLabel] = useState(value)
  const [name, setName] = useState("")
  const { displaySuccessToast, displayMediumErrorToast } = useToaster()

  const onMutationComplete = () => {
    displaySuccessToast(`${name} is updated to ${label}.`)
    setError(null)
    setIsEditable(false)
    handleUpdateComplete?.(label)
  }

  const onMutationError = (err) => {
    if (err?.data?.error?.status === ERROR_CODE.CONFLICT) {
      setError(ERROR_MESSAGE[type].uniqueName)
    } else if (err?.data?.error?.status === ERROR_CODE.FORBIDDEN) {
      setError(ERROR_MESSAGE[type].accessError)
    } else {
      displayMediumErrorToast(ERROR_MESSAGE[type].updateError)
      setLabel(value)
      setIsEditable(false)
    }
  }

  const { requestMutation: updateField, loading } = useMutationWithHandlers({
    onCompletedCallback: onMutationComplete,
    onErrorCallback: onMutationError,
    queryFn: UPDATE_MUTATIONS[type],
    refetchQueries: [REFETCH_QUERIES[type] || ""]
  })

  const onSubmit = (valueParam) => {
    const isError = validateInput(valueParam, type)
    if (isError) {
      setError(isError)
    } else {
      setName(label)
      setLabel(valueParam)
      updateField({
        payload: {
          id: dataId,
          variables: PAYLOAD_DATA[type](valueParam)
        },
        showToast: false
      })
    }
  }

  const handleInputValidation = (valueParam) => {
    const isError = validateInput(valueParam, type)
    setError(isError)
  }

  const handleEditClick = (event) => {
    event.preventDefault()
    setIsEditable(true)
  }

  const handleCancel = (value) => {
    setLabel(value)
    setError(null)
    setIsEditable(false)
  }

  const handleOpenPopup = (event) => {
    openLabelEditPopup({
      isOpen: true,
      name: value,
      type: { action: UPDATE, id: dataId, type: type }
    })
    event.preventDefault()
  }

  return isEditable && !isPopupEnabled ? (
    <InputField
      label={ label }
      onSubmit={ onSubmit }
      onCancel={ handleCancel }
      error={ error }
      loading={ loading }
      maxLength={ FIELD_CHAR_LENGTH[type] }
      handleInputValidation={ handleInputValidation }
    />
  ) : (
    <TextContainer
      labelAlert={ labelAlert }
      accessType={ ACCESS_TYPE[type] }
      label={ label }
      handleEditLabelClick={ isPopupEnabled ? handleOpenPopup : handleEditClick }
    />
  )
}

EditableLabel.defaultProps = {
  isPopupEnabled: false
}

EditableLabel.propTypes = {
  dataId: PropTypes.string,
  handleUpdateComplete: PropTypes.func,
  isPopupEnabled: PropTypes.bool,
  labelAlert: PropTypes.element,
  openLabelEditPopup: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string
}

export default EditableLabel
