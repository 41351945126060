import IconButton from "@mui/material/IconButton"
import PropTypes from "prop-types"
import React from "react"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"
import style from "../InputField.module.scss"

const ActionButtons = ({ value, defVal, onSubmit, onCancel }) => {
  return (
    <>
      { value !== defVal && (
        <IconButton aria-label="Save Input" disableRipple onClick={ onSubmit }>
          <Icon icon={ ICON_NAME.saveInput } iconStyles={ style.saveIcon } />
        </IconButton>
      ) }
      <IconButton aria-label="Cancel Input" disableRipple onClick={ onCancel }>
        <Icon icon={ ICON_NAME.cancelInput } iconStyles={ style.cancelIcon } />
      </IconButton>
    </>
  )
}

ActionButtons.propTypes = {
  defVal: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.string
}

export default ActionButtons
