import axiosClient from "../axios/axios"

const PAGE_SIZE = 10000

export const telemetrySVC = {
  getAssetStatusDetails: (serialNumber, searchInput, sortingObject) => {
    const url = `/telemetrysvc/v1/assets/${serialNumber}/metrics`
    let query = `?size=${PAGE_SIZE}&page=0`
    if (sortingObject) {
      query += `&sortBy=${sortingObject.id}&sortDirection=${sortingObject.desc ? "DESC" : "ASC"}`
    }
    if (searchInput) {
      query += `&search=${searchInput}`
    }
    return axiosClient.get(url + query)
  }
}
