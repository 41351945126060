import i18n from "i18next"
import React from "react"

import { FaultsAuthElement } from "../../../../utils/Constants/Auth/faults"
import { TABLE_COLUMN_SPECIAL_ID_ACCESSORS } from "../../../../utils/Constants/DataTable"
import { DATE_FORMAT } from "../../../../utils/Constants/TimeZones"
import { formatDate } from "../../../../utils/helper"
import margins from "../../../../utils/styles/jsExports/marginExport.module.scss"
import Action from "../../../AssetView/AssetFaults/internals/Action"

const ColumnAssetFault = (handleRowClickActions, hasSomeAckFaults, hasSomeUnAckFaults) => [
  {
    accessorKey: "severity",
    disableTooltip: true,
    header: "",
    id: TABLE_COLUMN_SPECIAL_ID_ACCESSORS.ASSET_SEVERITY,
    maxSize: 40,
    size: 40
  },
  {
    accessorFn: (row) => formatDate(row.detectedOn, DATE_FORMAT.dateTime),
    accessorKey: "detectedOn",
    defaultCanSort: true,
    header: i18n.t("assetFault:table.headerLabels.detectedOn"),
    id: "detectedOn",
    sortDesc: true,
    width: margins["marginInPercentage-per13o6"]
  },
  {
    accessorKey: "assetName",
    header: i18n.t("assetFault:table.headerLabels.name"),
    width: margins["marginInPercentage-per13o6"]
  },
  {
    accessorKey: "assetSerialNumber",
    header: i18n.t("assetFault:table.headerLabels.serialNumber"),
    width: margins["marginInPercentage-per9o1"]
  },
  {
    accessorKey: "code",
    header: i18n.t("assetFault:table.headerLabels.code"),
    width: margins["marginInPercentage-per7"]
  },
  {
    accessorKey: "description",
    header: i18n.t("assetFault:table.headerLabels.description"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per9o1"]
  },
  {
    accessorKey: "suggestions",
    header: i18n.t("assetFault:table.headerLabels.suggestions"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per9o1"]
  },
  {
    accessorKey: "notes",
    header: i18n.t("assetFault:table.headerLabels.note"),
    sortingFn: "alphanumeric",
    width: margins["marginInPercentage-per12o9"]
  },
  {
    accessorKey: "lastUpdated",
    header: i18n.t("assetFault:table.headerLabels.lastUpdated"),
    width: margins["marginInPercentage-per12o9"]
  },
  {
    accessorKey: "action",
    cell: ({ row }) => (
      <Action
        row={ row }
        handleRowClickActions={ handleRowClickActions }
        ackAccessKey={ FaultsAuthElement.AckCentralFaults }
        unAckAccessKey={ FaultsAuthElement.UnAckCentralFaults }
        hasSomeAckFaults={ hasSomeAckFaults }
        hasSomeUnAckFaults={ hasSomeUnAckFaults }
      />
    ),
    disableTooltip: true,
    enableResizing: false,
    enableSorting: false,
    header: i18n.t("assetFault:table.headerLabels.actionDetails"),
    width: margins["marginInPercentage-per12o5"]
  }
]

export default ColumnAssetFault
