import PropTypes from "prop-types"
import React from "react"

import styles from "./PromptModal.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"

const PromptModal = ({
  isOpen,
  onSubmit,
  heading,
  onCancel,
  message,
  warning,
  confirm,
  cancel
}) => {
  return (
    <ConfirmationPopup
      heading={ heading }
      isOpen={ isOpen }
      onSubmit={ onSubmit }
      onCancel={ onCancel }
      confirm={ confirm }
      cancel={ cancel }
      popupStyles={ styles.root }
    >
      <span className={ styles.confirmContent }>{ message }</span>

      { warning && (
        <span className={ styles.warningSection }>
          <Icon icon={ ICON_NAME.alertRed } iconStyles={ styles.warningIcon } />
          <span className={ styles.confirmText }>{ warning }</span>
        </span>
      ) }
    </ConfirmationPopup>
  )
}

PromptModal.propTypes = {
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.any,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  warning: PropTypes.any
}

export default PromptModal
