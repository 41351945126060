import { Button, IconButton, Snackbar } from "@mui/material"
import PropTypes from "prop-types"
import { React, useMemo } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Toaster.module.scss"

import { useToaster } from "../../contexts/ToastContext"
import { ICON_NAME, ToasterConfig } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon.jsx"

const Toaster = () => {
  const { t } = useTranslation(["toaster"])
  const {
    state: { type, message, onUndoClick, showUndo, open },
    dispatch
  } = useToaster()

  const handleClose = () => {
    dispatch({ payload: { open: false }, type: "close" })
  }

  const icon = useMemo(() => {
    switch (type) {
    case ToasterConfig.TYPE.SUCCESS:
      return ICON_NAME.tick

    case ToasterConfig.TYPE.INFO:
      return ICON_NAME.infoBlack

    case ToasterConfig.TYPE.LOW:
      return ICON_NAME.errorIcon

    case ToasterConfig.TYPE.MEDIUM:
      return ICON_NAME.errorIcon

    case ToasterConfig.TYPE.CRITICAL:
      return ICON_NAME.alertWhite

    default:
      return ICON_NAME.infoBlack
    }
  }, [type])

  const action = (
    <>
      <span className={ styles.messageIconContainer }>
        <Icon icon={ icon } iconStyles={ styles.iconFill } />
        <span className={ styles.toasterMessage }> { message }</span>
      </span>
      <span className={ styles.buttonIcon }>
        { showUndo && (
          <Button variant="outlined" size="large" onClick={ onUndoClick }>
            { t("undo") }
          </Button>
        ) }
        <IconButton onClick={ handleClose } className={ styles.closeButton } aria-label="Close Toaster">
          <Icon
            icon={ type === ToasterConfig.TYPE.CRITICAL ? ICON_NAME.cancelWhite : ICON_NAME.cancelBlack }
            iconStyles={ styles.icon }
          />
        </IconButton>
      </span>
    </>
  )

  let toasterClassName = ""

  if (type === ToasterConfig.TYPE.SUCCESS) {
    toasterClassName = styles.successColor
  } else if (type === ToasterConfig.TYPE.INFO) {
    toasterClassName = styles.infoColor
  } else if (type === ToasterConfig.TYPE.LOW) {
    toasterClassName = styles.lowErrorColor
  } else if (type === ToasterConfig.TYPE.MEDIUM) {
    toasterClassName = styles.mediumErrorColor
  } else if (type === ToasterConfig.TYPE.CRITICAL) {
    toasterClassName = styles.criticalErrorColor
  }

  return (
    <Snackbar
      anchorOrigin={ ToasterConfig.position }
      open={ Boolean(message && open) }
      autoHideDuration={ ToasterConfig.duration }
      onClose={ handleClose }
      action={ action }
      className={ classNames(toasterClassName, "toaster") }
    />
  )
}

Toaster.propTypes = {
  handleClose: PropTypes.func,
  message: PropTypes.string,
  onUndoClick: PropTypes.func,
  open: PropTypes.bool,
  showUndo: PropTypes.bool,
  type: PropTypes.oneOf(["success", "error"])
}

Toaster.defaultProps = {
  showUndo: false,
  type: ToasterConfig.SUCCESS
}

export default Toaster
