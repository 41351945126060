import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const Tabs = {
  styleOverrides: {
    indicator: {
      background: colors["secondary"]
    },
    root: {
      "& .MuiTab-root": {
        "&.Mui-selected": {
          color: colors["label"],
          fontWeight: fonts["weight-bolder"]
        },
        color: colors["label"],
        fontSize: margins["margins-px16"],
        lineHeight: margins["marginInPixel-px22"],
        textTransform: "unset"
      },
      "& .MuiTabs-indicator": {
        height: margins["marginInPixel-px4"]
      },
      "& .MuiTabs-scroller": {
        borderBottom: `${margins["marginInPixel-px1"]} solid ${colors["borderGrey"]}`
      },
      "&.reportTabs": {
        "& .MuiTabs-indicator": {
          borderTopLeftRadius: margins["marginInPixel-px4"],
          borderTopRightRadius: margins["marginInPixel-px4"],
          height: margins["marginInPixel-px2"]
        },
        "& .MuiTabs-scroller": {
          borderBottom: `${margins["marginInPixel-px1"]} solid ${colors["borderGrey"]}`
        } 
      },
      background: colors["white"],
      minHeight: "unset"
    }
  }
}
