export const USER_PROFILE = {
  preference: {
    language: [
      { id: 1, label: "English" },
      { id: 2, label: "German" },
      { id: 3, label: "French" },
      { id: 4, label: "Italian" }
    ]
  }
}

/* eslint-disable sort-keys */
export const PROFILE_FIELDS = {
  "User name": "userName",
  Email: "email",
  Role: "role",
  Organization: "organization"
}

export const transformProfileDetails = (data) => {
  return {
    userName: data?.sub,
    email: data?.email,
    role: data?.roles,
    organization: data?.organizationName
  }
}
