import PropTypes from "prop-types"
import { React } from "react"
import { useTranslation } from "react-i18next"

import styles from "./NotificationBar.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const NotificationBar = ({ isOpen, notificationMsg, mutedDate }) => {
  const { t } = useTranslation("asset")
  if (isOpen) {
    return (
      <div className={ styles.notificationContainer }>
        <Icon icon={ ICON_NAME.errorBlack } iconStyles={ styles.warningInfo } />
        <span className={ styles.notificationText }>{ `${t("muteUnmuteMessage.deviceText", {
          mutedDate })}` }
        </span>
        <span className={ styles.noteText }>   
          { t("muteUnmuteMessage.noteText") } { notificationMsg }      
        </span>
      </div>
    )
  }
}

NotificationBar.propTypes = {
  isOpen: PropTypes.bool,
  mutedDate: PropTypes.string,
  notificationMsg: PropTypes.string
}

export default NotificationBar
