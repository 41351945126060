import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./SearchErrorPage.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const SearchErrorPage = () => {
  const { t } = useTranslation(["search"])
  return (
    <span className={ styles.page }>
      <Icon icon={ ICON_NAME.search } iconStyles={ styles.icon } />
      <span className={ styles.boldTitle }>{ t("search.boldTitle") }</span>
      <span className={ styles.subTitle }>{ t("search.title") }</span>
    </span>
  )
}

export default SearchErrorPage

SearchErrorPage.propTypes = {
  type: PropTypes.oneOf(["customerView", "siteView", 
    "centralConfig", "centralConfigDeployment", "assetFaults", "userManagementUser"])
}
