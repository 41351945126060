import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import styles from "./TextContainer.module.scss"

import useAccess from "../../utils/CustomHooks/useAccess"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import Icon from "../Icon/Icon"

const TextContainer = ({ label, accessType, handleEditLabelClick, labelAlert }) => {
  const textElementRef = useRef()
  const hasEditAccess = useAccess(accessType)
  
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const compareSize = () => {
    const compare = textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize)
    }, [])

  return (
    <>
      <div className={ classNames(hasEditAccess ? styles.editableLabel : styles.label, "textContainer") }>
        <Tooltip title={ label }
          arrow
          placeholder="bottom-end" 
          interactive="true" 
          disableHoverListener={ !showInfoTooltip }
        >
          <div ref={ textElementRef } id={ label } className={ styles.textEllipsis }>
            { label }
          </div>
        </Tooltip>
        { labelAlert }
      </div>
      { hasEditAccess && (
        <button
          className={ classNames(styles.editIcon, "editIcon", showInfoTooltip && "editWithTooltip") }
          onClick={ handleEditLabelClick }
        >
          <Icon icon={ ICON_NAME.edit } iconStyles={ styles.edit }/>
        </button>
      ) }
    </>
  )
}

TextContainer.propTypes = {
  accessType: PropTypes.string,
  handleEditLabelClick: PropTypes.func,
  label: PropTypes.string,
  labelAlert: PropTypes.element
}

export default TextContainer
