/* eslint-disable max-len */
/* eslint-disable sort-keys */
import colors from "../../styles/jsExports/colorExport.module.scss"
import fonts from "../../styles/jsExports/fontExport.module.scss"
import margins from "../../styles/jsExports/marginExport.module.scss"

export const TextField = {
  styleOverrides: {
    root: {
      "& .MuiInputAdornment-root": {
        "& .MuiIconButton-root": {
          marginRight: margins["marginInPixel-px12"],
          padding: 0
        },
        "& .MuiIconButton-edgeEnd": {
          height: margins["marginInPixel-px24"],
          width: margins["marginInPixel-px24"],
          marginRight: margins["marginInPixel-px4"],
          borderRadius: margins["marginInPixel-px4"],
          "&:hover": {
            backgroundColor: colors["datePicker-hoverBg"]
          }
        },
        marginLeft: margins["marginInPixel-px12"]
      },
      "& .MuiInputAdornment-root.Active_Datepicker ": {
        "& .MuiIconButton-edgeEnd": {
          backgroundColor: colors["datePicker-activeBackground"]
        },
        marginLeft: margins["marginInPixel-px12"]
      },
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: `${margins["marginInPixel-px6"]} 0  ${margins["marginInPixel-px6"]} ${margins["marginInPixel-px12"]}`
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["datePicker-outline"],
          borderWidth: margins["marginInPixel-px1"]
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          backgroundColor: colors["datePicker-hoverBg"],
          borderColor: colors["datePicker-outline"]
        },
        // eslint-disable-next-line sort-keys
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors["datePicker-selected"],
          borderRadius: margins["marginInPixel-px4"],
          borderWidth: margins["marginInPixel-px2"]
        },
        backgroundColor: colors["datePicker-textFieldBackground"],
        borderRadius: margins["marginInPixel-px4"],
        paddingRight: 0
      },
      "&.MuiTextField-root": {
        "& .MuiInputLabel-root": {
          color: colors["datePicker-label"],
          fontWeight: fonts["weight-bolder"]
        },
        width: margins["marginInPixel-px150"]
      },
      "&.MuiTextField-root.ErrorField": {
        "& .MuiInputBase-root": {
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: colors["datePicker-errorBorder"]
          },
          backgroundColor: colors["inputValidation-errorBackground"]
        }
      }
    }
  }
}

export const Calender = {
  styleOverrides: {
    root: {
      "& .css-epd502": {
        "& .MuiCalendarPicker-root": {
          "& .MuiPickersCalendarHeader-root": {
            "& .MuiPickersArrowSwitcher-root": {
              "& .MuiPickersArrowSwitcher-spacer": {
                width: margins["marginInPixel-px10"]
              },
              "& .MuiPickersArrowSwitcher-button": {
                height: margins["marginInPixel-px32"],
                width: margins["marginInPixel-px32"],
                "&:hover": {
                  backgroundColor: colors["datePicker-hoverBg"],
                  borderRadius: margins["marginInPixel-px4"]
                }
              }
            },
            "& .MuiPickersCalendarHeader-labelContainer": {
              color: colors["datePicker-textValue"],
              marginLeft: margins["marginInPixel-px8"],
              maxHeight: margins["marginInPixel-px32"],
              "& .MuiPickersCalendarHeader-switchViewButton": {
                height: margins["marginInPixel-px32"],
                width: margins["marginInPixel-px32"],
                "&:hover": {
                  backgroundColor: colors["datePicker-hoverBg"],
                  borderRadius: margins["marginInPixel-px4"]
                }
              }
            },
            margin: `${margins["marginInPixel-px16"]} ${margins["marginInPixel-px12"]} 0 ${margins["marginInPixel-px12"]}`,
            maxHeight: margins["marginInPixel-px32"],
            padding: 0
          },
          "& .MuiPickersFadeTransitionGroup-root": {
            "& .MuiDayPicker-header": {
              padding: `${margins["marginInPixel-px16"]} ${margins["marginInPixel-px16"]} 0 ${margins["marginInPixel-px16"]}`,
              "& .MuiDayPicker-weekDayLabel": {
                color: colors["datePicker-dateLabel"],               
                height: margins["marginInPixel-px32"],
                width: margins["marginInPixel-px32"]
              }
            },
            "& .MuiYearPicker-root": {
              "& .PrivatePickersYear-root": {
                "& .PrivatePickersYear-yearButton.Mui-selected": {
                  backgroundColor: colors["datePicker-selected"],
                  borderRadius: margins["marginInPixel-px4"],
                  color: colors["datePicker-text"],
                  fontWeight: fonts["weight-bold"]
                },
                "& .PrivatePickersYear-yearButton.Mui-disabled:hover": {
                  backgroundColor: "transparent"
                },
                "& .PrivatePickersYear-yearButton.Mui-disabled.Mui-selected:hover": {
                  backgroundColor: colors["datePicker-selected"]
                }
              }
            },
            "& .PrivatePickersSlideTransition-root": {
              "& .MuiDayPicker-monthContainer": {
                padding: `0 ${margins["marginInPixel-px16"]} ${margins["marginInPixel-px16"]} ${margins["marginInPixel-px16"]}`,
                "& .MuiDayPicker-weekContainer": {
                  "& .MuiPickersDay-dayWithMargin": {
                    color: colors["datePicker-textValue"],
                    fontSize: fonts["size-px14"],
                    height: margins["marginInPixel-px32"],
                    width: margins["marginInPixel-px32"],
                    backgroundColor: "inherit"
                  },
                  "& .MuiPickersDay-dayWithMargin:hover": {
                    backgroundColor: colors["datePicker-hoverBg"],
                    borderRadius: margins["marginInPixel-px4"]
                  },
                  "& .MuiPickersDay-dayWithMargin:active": {
                    backgroundColor: colors["datePicker-activeBg"],
                    borderRadius: margins["marginInPixel-px4"]
                  },
                  "& .Mui-disabled": {
                    color: colors["datePicker-disabledText"]
                  },
                  "& .Mui-selected, .Mui-selected:hover": {
                    backgroundColor: colors["datePicker-selected"],
                    borderRadius: margins["marginInPixel-px4"],
                    color: colors["datePicker-text"],
                    fontWeight: fonts["weight-bold"]
                  },
                  "& .MuiPickersDay-today": {
                    "& .MuiTouchRipple-root": {
                      background: colors["datePicker-selectedBorder"],
                      borderRadius: margins["marginInPixel-px2"],
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: margins["marginInPixel-px16"],
                      height: margins["marginInPixel-px2"],
                      position: "absolute",
                      top: margins["marginInPixel-px26"]
                    },
                    border: "none"
                  },
                  "& .Mui-selected.MuiPickersDay-today": {
                    "& .MuiTouchRipple-root": {
                      background: colors["datePicker-text"]
                    }
                  },
                  margin: 0
                }
              },
              minHeight:margins["marginInPixel-px209"]
            }
          },
          width: margins["marginInPixel-px256"]
        },
        maxHeight: "max-content",
        width: margins["marginInPixel-px256"]
      }
    }
  }
}

export const PickersPopperPaper = {
  styleOverrides: {
    root: {
      "& .MuiPickersPopper-paper": {
        background: colors["datePicker-textFieldBackground"],
        borderRadius: `0 0 ${margins["marginInPixel-px1"]} ${margins["marginInPixel-px1"]}`,
        boxShadow: `0 ${margins["marginInPixel-px1"]} ${margins["marginInPixel-px5"]} 0 ${colors["datePicker-overlayShadow"]}`,
        height: margins["marginInPixel-px304"],
        width: margins["marginInPixel-px256"]
      }
    }
  }
}
