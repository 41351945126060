import PropTypes from "prop-types"
import React from "react"

import styles from "./SWPackageCancelDeployModal.module.scss"

import { softwareSVC, softwareSVCKeys } from "../../services/reactQueries/softwaresvc"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import { CANCEL, OK } from "../../utils/GlobalConstants"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"

const SWPackageCancelDeployModal = ({ isOpen, serialNumber, popupStyles, onSubmit, onCancel, t, packageDetails }) => {
  const refetchQueries =[{ id: serialNumber, key: softwareSVCKeys.GET_SOFTWARE_PACKAGES_BY_SERIAL_NUMBER }]

  const { requestMutation: cancelSoftwareDeployment } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit?.({ isOpen: false }),
    onErrorCallback: () => onSubmit?.({ isOpen: false }),
    queryFn: softwareSVC.cancelSoftwareDeployment,
    refetchQueries
  })

  const handleSubmit = () => {
    cancelSoftwareDeployment({
      errorMessage: t("softwarePackages.cancelDeployModal.errorMessage"),
      payload: {
        packageId: packageDetails?.id,
        serialNumber
      },
      successMessage: t("softwarePackages.cancelDeployModal.successMessage")
    })
  }

  return (
    <ConfirmationPopup
      heading={ t("softwarePackages.cancelDeployModal.modalTitle") }
      isOpen={ isOpen }
      onSubmit={ handleSubmit }
      onCancel={ onCancel }
      confirm={ OK }
      cancel={ CANCEL }
      popupStyles={ popupStyles }
    >
      <div className={ styles.container }>
        <span>{ t("softwarePackages.cancelDeployModal.confirmText") }</span>
      </div>
    </ConfirmationPopup>
  )
}

SWPackageCancelDeployModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  packageDetails: PropTypes.object,
  popupStyles: PropTypes.string,
  serialNumber: PropTypes.string,
  t: PropTypes.func
}

export default SWPackageCancelDeployModal
