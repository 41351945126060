import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"

import ColumnAssetFault from "./internal/ColumnAssetFault"

import DataTable from "../../../components/DataTable/DataTable"
import LabelDataCountAccordion from "../../../components/LabelDataCountAccordion/LabelDataCountAccordion"
import NotesPopUp from "../../../components/NotesPopUp/NotesPopUp"
import { TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import useCheckBoxSelection from "../../../utils/CustomHooks/useCheckBoxSelection"
import {
  ACKNOWLEDGE,
  COMPARE_VALUE,
  GLOBAL_THROTTLE_TIME,
  ICON_NAME,
  UNACKNOWLEDGE
} from "../../../utils/GlobalConstants"
import { capitalizeFirstLetter, throttle } from "../../../utils/helper"
import styles from "../AssetFaults.module.scss"
import { useAssetFaultsContext } from "../Context/AssetFaultsContext"

// eslint-disable-next-line react/display-name
const AssetFaultAccordion = forwardRef(
  ({ id, selectedAssets, faultsDetails, handleSelectedAssets, handleResetTableSelection }, ref) => {
    const tableRef = useRef(null)
    const tableData = faultsDetails.faults
    const { t, setAcknowledgeUnAcknowledgePopUp, queryKey, setExportData } = useAssetFaultsContext()
    const hasSomeAckFaults = tableData?.some((item) => item.isAcknowledge)
    const hasSomeUnAckFaults = tableData?.some((item) => !item.isAcknowledge)
    const [notesPopUpState, setNotesPopUpState] = useState({
      faultId: "",
      isOpen: false
    })
    const [actionClick, setActionClick] = useState(false)

    const handleRowClickActions = (actionType, payload) => {
      switch (actionType) {
      case COMPARE_VALUE.acknowledge:
        setActionClick(true)
        setAcknowledgeUnAcknowledgePopUp({
          faults: [payload],
          isOpen: true,
          message: "",
          type: ACKNOWLEDGE
        })
        break
      case COMPARE_VALUE.unAcknowledge:
        setActionClick(true)
        setAcknowledgeUnAcknowledgePopUp({
          faults: [payload],
          isOpen: true,
          message: t("acknowledgeAndUnAcknowledge.unAcknowledgeMsg"),
          type: UNACKNOWLEDGE
        })
        break
      case TABLE_ACTION.NOTES:
        setActionClick(true)
        setNotesPopUpState({
          ...notesPopUpState,
          faultId: payload?.id,
          isOpen: true
        })
        break
        /* istanbul ignore next */
      default:
        break
      }
    }

    const { selectedRows, handleSelectRow, handleSelectAll, resetSelection } = useCheckBoxSelection({
      data: tableData,
      onReset: () => handleSelectedAssets(id, []),
      onSelectAllCallback: (data) => handleSelectedAssets(id, data),
      onSelectRowCallback: (rows) => handleSelectedAssets(id, rows)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

    useImperativeHandle(ref, () => ({
      resetSelection() {
        resetSelection()
        tableRef?.current?.toggleAll(false)
      }
    }))

    const handleCloseNotesPopUp = (isReset) => {
      if (isReset) handleResetTableSelection()
      setNotesPopUpState({ ...notesPopUpState, faultId: "", isOpen: false })
    }

    const handleSortChange = (sortObject, sortedRows) => {
      setExportData((prevState) => {
        return { ...prevState, [faultsDetails.title]: sortedRows.flatRows.map((each) => each.original) }
      })
    }

    return (
      <>
        <LabelDataCountAccordion
          id={ String(id) }
          label={ capitalizeFirstLetter(faultsDetails.title) }
          itemCount={ faultsDetails?.faults?.length }
          selectedAssets={ selectedAssets }
          icon={ ICON_NAME.fleet }
          className={ "HasHorizontalScroll" }
          actionClick={ actionClick }
          customClass={ styles.accordionCustomClass }
        >
          <div className={ styles.centralAssetFaultsTable }>
            <DataTable
              columnsData={ ColumnAssetFault(throttleActions, hasSomeAckFaults, hasSomeUnAckFaults) }
              tableData={ tableData }
              selectableRows
              rowHoverEffect
              highlightSelectedRows
              internalSelectAll
              disableCellTooltip
              selectedRows={ selectedRows }
              onSelectAllCallBack={ handleSelectAll }
              onSelectRowCallBack={ ({ checked, data }) => handleSelectRow(checked, data) }
              type={ TABLE_INSTANCES.SITE_ASSETS_FAULTS }
              ref={ tableRef }
              onSortingChange={ handleSortChange }
            />
          </div>
        </LabelDataCountAccordion>
        { notesPopUpState?.faultId && (
          <NotesPopUp
            className={ styles.notesPopUp }
            notesPopUpState={ notesPopUpState }
            setNotesPopUpState={ setNotesPopUpState }
            handleClose={ handleCloseNotesPopUp }
            queryKey={ queryKey }
          />
        ) }
      </>
    )
  }
)

AssetFaultAccordion.propTypes = {
  faultsDetails: PropTypes.shape({
    faults: PropTypes.array,
    title: PropTypes.string
  }),
  handleResetTableSelection: PropTypes.func,
  handleSelectedAssets: PropTypes.func,
  id: PropTypes.any,
  selectedAssets: PropTypes.array
}

export default AssetFaultAccordion
