import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

import { useBreadcrumbContext } from "../../contexts/breadcrumb/breadcrumb"
import { BREADCRUMB_ROUTE } from "../Breadcrumb/BreadcrumbUtils"

const PageLink = ({ type, pageName, onClick, children, ...props }) => {
  const { updateBreadcrumbData } = useBreadcrumbContext()

  const handleClick = (e) => {
    updateBreadcrumbData({
      key: type,
      name: pageName
    })
    onClick?.(e)
  }

  return (
    <Link
      onClick={ handleClick }
      { ...props }
    >
      { children }
    </Link>
  )
}

PageLink.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  pageName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BREADCRUMB_ROUTE))
}

export default PageLink
