import React from "react"

import AssetTabs from "./AssetTabs/AssetTabs"
import styles from "./AssetView.module.scss"

import AssetSummary from "../../components/AssetSummary/AssetSummary"
import EditAssetDetailsPopup from "../../components/EditAssetDetailsPopup/EditAssetDetailsPopup"
import { useAssetViewContext } from "../../contexts/assetView/assetView"
import { classNames } from "../../utils/styles/helper"

const AssetViewContainer = () => {
  const { state, assetDetails, assetQueryKey, openEditAssetPopup, closeEditAssetPopup } = useAssetViewContext()

  return (
    <div className={ assetDetails?.muted ? "assetMutedBar" : undefined }>
      <div className={ classNames(styles.summaryHeader) }>
        <AssetSummary
          assetDetails={ assetDetails }
          displayEmptyProperties={ true }
          handleEditAssetDetails={ openEditAssetPopup }
          muted={ assetDetails?.muted }
        />
        { state?.editAssetPopup?.isOpen && (
          <EditAssetDetailsPopup
            dataId={ state?.editAssetPopup?.type?.id }
            popupStyles={ styles.popupStyle }
            isEditPopupOpen={ state?.editAssetPopup }
            setIsEditPopupOpen={ closeEditAssetPopup }
            queryKey={ assetQueryKey }
          />
        ) }
      </div>
      <AssetTabs deviceName={ assetDetails.name } deviceNumber={ assetDetails.id } muted={ assetDetails.muted } />
    </div>
  )
}

export default AssetViewContainer
