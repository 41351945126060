import React from "react"

import CentralSoftwareUpdate from "./CentralSoftwareUpdate"

import { SoftwareUpdateProvider } from "../../../contexts/swUpdate/SoftwareUpdateContext"

const CentralSoftwareContainer = () => {
  return (
    <SoftwareUpdateProvider>
      <CentralSoftwareUpdate />
    </SoftwareUpdateProvider>
  )
}

export default CentralSoftwareContainer
