import { IconButton } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import AssetsAccordionItem from "./internals/AssetsAccordionItem"

import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Error from "../../../../components/Error/Error"
import Icon from "../../../../components/Icon/Icon"
import Loading from "../../../../components/Loading/Loading"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import SearchErrorPage from "../../../../components/SearchErrorPage/SearchErrorPage"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { ConfigAuthElement } from "../../../../utils/Constants/Auth/configuration"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import useAccess from "../../../../utils/CustomHooks/useAccess"
import { COMPARE_VALUE, DROPDOWN_OPTIONS, GROUP_BY, ICON_NAME } from "../../../../utils/GlobalConstants"
import { useConfigContext } from "../../Context/ConfigurationContext"
import styles from "../CentralConfiguration.module.scss"

const ConfigurationAssetsSummary = ({
  isLoading,
  isError,
  configData,
  setConfigData,
  setIsFilterOpen,
  throttleActions,
  selectedFilter,
  setSelectedFilter,
  handleSearch,
  searchInput,
  searchResult,
  searchError,
  searchBarRef,
  configDateRange,
  setConfigDateRange,
  handleResetSearch
}) => {
  const hasAnyActionAccess = useAccess([ConfigAuthElement.DeployCentralConfig, ConfigAuthElement.DemoteCentralConfig])
  const { filter, setIsFilterSet, setFilter } = useConfigContext()
  const { t } = useTranslation(["configDeployment"])

  useEffect(() => {
    setFilter(selectedFilter)
    selectedFilter?.length === 0 && setIsFilterOpen(true)
  }, [setFilter, selectedFilter, setIsFilterOpen])

  const handleFilterOpen = () => {
    setIsFilterOpen(true)
  }

  const handleClearAll = () => {
    setFilter([])
    setConfigData([])
    handleResetSearch()
    setIsFilterSet(false)
    setIsFilterOpen(true)
  }

  const searchResultText = () => {
    const searchCount = searchResult.map((item) => item?.assets?.length).reduce((acc, cur) => acc + cur)
    return `${t("searchAssetsCount", {
      searchResultsCount: searchCount
    })}`
  }

  const handelSetSelectedFilter = (filters) => {
    const modalityFilterItem = filters.filter((option) => option.type === COMPARE_VALUE.modality)
    const modelFilterItem = filters.filter((option) => option.type === COMPARE_VALUE.model)
    if (modalityFilterItem.length > 0 && modelFilterItem.length > 0) {
      setSelectedFilter(filters)
    } else {
      setSelectedFilter([])
      handleClearAll()
    }
  }

  return (
    <>
      <div className={ styles.filtersSection }>
        <div className={ styles.pageContentLeft }>
          <div>
            <SingleSelectDropdown
              defaultOption={ 1 }
              options={ DROPDOWN_OPTIONS.SITE_VIEW_GROUP_BY }
              optionBy={ GROUP_BY }
              disabled
              value={ 3 }
              width={ 192 }
            />
          </div>
          { Object.keys(filter).length > 0 && (
            <div className={ styles.filterBlock }>
              <SelectedFilters
                handleClearAll={ handleClearAll }
                filters={ filter }
                setSelectedFilterItem={ handelSetSelectedFilter }
                selectedFilterItem={ Object.values(filter) }
                filterDisabled={ false }
                isPageLoading={ isLoading }
              />
            </div>
          ) }
        </div>
        <div className={ styles.pageContentRight }>
          { (searchResult?.length > 0 || (searchError && searchInput?.length >= 3)) && (
            <span className={ styles.searchResultsText }>
              { searchInput && searchInput.length >= 3 && searchResult?.length === 0
                ? t("noSearchResult")
                : searchResultText() }
            </span>
          ) }
          <IconButton aria-label="Filter Button" onClick={ handleFilterOpen } className={ styles.filterButton }>
            <Icon
              iconStyles={ styles.filterIcon }
              icon={ Object.keys(filter).length > 0 ? ICON_NAME.filterBadge : ICON_NAME.filter }
            />
          </IconButton>
          <div className={ styles.datePicker }>
            <DateRangePicker
              startDate={ configDateRange.startDate }
              endDate={ configDateRange.endDate }
              onChange={ (value) => setConfigDateRange((prevState) => ({ ...prevState, ...value })) }
            />
          </div>
          <div className={ styles.searchField }>
            <SearchBar
              type={ SEARCH_INSTANCES.centralConfig }
              onHandleSubmit={ handleSearch }
              isDisabled={ !searchInput && searchInput === null && configData.length === 0 }
              ref={ searchBarRef }
            />
          </div>
        </div>
      </div>
      <div className={ styles.configContainer }>
        { isLoading && <Loading /> }
        { isError && <Error error={ { message: t("configApiErrorMsg") } } /> }

        { searchError && <SearchErrorPage /> }
        { !isLoading && !searchError && searchInput && searchResult?.length === 0 && <SearchErrorPage /> }
        { !isLoading && !isError && !searchError && !searchInput && configData?.length === 0 && (
          <div className={ styles.noConfig }>{ t("noData") }</div>
        ) }

        { (isLoading || searchError ? [] : configData)?.map((item) => (
          <AssetsAccordionItem
            key={ item.assetName }
            itemDetails={ item }
            throttleActions={ throttleActions }
            hasAnyActionAccess={ hasAnyActionAccess }
          />
        )) }
      </div>
    </>
  )
}

ConfigurationAssetsSummary.propTypes = {
  configData: PropTypes.array,
  configDateRange: PropTypes.object,
  handleResetSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  searchBarRef: PropTypes.any,
  searchError: PropTypes.bool,
  searchInput: PropTypes.string,
  searchResult: PropTypes.array,
  selectedFilter: PropTypes.array,
  setConfigData: PropTypes.func,
  setConfigDateRange: PropTypes.func,
  setIsFilterOpen: PropTypes.func,
  setSelectedFilter: PropTypes.func,
  throttleActions: PropTypes.any
}

export default ConfigurationAssetsSummary
