import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

import { CHART_COLOR } from "../../../utils/GlobalConstants"

const model = (type, label) => {
  return type + " | " + label 
}

const CustomBar = ({ x, y, width, height, payload, type }) => {
  const label = type === "modality" || type === "sucessModality" ? payload.label : model(type, payload.label)
  return (
    <Tooltip
      title={ `${label} : ${payload.value}` }
      arrow
      style={ { cursor:"pointer" } }
    >
      <rect x={ x } y={ y } width={ width } height={ height } fill={ CHART_COLOR[type] } style={ { minWidth:20 } } />
    </Tooltip>
  )
}

CustomBar.propTypes = {
  height: PropTypes.any,
  payload: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number
}

export default CustomBar
