import { FormControl, FormLabel, ListItemText, MenuItem, Select } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useId, useState } from "react"

import DropdownIcon from "./DropdownIcon"

const SingleSelectDropdown = ({
  displayLabelText,
  defaultOption,
  disabled,
  label,
  listWidth,
  onChange,
  options,
  optionBy,
  placeholder,
  value,
  required,
  width,
  ...props
}) => {
  const id = useId()
  const [selectedValue, setSelectedValue] = useState(defaultOption)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleChange = (event) => {
    if (onChange) {
      // onChange(options.find((eachOption) => eachOption.id === event.target.value))
      onChange(event.target.value)
    } else {
      setSelectedValue(event.target.value)
    }
  }

  const renderValue = (selected) => {
    const selectedOption = options.find((eachOption) => eachOption.id === selected)
    return optionBy ? `${optionBy} ${selectedOption?.label}` : `${selectedOption?.label}`
  }

  return (
    <FormControl sx={ { width: { width } } } disabled={ disabled } required={ required }>
      { displayLabelText && <FormLabel id={ id }>{ label }</FormLabel> }
      <Select
        aria-labelledby={ id }
        MenuProps={ {
          PaperProps:
          { style: { ...props.menuheight, maxWidth: "180px", width: listWidth  } },
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom"
          },
          // getContentAnchorEl: null,
          transformOrigin: {
            horizontal: "left",
            vertical: "top"
          }
        } }  
        sx={ {
          ...props.customStyles,
          ...{
            "& .MuiSelect-select .notranslate::after": placeholder
              ? {
                content: `"${placeholder}"`,
                fontStyle: "italic"
              }
              : {}
          }
        } }
        value={ selectedValue || "" }
        renderValue={ renderValue }
        onChange={ handleChange }
        IconComponent={ DropdownIcon }
        className="DropdownSelect"
        { ...props }
      >
        { options.map((item) => {
          return (
            <MenuItem
              id={ item.id }
              key={ item.id }
              value={ item.id }
              className= "DropdownBorder"
            >
              <ListItemText primary={ item.label } 
                sx={ {  whiteSpace: "normal",
                  wordWrap: "breakWord" } }
              />
            </MenuItem>
          )
        }) }
      </Select>
    </FormControl>
  )
}

SingleSelectDropdown.propTypes = {
  customStyles: PropTypes.object,
  defaultOption: PropTypes.any,
  disabled: PropTypes.bool,
  displayLabelText: PropTypes.bool,
  label: PropTypes.string,
  listWidth: PropTypes.string,
  menuheight: PropTypes.object,
  onChange: PropTypes.func,
  optionBy: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  width: PropTypes.number
}

SingleSelectDropdown.defaultProps = {
  defaultOption: "",
  displayLabelText: false,
  placeholder: "Select",
  width: 180
}

export default SingleSelectDropdown
