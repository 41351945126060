import { Checkbox, FormControl, FormLabel, ListItemText, MenuItem, Select } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useId, useState } from "react"

import styles from "./Dropdown.module.scss"
import DropdownIcon from "./DropdownIcon"

import { CHECKBOX_STATE_ICONS, DROPDOWN, ICON_NAME } from "../../utils/GlobalConstants"
import margins from "../../utils/styles/jsExports/marginExport.module.scss"
import Icon from "../Icon/Icon"

const MultipleSelectDropdown = ({
  displayLabelText,
  defaultOption,
  disabled,
  value,
  onChange,
  label,
  listWidth,
  options,
  placeholder,
  required,
  width,
  ...props
}) => {
  const id = useId()
  const [selectedValue, setSelectedValue] = useState(defaultOption || value || [])
  const isAllSelected = options.length > 0 && selectedValue.length === options.length

  useEffect(() => {
    if (value) setSelectedValue(value)
  }, [value])

  const handleChange = (event) => {
    const value = event.target.value
    if (value.includes(DROPDOWN.selectAll)) {
      setSelectedValue(selectedValue.length === options.length ? [] : options?.map(item => item.id))
      return 
    }
    setSelectedValue(value)
  }

  const handleClose = () => {
    onChange?.(selectedValue)
  }

  const renderValue = (selected) => {
    const length = selectedValue.length
    return `${length === 1 ? selected.map((obj) => options[obj - 1]?.label) : length + DROPDOWN.selected}`
  }

  const applySelectionBorder = (currentOption, prevOption, nextOption) => {
    let prevOptionSelected
    let nextOptionSelected
    const isCurrentOptionSelected = isOptionSelected(currentOption.id)
    if (prevOption) {
      prevOptionSelected = isOptionSelected(prevOption.id)
    }
    if (nextOption) {
      nextOptionSelected = isOptionSelected(nextOption.id)
    }
    if (isCurrentOptionSelected) {
      const defaultRadius = margins["marginInPixel-px4"]
      let styleObject = {
        borderBottomLeftRadius : defaultRadius,
        borderBottomRightRadius : defaultRadius,
        borderTopLeftRadius : defaultRadius,
        borderTopRightRadius : defaultRadius
      }
      if (prevOptionSelected && nextOptionSelected ) {
        styleObject.borderRadius = 0
       
      } else if (prevOptionSelected) {
        styleObject.borderTopRightRadius = 0
        styleObject.borderTopLeftRadius = 0
      } else if (nextOptionSelected) {
        styleObject.borderBottomRightRadius = 0
        styleObject.borderBottomLeftRadius = 0
      } else {
        styleObject.borderRadius = margins["marginInPixel-px4"]
      }
      return styleObject
    } else {
      return { borderRadius: 0 }
    }
  }

  const isOptionSelected = (id) => selectedValue.includes(id)

  return (
    <FormControl sx={ { width: { width } } } disabled={ disabled } required={ required }>
      { displayLabelText && <FormLabel id={ id }>{ label }</FormLabel> }
      <Select
        aria-labelledby={ id }
        MenuProps={ {
          PaperProps: { style: { ...props.menuheight, maxWidth: "180px", width: listWidth  } },
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom"
          },
          // getContentAnchorEl: null,
          transformOrigin: {
            horizontal: "left",
            vertical: "top"
          }
        } }
        sx={ {
          ...props.customStyles,
          ...{
            "& .MuiSelect-select .notranslate::after": placeholder
              ? {
                content: `"${placeholder}"`,
                fontStyle: "italic"
              }
              : {}
          }
        } }
        value={ selectedValue }
        multiple
        renderValue={ renderValue }
        onChange={ handleChange }
        onClose={ handleClose }
        IconComponent={ DropdownIcon }
        className="DropdownSelect"
        { ...props }
      >
        <MenuItem value={ DROPDOWN.selectAll } className="MultiSelectClearAll">
          <Checkbox 
            className="customCheckbox"
            checked={ isAllSelected }
            indeterminate={
              selectedValue.length > 0 && selectedValue.length < options.length
            }
            checkedIcon={
              <Icon
                iconStyles={ styles.checkboxStateIcons }
                icon={ !isAllSelected ? CHECKBOX_STATE_ICONS.disableChecked : CHECKBOX_STATE_ICONS.checked }
              />
            }
            icon={
              <Icon
                iconStyles={ styles.checkboxStateIcons }
                icon={ isAllSelected ? 
                  CHECKBOX_STATE_ICONS.disableUnchecked : CHECKBOX_STATE_ICONS.unChecked }
              />
            }
            indeterminateIcon={
              <Icon iconStyles={ styles.checkboxStateIcons } icon={ CHECKBOX_STATE_ICONS.indeterminate } />
            }
          />
          <ListItemText sx={ { ml: "7px" } }
            primary={ DROPDOWN.selectAllLabelText } />
        </MenuItem>
        { options?.map((item, index) => {
          const prevOption = options[index-1]
          const nextOption = options[index+1]
          const radiusStyles = applySelectionBorder(item, prevOption, nextOption)
          return (
            <MenuItem
              id={ item.id }
              key={ item.id }
              value={ item.id }
              className="MultiDropdownBorder"
              sx = { radiusStyles } 
            >
              { isOptionSelected(item.id) && (
                <Icon iconStyles={ styles.icon } icon={ ICON_NAME.tickmark } />
              ) }
              <ListItemText
                sx={
                  isOptionSelected(item.id)
                    ? { ml: "8px", whiteSpace: "normal", wordWrap: "breakWord" }
                    : { ml: "32px", whiteSpace: "normal", wordWrap: "breakWord" }
                }
                primary={ item.label }
              />
            </MenuItem>
          )
        }) }
      </Select>
    </FormControl>
  )
}

MultipleSelectDropdown.propTypes = {
  customStyles: PropTypes.object,
  defaultOption: PropTypes.array,
  disabled: PropTypes.bool,
  displayLabelText: PropTypes.bool,
  label: PropTypes.string,
  listWidth: PropTypes.string,
  menuheight: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.array,
  width: PropTypes.number
}

MultipleSelectDropdown.defaultProps = {
  displayLabelText: true,
  placeholder: "Select",
  width: 180
}

export default MultipleSelectDropdown
