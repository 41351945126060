import PropTypes from "prop-types"
import React from "react"

import { COMPARE_VALUE } from "../../../utils/GlobalConstants"
import styles from "../SelectedFilters.module.scss"

const FilterItem = ({ filter, isDisable }) => {
  const isType =
    filter.type === COMPARE_VALUE.modality || filter.type === COMPARE_VALUE.model || filter.type === COMPARE_VALUE.model

  const isModelModel = filter.type === COMPARE_VALUE.model
  return isType ? (
    <>
      <span className={ styles.filterLabel }>{ isModelModel ? "Model" : filter.type }</span>
      <span className={ styles.seperator }></span>
      <span className={ styles.menuDisplayLabel }>{ filter.label }</span>
    </>
  ) : (
    <span className={ !isDisable ? styles.menuDisplayLabel : styles.displayLabel }>{ filter.label }</span>
  )
}

FilterItem.propTypes = {
  filter: PropTypes.object,
  isDisable: PropTypes.bool
}

export default FilterItem
