import { IconButton } from "@mui/material"
import React from "react"

import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import SingleSelectDropdown from "../../../../components/Dropdown/SingleSelectDropdown"
import Icon from "../../../../components/Icon/Icon"
// import SearchBar from "../../../../components/SearchBar/SearchBar"
import SelectedFilters from "../../../../components/SelectedFilterItem/SelectedFilters"
import { useSoftwareUpdateContext } from "../../../../contexts/swUpdate/SoftwareUpdateContext"
// import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import { COMPARE_VALUE, DROPDOWN_OPTIONS, GROUP_BY, ICON_NAME } from "../../../../utils/GlobalConstants"
import styles from "../CentralSoftwareUpdate.module.scss"
import { DEFAULT_DATE_RANGE } from "../CentralSoftwareUpdatesUtils"

const HeaderSection = () => {
  const {
    filter,
    setIsFilterSet,
    setDateRange,
    setIsFilterOpen,
    setFilter,
    setSoftwareList,
    getQueryResults
  } = useSoftwareUpdateContext()

  const handleFilterOpen = () => {
    setIsFilterOpen(true)
  }

  const handleFilterUpdate = (filters) => {
    const modalityFilterItem = filters.filter(option => option.type === COMPARE_VALUE.modality)
    const modelFilterItem = filters.filter(option => option.type === COMPARE_VALUE.model)
    if (modalityFilterItem.length === 0 || modelFilterItem.length === 0) {
      handleClearAll()
    } else {
      setFilter(filters)
    }
  }

  const handleClearAll = () => {
    setFilter([])
    setSoftwareList([])
    setIsFilterSet(false)
    setIsFilterOpen(true)
  }
  
  return (
    <div className={ styles.topSection }>
      <div className={ styles.leftSection }>
        <SingleSelectDropdown
          defaultOption={ 1 }
          options={ DROPDOWN_OPTIONS.SITE_VIEW_GROUP_BY }
          optionBy={ GROUP_BY }
          disabled
          value={ 3 }
          width={ 192 }
        />
        { Object.keys(filter).length > 0 && (
          <div className={ styles.filterBlock }>
            <SelectedFilters
              handleClearAll={ handleClearAll }
              filters={ filter }
              setSelectedFilterItem={ handleFilterUpdate }
              selectedFilterItem={ Object.values(filter) }
              filterDisabled={ false }
              isPageLoading={ getQueryResults?.isFetching }
            />
          </div>
        ) }
      </div>
    
      <div className={ styles.rightSection }>
        <IconButton aria-label="Filter Button" className={ styles.filterButton }  onClick={ handleFilterOpen }>
          <Icon iconStyles={ styles.filterIcon }  
            icon={
              Object.keys(filter).length > 0
                ? ICON_NAME.filterBadge
                : ICON_NAME.filter
            }/>
        </IconButton>
        <div className={ styles.datePicker }>
          <DateRangePicker
            startDate={ DEFAULT_DATE_RANGE.startDate }
            endDate={ DEFAULT_DATE_RANGE.endDate }
            onChange={ (value) => setDateRange(value) }
            minDate={ DEFAULT_DATE_RANGE.minDate }
          />
        </div>
        { /* <div className={ styles.searchField }>
          <SearchBar type={ SEARCH_INSTANCES.centralSoftware } />
        </div> */ }
      </div>
    </div>
  )
}

export default HeaderSection
