import { CommonAuthElement } from "./Auth/common"
import { ConfigAuthElement } from "./Auth/configuration"

import { ICON_NAME } from "../GlobalConstants"

export const ASSET_CONFIG_INFO = [
  {
    icon: ICON_NAME.infoBlack,
    id: 1,
    label: "infoLabel1"
  },
  {
    icon: ICON_NAME.lockBlack,
    id: 2,
    label: "infoLabel2"
  }
]

export const TABLE_ACTION = {
  ACKNOWLEDGE: "acknowledge",
  CANCEL_DEPLOY: "cancel_deployment",
  DELETE: "delete",
  DEMOTE: "demote",
  DEPLOY: "deploy",
  EDIT_SCHEDULE_DEPLOY: "edit_schedule_deployment",
  INFO: "info",
  LOCK: "lock",
  NOTES: "notes",
  PAUSE_DEPLOY: "pause_deployment",
  PROMOTE: "promote",
  RESUME_DEPLOY: "resume_deployment",
  UNACKNOWLEDGE: "unacknowledge"
}

export const CONFIG_ACTIONS = {
  REFRESH: {
    icon: ICON_NAME.refresh,
    key: "refresh"
  },
  REQUEST_CONFIG_FILE: {
    icon: ICON_NAME.requestFile,
    key: "requestConfigFile"
  }
}

export const ASSET_CONFIG_BUTTONS = [
  {
    accessKey: CommonAuthElement.Any,
    disabledIcon: ICON_NAME.refreshDisabled,
    icon: CONFIG_ACTIONS.REFRESH.icon,
    id: 1,
    name: CONFIG_ACTIONS.REFRESH.key
  },
  {
    accessKey: ConfigAuthElement.RequestAssetConfig,
    disabledIcon: ICON_NAME.requestFileDisabled,
    icon: CONFIG_ACTIONS.REQUEST_CONFIG_FILE.icon,
    id: 2,
    name: CONFIG_ACTIONS.REQUEST_CONFIG_FILE.key
  }
]
