import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQueryClient } from "react-query"

import styles from "./SubFleetExpandedCard.module.scss"

import { fleetGetSVC, fleetSVCKeys } from "../../services/reactQueries/fleetsvc.js"
import { SubFleetAuthElement } from "../../utils/Constants/Auth/sub-fleet.js"
import { 
  EDITABLE_FIELD_TYPE,
  FLEET_CARD,
  UPDATE
} from "../../utils/GlobalConstants.js"
import { classNames } from "../../utils/styles/helper"
import AuthGuard from "../AuthGuard/AuthGuard.jsx"
import Distribution from "../Distribution/Distribution"
import FleetCardMenu from "../FleetCardMenu/FleetCardMenu.jsx"
import IconLabel from "../IconLabel/IconLabel"

const SubFleetExpandedCard = ({ idKey, titleKey, setIsEdit, fleetDetails, setIsConfirmPopUp }) => {
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState(false)

  const onMutationComplete = (devices) => {
    if (devices.length > 0) {
      setIsConfirmPopUp({
        deleteFleetDetails: fleetDetails,
        heading: "Warning: Delete sub-fleet",
        isOpen: true,
        message: "Before deleting a sub-fleet, all assets within the sub-fleet must be removed.",
        type: ""
      })
    } else {
      setIsConfirmPopUp({
        deleteFleetDetails: fleetDetails,
        heading: "Confirm delete sub-fleet",
        isOpen: true,
        message: `Delete sub-fleet ${fleetDetails?.[titleKey]} ?`,
        type: "delete"
      })
    }
  }

  const onClickAction = async (action) => {
    if (action === "delete") {
      const { data } = await queryClient.fetchQuery(
        [fleetSVCKeys.FLEET_DETAILS_BY_ID, fleetDetails?.[idKey]],
        () => fleetGetSVC.getFleetDetailsByFleetId(fleetDetails?.[idKey]),
        { staleTime: 5000 }
      )
      onMutationComplete(data?.assets)
    } else if (action === "edit") {
      setIsEdit({
        isOpen: true,
        name: fleetDetails?.[titleKey],
        type: { action: UPDATE, id: fleetDetails?.[idKey], type: EDITABLE_FIELD_TYPE.SUB_FLEET_CARD }
      })
    }
  }

  return (
    <div className={ classNames(styles.root, selected && styles.fleetSelected) }>
      <div className={ styles.card }>
        <span className={ styles.fleetData }>
          <IconLabel
            iconName={ FLEET_CARD.icon }
            label={ fleetDetails?.[titleKey] }
            labelStyles={ styles.nameLabel }
            iconStyles={ styles.nameIcon }
            displayTooltip={ true }
          />
        </span>
        <span className={ styles.distributionPanel }>
          { fleetDetails?.distributionData?.map((data) => (
            <Distribution
              key={ data.type }
              type={ data.type }
              displayLabelText={ true }
              distribution={ data.distribution }
              customStyles={ styles.distributionCards }
            />
          )) }
        </span>
      </div>
      <AuthGuard
        actionType={ [SubFleetAuthElement.UpdateSubFleet, SubFleetAuthElement.DeleteSubFleet] }
        fallbackElement={ <span className={ styles.viewIconFallback }></span> }
      >
        <span className={ classNames(styles.viewIcon, "FleetMenu") }>
          <FleetCardMenu
            fleetId={ fleetDetails?.[idKey] }
            onClick={ onClickAction }
            selected={ selected }
            setSelected={ setSelected }
            fleetType={ "SUB_FLEET" }
          />
        </span>
      </AuthGuard>
    </div>
  )
}

SubFleetExpandedCard.propTypes = {
  fleetDetails: PropTypes.shape({
    distributionData: PropTypes.arrayOf(
      PropTypes.shape({
        distribution: PropTypes.array,
        type: PropTypes.oneOf(["assets", "alerts", "updates", "configs"])
      })
    ),
    fleetId: PropTypes.string,
    fleetName: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    parentFleetId: PropTypes.string,
    statistics: PropTypes.object
  }),
  idKey: PropTypes.string,
  setIsConfirmPopUp: PropTypes.func,
  setIsEdit: PropTypes.func,
  titleKey: PropTypes.string
}

SubFleetExpandedCard.defaultProps = {
  titleKey: "title"
}

export default SubFleetExpandedCard
