import { globalSearch } from "../../utils/GlobalConstants"

export const userManagementReducer = (newState, action) => {
  switch (action.type) {
  case ACTION_TYPES.SELECT_USER:
    return {
      ...newState,
      ...{
        currentlySelectedType: CURRENTLY_SELECTED_TYPES.USER,
        manageAccess: {
          isOpen: false
        },
        selectedRowDetails: action.payload
      }
    }
  case ACTION_TYPES.SELECT_USER_GROUP:
    return {
      ...newState,
      ...{
        currentlySelectedType: CURRENTLY_SELECTED_TYPES.GROUP,
        manageAccess: {
          isOpen: false
        },
        selectedRowDetails: action.payload
      }
    }
  case ACTION_TYPES.CLOSE_MANAGE_ACCESS:
    return {
      ...newState,
      ...{
        manageAccess: {
          isOpen: false
        }
      }
    }
  case ACTION_TYPES.OPEN_MANAGE_ACCESS:
    return {
      ...newState,
      ...{
        manageAccess: {
          isOpen: true
        },
        selectedAccess: { fleets: [], sites: [], subFleets: [] }
      }
    }
  case ACTION_TYPES.HANDLE_CONFIRM_ACCESS_PROMPT:
    return {
      ...newState,
      ...{
        confirmAccessPrompt: action.payload
      }
    }
  case ACTION_TYPES.SELECTED_ACCESS_DETAILS:
    return {
      ...newState,
      ...{
        selectedAccess: action.payload
      }
    }
  case ACTION_TYPES.SEARCH_STRING:
    return {
      ...newState,
      ...{
        currentlySelectedType: newState.currentlySelectedType == CURRENTLY_SELECTED_TYPES.GROUP ? "G" : "U",
        searchString: action.payload,
        selectedRowDetails: {}
      }
    }
    /* istanbul ignore next */
  default:
    return newState
  }
}

export const ACTION_TYPES = {
  CLEAR_GROUP_USER_SELECTION: "clearSelecredRowDetails",
  CLOSE_MANAGE_ACCESS: "closeManageAccess",
  HANDLE_CONFIRM_ACCESS_PROMPT: "handleConfirmAccessPrompt",
  OPEN_MANAGE_ACCESS: "openManageAccess",
  SEARCH_STRING: "searchString",
  SELECTED_ACCESS_DETAILS: "selectedAccessDetails",
  SELECT_USER: "selectUser",
  SELECT_USER_GROUP: "selectUserGroup"
}

export const CURRENTLY_SELECTED_TYPES = {
  GROUP: "group",
  USER: "user"
}

export const USER_MANAGEMENT_STATE = {
  confirmAccessPrompt: {},
  currentlySelectedType: CURRENTLY_SELECTED_TYPES.GROUP,
  manageAccess: {},
  searchString: null,
  selectedAccess: {
    fleets: [],
    sites: [],
    subFleets: []
  },
  selectedRowDetails: {}
}

// Ignoring passing the test case as it was pending for api integration
/* istanbul ignore next */
export const restructureAccessPayload = (payload, { id }) => {
  return { ...payload, userGroupId: id, userId: id }
}

export const searchGroupOrUsers = (params) => {
  return globalSearch(params)
}

export const transformUsers = (users) => {
  return users.map((eachUser) => {
    eachUser.name = eachUser?.userName
    eachUser.emails = eachUser?.emails?.join(", ")
    return eachUser
  })
}
