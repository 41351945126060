import { PropTypes } from "prop-types"
import React from "react"
import { NavLink } from "react-router-dom"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"
import Icon from "../../Icon/Icon"
import styles from "../Breadcrumb.module.scss"

const BreadcrumbItems = ({
  name,
  route,
  disabled,
  showSeparator,
  isLastItem,
  itemWidth
}) => {
  return (
    <span className={ styles.Breadcrumbs } style={ { maxWidth: itemWidth - 25 } }>
      { showSeparator && (
        <span className={ styles.ArrowIcon }>
          <Icon icon={ ICON_NAME.arrowRight } />
        </span>
      ) }
      { !disabled && !isLastItem ? (
        <NavLink role={ "link" } to={ route } className={ styles.Link }>
          { name }
        </NavLink>
      ) : (
        <span
          data-testid={ "breadcrumbs-item" }
          className={ classNames(styles.Text, isLastItem && styles.Active, disabled && styles.disabled) }
        >
          { name }
        </span>
      ) }
    </span>
  )
}

BreadcrumbItems.propTypes = {
  disabled: PropTypes.bool,
  isLastItem: PropTypes.bool,
  itemWidth: PropTypes.number,
  name: PropTypes.string,
  route: PropTypes.string,
  showSeparator: PropTypes.bool
}

export default BreadcrumbItems
