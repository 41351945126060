import React from "react"

import ManageAccess from "./manageAccess/ManageAccess"
import UserAccessManagement from "./user/UserAccessManagement"
import UserGroupAccessManagement from "./userGroup/UserGroupAccessManagement"

import { useUserManagementContext } from "../../../../contexts/userManagement/UserManagement"
import { CURRENTLY_SELECTED_TYPES } from "../../UserManagementUtils"

const AccessManagementContainer = () => {
  const { userManagementState } = useUserManagementContext()

  if (userManagementState?.manageAccess?.isOpen) {
    return <ManageAccess />
  }

  return (
    <>
      { userManagementState?.currentlySelectedType === CURRENTLY_SELECTED_TYPES.GROUP && <UserGroupAccessManagement /> }
      { userManagementState?.currentlySelectedType === CURRENTLY_SELECTED_TYPES.USER && <UserAccessManagement /> }
    </>
  )
}

AccessManagementContainer.propTypes = {}

export default AccessManagementContainer
