import margins from "../../styles/jsExports/marginExport.module.scss"

export const RadioButton = {
  styleOverrides: {
    root: {
      marginLeft: margins["marginInPixel-px12"],
      padding: 0
    }
  }
}
