import React from "react"
import { Outlet } from "react-router-dom/dist"

import Error from "../components/Error/Error"
import { useApp } from "../contexts/app/AppContext"

const PageOutlet = () => {
  const { app: appState } = useApp()
  if (appState?.showAppError) return <Error error={ { message: appState?.showAppErrorMessage ?? "" } } />
  return <Outlet />
}

export default PageOutlet
