import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./DistributionStatisticsCard.module.scss"

import { DISTRIBUTION_CONFIG } from "../../utils/GlobalConstants.js"
import { classNames } from "../../utils/styles/helper"
import StatisticsData from "../StatisticsData/StatisticsData"
import StatusData from "../StatusData/StatusData"

const DistributionStatisticsCard = ({ countValue, distribution, type, showMutedCount, mutedAssetCount }) => {
  const { t } = useTranslation(["statistics"])

  return (
    <div className={ styles.statisticsCard } data-testid={ `${type}-statistics` }>
      <StatisticsData
        label={ t(`statisticsData.${DISTRIBUTION_CONFIG[type].label}`) }
        value={ countValue }
        showMutedCount={ showMutedCount }
        style={ classNames(styles.statisticsData, "StatisticsCardData") }
        mutedAssetCount={ mutedAssetCount }
      />
      <span className={ styles.divider } />
      <span className={ styles.rightStatisticsCard }>
        <StatusData
          distributionValues={ distribution }
          type={ DISTRIBUTION_CONFIG[type].label.toLowerCase() }
          totalCount={ countValue }
        />
      </span>
    </div>
  )
}

DistributionStatisticsCard.propTypes = {
  countValue: PropTypes.number,
  distribution: PropTypes.arrayOf(PropTypes.number),
  mutedAssetCount: PropTypes.number,
  showMutedCount: PropTypes.any,
  type: PropTypes.oneOf([
    "assets",
    "alerts",
    "updates",
    "faults",
    "configs",
    "swpackages",
    "swpackagesactivity",
    "swpackagesupdate",
    "assets_in_config",
    "config_activities"
  ]).isRequired
}

DistributionStatisticsCard.defaultProps = {
  distribution: [0, 0, 0]
}

export default DistributionStatisticsCard
