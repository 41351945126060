export const QUERY_KEYS = {
  FLEET_GROUP_BY_PAGE: "device-byFleetId-groupByPage",
  FLEET_VIEW_ASSETS: "getDevice-byFleetID",
  FLEET_VIEW_GROUP_BY: "fleet-byId",
  SITE_GROUP_BY_PAGE: "device-bySiteId-groupByPage",
  SITE_VIEW_ASSETS: "device-bySiteId",
  SITE_VIEW_GROUP_BY: "site-byID",
  SUB_FLEET_ID:"subFleet-byId",
  SUB_FLEET_VIEW_ASSETS: "device-byFleetId"
}
