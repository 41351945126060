import i18n from "i18next"

const UsersColumnConfig = () => [
  {
    accessorKey: "name",
    defaultCanSort: true,
    header: i18n.t("userManagement:table.users.headerLabels.name")
  },
  {
    accessorKey: "emails",
    header: i18n.t("userManagement:table.users.headerLabels.email")
  }
]

export default UsersColumnConfig
