import PropTypes from "prop-types"
import React, { useId } from "react"
import { useTranslation } from "react-i18next"

import styles from "./StatisticsData.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"
import Icon from "../Icon/Icon"

const StatisticsData = ({ label, value, style, showMutedCount, mutedAssetCount }) => {
  const id = useId()
  const { t } = useTranslation(["asset"])
  return (
    <div className={ style }>
      <span id={ id } className={ styles.label }>
        { label }
      </span>
      <span aria-labelledby={ id } className={ styles.count }>
        { value || 0 }
      </span>
      { showMutedCount && mutedAssetCount > 0 && (
        <span className={ styles.muteSection }>
          <Icon icon={ ICON_NAME.disabledIcon } iconStyles={ styles.mutedIcon } />
          <span className={ styles.mutedText }>
            { " " }
            { mutedAssetCount || 0 } { t("muted") }
          </span>
        </span>
      ) }
    </div>
  )
}

StatisticsData.propTypes = {
  label: PropTypes.string,
  mutedAssetCount: PropTypes.number,
  showMutedCount: PropTypes.any,
  style: PropTypes.any,
  value: PropTypes.any
}

export default StatisticsData
