import { Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"

import style from "./Bar.module.scss"

const Bar = ({ color, barStyles, toolTipMessages }) => {
  return (
    <Tooltip
      arrow
      title={ toolTipMessages }
    >
      <div
        role={ "progressbar" }
        aria-label="Bar"
        style={ { background: `${color}`, ...barStyles } }
        className={ `${style.bar} ` }
      />
    </Tooltip>
  )
}

Bar.propTypes = {
  barStyles: PropTypes.object,
  color: PropTypes.string.isRequired,
  toolTipMessages: PropTypes.string
}

export default Bar
