import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import { CommonAuthElement } from "../../../utils/Constants/Auth/common"
import { DRAG_ICON_BASE_PATH } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"
import AuthGuard from "../../AuthGuard/AuthGuard"

const DragHandle = ({ dragHandleProps, draggableRows }) => {
  const { t } = useTranslation(["asset"])
  return (
    <AuthGuard actionType={ CommonAuthElement.Any }>
      { " " }
      <div
        className={ classNames("dragIcon", !(draggableRows && dragHandleProps) && "dragDisabled") }
        { ...dragHandleProps }
      >
        <img alt={ t(DRAG_ICON_BASE_PATH.altText) } src={ DRAG_ICON_BASE_PATH.icon } />
      </div>
    </AuthGuard>
  )
}

DragHandle.propTypes = {
  dragHandleProps: PropTypes.any,
  draggableRows: PropTypes.bool
}

export default DragHandle
