import { IconButton, Radio } from "@mui/material"
import PropTypes from "prop-types"
import { React } from "react"

import margins from "../../..//utils/styles/jsExports/marginExport.module.scss"
import { DOWN_ARROW, ICON_NAME, UP_ARROW } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"
import styles from "../MoveFleet.module.scss"

const FleetItems = ({ disabled, item, subFleets, selectedFleet, handleFleetClick, ExpandMenu, expandedFleet }) => {
  const fleetSelectedState = !selectedFleet.subFleet && selectedFleet.fleetId == item.fleetId

  return (
    <span
      className={ expandedFleet == item.fleetId ? styles.fleetContentOpen : styles.fleetContentClose }
      data-testid={ `fleet-${item.fleetId}` }
    >
      <Radio
        disabled={ disabled }
        disableRipple
        icon={ <Icon icon={ ICON_NAME.unSelectedRadio } /> }
        checkedIcon={ <Icon icon={ ICON_NAME.selectedRadio } /> }
        name="fleets"
        checked={ fleetSelectedState }
        onChange={ () => {
          // This is intentional
        } }
        onClick={ () => handleFleetClick(item.isSite, item.fleetId, item.fleetName) }
        className={ styles.fleetRadio }
      />

      { subFleets && subFleets.length > 0 && (
        <IconButton
          className={ styles.fleetsIconButton }
          aria-label={ `Expand button ${item.fleetId}` }
          onClick={ () => ExpandMenu(item.fleetId) }
        >
          <Icon iconStyles={ styles.fleetsIcon } icon={ expandedFleet == item.fleetId ? DOWN_ARROW : UP_ARROW } />
        </IconButton>
      ) }

      <span
        className={ styles.fleetItem }
        style={ {
          marginLeft: !subFleets || (subFleets && subFleets.length === 0) ? margins["marginInPercentage-per12o5"] : 0
        } }
      >
        { item.fleetName }
      </span>
    </span>
  )
}
FleetItems.propTypes = {
  ExpandMenu: PropTypes.func,
  disabled: PropTypes.bool,
  expandedFleet: PropTypes.any,
  handleFleetClick: PropTypes.func,
  item: PropTypes.object,
  selectedFleet: PropTypes.object,
  subFleets: PropTypes.array
}
export default FleetItems
