import PropTypes from "prop-types"
import React, { forwardRef } from "react"

import style  from "./Icon.module.scss"

import { classNames } from "../../utils/styles/helper"

// eslint-disable-next-line react/display-name
const Icon = forwardRef(({ icon, iconStyles }, ref) => {
  return (
    <img
      src={ `/assets/icons/${icon}.svg` }
      alt={ icon }
      className = { classNames(style.icon, iconStyles) }
      ref={ ref }
    />
  )
})

Icon.propTypes = {
  icon: PropTypes.string,
  iconStyles: PropTypes.any
}

export default Icon
