/* eslint-disable react-hooks/exhaustive-deps */
import { addMinutes, format } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import styles from "./SWPackageDeployModal.module.scss"

import { SOFTWARE_TYPE } from "../../pages/AssetView/SoftwarePackages/SoftwarePackagesUtils"
import {
  getDeploySoftwarePayload,
  getReScheduledSoftwarePackagePayload,
  getScheduledSoftwarePackagePayload
} from "../../pages/Reports/Tabs/SWPackage/SWPackageUtils"
import { softwareSVC, softwareSVCKeys } from "../../services/reactQueries/softwaresvc"
import { validateDeployTime } from "../../utils/Common/common"
import { DEFAULT_DATE_RANGE } from "../../utils/Constants/AssetLogs"
import { TABLE_ACTION } from "../../utils/Constants/AssetsConfig"
import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { useMutationWithHandlers } from "../../utils/CustomHooks/reactQuery"
import { CANCEL, OK, TEXT_FIELD_MAX_CHAR, specialCharactersNotAllowedForSearch } from "../../utils/GlobalConstants"
import { convertToLocalTimeZone, formatStatus } from "../../utils/helper"
import { removeEmoji } from "../../utils/InputValidator/validator"
import { StorageKeys, getSession } from "../../utils/SessionHelper/session"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import DatePicker from "../DateRangePicker/DatePicker"
import TimePickerDropdown from "../Dropdown/TimePickerDropdown"
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch"

const SWPackageDeployModal = ({
  isOpen,
  type,
  pageType,
  popupStyles,
  onSubmit,
  onCancel,
  t,
  packageDetails,
  assetSerialNumber
}) => {
  const defaultUpdatedDateRef = useRef()
  const initialDateRef = useRef()
  const packageCurrentStatus = formatStatus(packageDetails?.status)
  const isCurrentStatusScheduled = packageCurrentStatus === "SCHEDULED"
  const isEditMode = type === TABLE_ACTION.EDIT_SCHEDULE_DEPLOY
  const [deployDetails, setDeployDetails] = useState({ scheduled: isEditMode })
  const [updatedDate, setUpdatedDate] = useState(null)
  const [updatedTime, setUpdatedTime] = useState(null)
  const [timeError, setTimeError] = useState(false)
  const [inValidTime, setInvalidTime] = useState(false)
  const siteUcmId = getSession(StorageKeys.SITE_UCM_ID)
  const refetchQueries = [
    { id: assetSerialNumber, key: softwareSVCKeys.GET_SOFTWARE_PACKAGES_BY_SERIAL_NUMBER },
    { id: packageDetails?.id, key: softwareSVCKeys.GET_SOFTWARE_DEPLOYMENT_ASSETS }
  ]

  useEffect(() => {
    const defaultDateTime = isCurrentStatusScheduled && packageDetails?.scheduledDate
      ? convertToLocalTimeZone(packageDetails?.scheduledDate)
      : new Date(addMinutes(new Date(), 30))
    defaultUpdatedDateRef.current = defaultDateTime
    initialDateRef.current = defaultDateTime
    setUpdatedDate(defaultDateTime)
    setUpdatedTime(defaultDateTime)
  }, [])

  const handleChangeDate = (dateValue) => {
    setUpdatedDate(dateValue)
    validateTime(
      dateValue,
      updatedTime,
      updatedTime && updatedTime != "Invalid Date" ? format(updatedTime, "HH:mm") : ""
    )
    if (dateValue != "Invalid Date") {
      setDeployDetails((prevState) => ({
        ...prevState,
        date: dateValue,
        dateError: false
      }))
    } else {
      setDeployDetails((prevState) => ({ ...prevState, dateError: true }))
    }
  }

  const handleChangeTime = (timeValue, time24HR) => {
    const time = time24HR?.length === 5 ? timeValue : null
    setUpdatedTime(time)
    validateTime(updatedDate, timeValue, time24HR)
    if (timeValue) {
      setDeployDetails((prevState) => ({
        ...prevState,
        time: timeValue,
        timeError: false
      }))
    } else {
      setDeployDetails((prevState) => ({
        ...prevState,
        time: null,
        timeError: true
      }))
    }
  }

  const validateTime = (date, time, time24HR) => {
    const isValidTime = validateDeployTime(date, time, time24HR)
    setInvalidTime(!isValidTime)
  }

  const handleChangeNotes = (event) => {
    const value = event.target.value.replace(specialCharactersNotAllowedForSearch, "")
    let msg = removeEmoji(value)
    setDeployDetails((prevState) => ({ ...prevState, notes: msg }))
  }

  const { requestMutation: deploySWPackage } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit?.(),
    onErrorCallback: () => onSubmit?.(),
    queryFn: softwareSVC.deploySWPackageToAsset,
    refetchQueries
  })

  const { requestMutation: scheduleDeploySWPackage } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit?.(),
    onErrorCallback: () => onSubmit?.(),
    queryFn: softwareSVC.scheduleDeploySWPackageToAsset,
    refetchQueries
  })

  const { requestMutation: reScheduleDeploySWPackage } = useMutationWithHandlers({
    onCompletedCallback: () => onSubmit?.(),
    onErrorCallback: () => onSubmit?.(),
    queryFn: softwareSVC.reScheduleSoftwareDeployment,
    refetchQueries
  })

  const handleSubmit = () => {
    const isValidTime = validateDeployTime(updatedDate, updatedTime, updatedTime ? format(updatedTime, "HH:mm") : "")
    setTimeError(!isValidTime)
    setInvalidTime(!isValidTime)
    if (
      !isCurrentStatusScheduled &&
      !deployDetails.scheduled
    ) {
      handleDeploySoftwarePackage()
    } else if (
      isCurrentStatusScheduled &&
      !deployDetails.scheduled
    ) {
      handleReScheduleDeploySWPackage(new Date(), addMinutes(new Date(), 1))
    } else if (
      isCurrentStatusScheduled &&
      deployDetails.scheduled &&
      isValidTime &&
      pageType === SOFTWARE_TYPE.ASSET_VIEW
    ) {
      handleReScheduleDeploySWPackage(updatedDate, updatedTime)
    } else if (
      !isCurrentStatusScheduled &&
      deployDetails.scheduled &&
      isValidTime
    ) {
      handleScheduleDeploySWPackage()
    }
  }

  const handleDeploySoftwarePackage = () => {
    deploySWPackage({
      errorMessage: t("softwarePackages.deployModal.deployFailureText"),
      payload: getDeploySoftwarePayload(
        packageDetails,
        deployDetails,
        assetSerialNumber,
        siteUcmId
      ),
      successMessage: t("softwarePackages.deployModal.deploySuccessText")
    })
  }

  const handleScheduleDeploySWPackage = () => {
    scheduleDeploySWPackage({
      errorMessage: t("softwarePackages.deployModal.deployFailureText"),
      payload: getScheduledSoftwarePackagePayload(
        packageDetails,
        deployDetails,
        updatedDate,
        updatedTime,
        assetSerialNumber,
        siteUcmId
      ),
      successMessage: t("softwarePackages.deployModal.scheduledDeploySuccessText")
    })
  }

  const handleReScheduleDeploySWPackage = (date, time) => {
    reScheduleDeploySWPackage({
      errorMessage: t("softwarePackages.deployModal.deployFailureText"),
      payload: getReScheduledSoftwarePackagePayload(
        packageDetails,
        deployDetails,
        date,
        time,
        assetSerialNumber
      ),
      successMessage: t("softwarePackages.deployModal.reScheduledDeploySuccessText")
    })
  }

  return (
    <ConfirmationPopup
      heading={
        isEditMode ? t("softwarePackages.deployModal.editModalTitle") : t("softwarePackages.deployModal.modalTitle")
      }
      isOpen={ isOpen }
      onSubmit={ handleSubmit }
      onCancel={ onCancel }
      confirm={ OK }
      cancel={ CANCEL }
      isConfirmEnable={
        deployDetails.scheduled &&
        (inValidTime ||
          (deployDetails.scheduled &&
            isEditMode &&
            initialDateRef?.current &&
            updatedDate &&
            format(initialDateRef?.current, DATE_FORMAT.date) === format(updatedDate, DATE_FORMAT.date) &&
            initialDateRef?.current &&
            updatedTime &&
            updatedTime != "Invalid Date" &&
            format(initialDateRef?.current, "HH:mm") === format(updatedTime, "HH:mm")))
      }
      popupStyles={ popupStyles }
    >
      <div className={ styles.container }>
        <div className={ classNames(styles.confirmText, styles.textEllipsis) }>
          { t("softwarePackages.deployModal.confirmText", {
            packageName: packageDetails?.name
          }) }
        </div>
        <div className="deploy">
          <ToggleSwitch
            id={ "deployNowSwitch" }
            isChecked={ Boolean(!deployDetails?.scheduled) }
            handleOnClick={ (deployNow) => {
              defaultUpdatedDateRef.current = updatedTime
              handleChangeTime(
                defaultUpdatedDateRef?.current,
                defaultUpdatedDateRef?.current && defaultUpdatedDateRef?.current != "Invalid Date"
                  ? format(defaultUpdatedDateRef?.current, "HH:mm")
                  : ""
              )
              setDeployDetails((prevState) => ({
                ...prevState,
                scheduled: !deployNow,
                time: new Date()
              }))
            } }
            label={ t("softwarePackages.deployModal.deployNow") }
          />
          { deployDetails.scheduled && (
            <>
              <div className="scheduleDeploy">
                <DatePicker
                  minDate={ DEFAULT_DATE_RANGE.todaysDate }
                  maxDate={ DEFAULT_DATE_RANGE.maxDateSixMonthFuture }
                  disablePast={ true }
                  onChangeDate={ handleChangeDate }
                  width={ "140px !important" }
                  customStyle={ styles.deployDatePicker }
                  value={ updatedDate }
                />
                <TimePickerDropdown
                  updatedDate={ updatedDate }
                  defaultDateTime={ defaultUpdatedDateRef?.current }
                  hasError={ timeError }
                  menuheight={ { maxHeight: 150 } }
                  onChange={ handleChangeTime }
                  width={ 140 }
                />
              </div>
              <div className={ styles.helperText }>{ t("softwarePackages.deployModal.helperText") }</div>
            </>
          ) }
        </div>
        <div className={ styles.notesContainer }>
          <label className={ "inputLable" } htmlFor="deployNotes">
            { t("editAssetNamePopUp.addNote") }
          </label>
          <textarea
            className="textArea"
            id="deployNotes"
            maxLength={ TEXT_FIELD_MAX_CHAR }
            onChange={ handleChangeNotes }
            value={ deployDetails?.notes ?? "" }
            aria-label="Add note"
          />
          <div className={ "inputInstructions" }>
            { t("editAssetNamePopUp.maxLength", {
              maxLength: TEXT_FIELD_MAX_CHAR
            }) }
          </div>
        </div>
      </div>
    </ConfirmationPopup>
  )
}

SWPackageDeployModal.propTypes = {
  assetSerialNumber: PropTypes.any,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  packageDetails: PropTypes.object,
  pageType: PropTypes.string,
  popupStyles: PropTypes.object,
  t: PropTypes.func,
  type: PropTypes.string
}

export default SWPackageDeployModal
