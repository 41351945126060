import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./SoftwareState.module.scss"

import { SOFTWARE_RELEASE_STATE_LEVELS } from "../../utils/GlobalConstants"
import { formatStatus } from "../../utils/helper"
import { classNames } from "../../utils/styles/helper"

const SoftwareState = ({ releaseState }) => {
  const { t } = useTranslation(["asset"])

  const transFormState = formatStatus(releaseState)
  const stateIcon = SOFTWARE_RELEASE_STATE_LEVELS[transFormState]
    ? SOFTWARE_RELEASE_STATE_LEVELS[transFormState].ICON
    : ""

  if (stateIcon) {
    return (
      <Tooltip
        arrow
        placement="bottom"
        title={ SOFTWARE_RELEASE_STATE_LEVELS[transFormState].TOOLTIP }
      >
        <div className={ classNames(styles.rootIndicator) }>
          <img id="software-state" src={ stateIcon } alt={ t("softwareState") } />
        </div>
      </Tooltip>
    )
  } else return null
}

SoftwareState.propTypes = {
  releaseState: PropTypes.string
}

SoftwareState.defaultProps = {
  releaseState: ""
}

export default SoftwareState
