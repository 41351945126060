export const FaultsAuthElement = {
  AckAssetFault: "ack:asset:fault",
  AckCentralFaults: "ack:central:fault",
  ExpCentralFaults: "exp:central:fault",
  ExportAssetFault: "exp:asset:fault",
  ReadAssetFault: "read:asset:fault",
  ReadCentalFaultsPage: "read:central:fault",
  UnAckCentralFaults: "unack:central:fault",
  UnkAssetFault: "unack:asset:fault"
}
