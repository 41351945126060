import { addDays, subDays } from "date-fns"

const todaysDate = new Date()
export const DEFAULT_DATE_RANGE = {
  endDate: addDays(todaysDate, 0),
  maxDate: addDays(todaysDate, 0),
  minDate: subDays(todaysDate, 729),
  startDate: subDays(todaysDate, 89)
}

export const transformSoftwareList = (data) => {
  const softwarePackges = JSON.parse(JSON.stringify(data || []))
  for (const software of softwarePackges) {
    software.releaseState = software.packageState
    software.uploadedOn = software.uploadedDate ? new Date(software.uploadedDate) : ""
  }
  return softwarePackges
}

const getInUpdatesStatistics = (updates) => {
  return {
    countValue: updates?.total,
    distribution: [updates?.inProgress, updates?.pending, updates?.failed],
    type: "swpackagesupdate"
  }
}

const getUpdatesActivitiesStatistics = (updates) => {
  return {
    countValue: updates?.total,
    distribution: [updates?.inProgress, updates?.pending, updates?.failed],
    type: "swpackagesactivity"
  }
}

const getModalityChartStatistics = (data) => {
  const chartData = []
  Object.entries(data).forEach(([key, value]) => {
    chartData.push({
      label: key,
      value: value
    })
  })
  return [
    {
      title: "Successful activities by modality",
      type: "sucessModality",
      value: chartData
    }
  ]
}

const getDistributionData = (assetsInConfig, configActivities) => {
  return [
    getInUpdatesStatistics(assetsInConfig),
    getUpdatesActivitiesStatistics(configActivities)
  ]
}

export const transformStatistics = (data) => {
  const { assetsInDeployment, deploymentActivities, byModality } = data ?? {}
  return {
    distributionData: getDistributionData(assetsInDeployment, deploymentActivities),
    modalityChartData: getModalityChartStatistics(byModality ?? {})
  }
}

export const intialState = {
  isOpen: false,
  packageDetails: {}
}

export const softwareFilterData = [
  {
    defaultValue: "",
    key: "modality",
    label: "Modality",
    multiple: false,
    options: []
  },
  {
    defaultValue: [],
    key: "model",
    label: "Model",
    multiple: true,
    options: []
  }
]
