/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useCallback } from "react"

import styles from "./NoRows.module.scss"

import {
  NO_ROWS_FOUND_MESSAGE,
  TABLE_INSTANCES
} from "../../../../utils/Constants/DataTable"

const NoRows = ({ type, customMessage }) => {
  const rowDetails = useCallback(
    NO_ROWS_FOUND_MESSAGE()[type] || NO_ROWS_FOUND_MESSAGE().DEFAULT
  )

  if (customMessage) {
    return (
      <div role="row" name="noRows" className={ styles.customMessageRoot }>
        <div role="cell">{ customMessage }</div>
      </div>
    )
  }

  return (
    <div role="row" name="noRows" className={ styles.root }>
      <div role="cell" className="heading">{ rowDetails.HEADING }</div>
      <div role="cell" className="message">{ rowDetails.MESSAGE }</div>
    </div>
  )
}

NoRows.propTypes = {
  customMessage: PropTypes.any,
  type: PropTypes.oneOf(Object.values(TABLE_INSTANCES))
}

export default NoRows
