import { IconButton, Menu, MenuItem } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import styles from "./UserSettingsMenu.module.scss"

import { isQRCodeEnabled } from "../../config/serverConfig"
import { useAbout } from "../../contexts/AboutContext"
import { useHelp } from "../../contexts/help/HelpContext"
import { useQRScanner } from "../../contexts/qrScanner/QRScannerContext"
import { logoutUser } from "../../services/idam/login"
import { ROUTE_CONFIG } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import { UserMenuRouteMap, headerData, userSettingsMenuItems } from "../AppHeader/HeaderUtils"
import AuthGuard from "../AuthGuard/AuthGuard"
import Icon from "../Icon/Icon"
import IconLabel from "../IconLabel/IconLabel"

const UserSettingsMenu = ({ userName, handleUserSettingsMenu }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { displayAboutPopup } = useAbout()
  const { t } = useTranslation(["userMenu"])
  const [anchorEl, setAnchorEl] = useState(null)
  const [activeTab, setActiveTab] = useState("")
  const open = Boolean(anchorEl)
  const userSettingsMenuIcon = open ? "arrowUp" : "arrowDown"
  const { displayHelpPopup } = useHelp()
  const { displayQRScannerPopup } = useQRScanner()

  useEffect(() => {
    setActiveTab(UserMenuRouteMap[location.pathname])
  }, [location.pathname])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleUserSettingsMenu(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    handleUserSettingsMenu(false)
  }

  const onSelectMenuItem = ({ label }) => {
    if (label !== "about" && label !== "help" && label !== "scanQRCode") setActiveTab(label)
    switch (label) {
    case "signOut":
      handleSignOut()
      break
    case "viewProfile":
      navigatePage(`/${ROUTE_CONFIG.USER.routeName}/${ROUTE_CONFIG.VIEW_PROFILE.routeName}`)
      break
    case "manageUsers":
      navigatePage(`/${ROUTE_CONFIG.USER.routeName}/${ROUTE_CONFIG.MANAGE_USERS.routeName}`)
      break
    case "help":
      displayHelpPopup()
      break
    case "about":
      displayAboutPopup()
      break
    case "scanQRCode":
      displayQRScannerPopup()
      break
    }
  }

  const navigatePage = (route) => {
    setTimeout(() => navigate(route), 500)
  }

  const handleSignOut = () => {
    logoutUser()
  }

  return (
    <span className={ styles.userProfileButton }>
      <IconButton
        onClick={ handleClick }
        size="small"
        aria-controls={ open ? "account-menu" : undefined }
        aria-haspopup="true"
        aria-expanded={ open ? "true" : undefined }
        disableRipple
        className="UserProfile"
      >
        <span className={ styles.rightAlign }>
          <span className={ styles.rightInnerAlign }>
            <IconLabel
              label={ userName }
              iconName={ headerData.userIcon }
              labelStyles={ styles.userNameText }
              iconStyles={ styles.userIcon }
            />
            <Icon icon={ userSettingsMenuIcon } iconStyles={ styles.iconStyles } />
          </span>
          <span className={ styles.activeIndicator }></span>
        </span>
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        onClick={ handleClose }
        transformOrigin={ { horizontal: "right", vertical: "top" } }
        anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
        className="UserProfileMenu"
        PaperProps={ {
          style: {
            width: anchorEl?.offsetWidth && anchorEl?.offsetWidth - 8
          }
        } }
      >
        { userSettingsMenuItems.map((menuItem) => {
          if (menuItem.label === "scanQRCode" && isQRCodeEnabled?.toLowerCase() === "off") return null
          return (
            <AuthGuard key={ menuItem.label } actionType={ menuItem.authType }>
              <MenuItem
                className={ classNames("UserMenuItem", activeTab === menuItem.label && "active") }
                onClick={ () => onSelectMenuItem(menuItem) }
              >
                <IconLabel
                  label={ t(`userSettingsMenu.${menuItem.label}`) }
                  iconName={ menuItem.icon }
                  labelStyles={ styles.menuItemLabel }
                  iconStyles={ styles.menuItemIcon }
                />
              </MenuItem>
            </AuthGuard>
          )
        }) }
      </Menu>
    </span>
  )
}

UserSettingsMenu.propTypes = {
  handleUserSettingsMenu: PropTypes.func,
  userName: PropTypes.string
}

export default UserSettingsMenu
