import PropTypes from "prop-types"
import React from "react"

import ReportsSWPackageTableColumnConfig from "./ReportsSWPackageTableColumnConfig"
import styles from "./SWPackage.module.scss"

import DataTable from "../../../../components/DataTable/DataTable"
import Loading from "../../../../components/Loading/Loading"
import SearchErrorPage from "../../../../components/SearchErrorPage/SearchErrorPage"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"

const SWPackage = ({
  swPackage,
  modality,
  isLoading,
  searchError,
  searchResult,
  handleSortChange
}) => {
  return (
    <div className={ styles.reportsSWPackageTable }>
      { searchError && <SearchErrorPage /> }
      { isLoading ? (
        <Loading />
      ) : (
        !searchError &&
        !searchResult && (
          <DataTable
            columnsData={ ReportsSWPackageTableColumnConfig() }
            tableData={ swPackage || [] }
            type={
              modality && swPackage?.length === 0
                ? TABLE_INSTANCES.REPORTS_SWPACKAGE_NO_DATA
                : TABLE_INSTANCES.REPORTS_SWPACKAGE
            }
            rowHoverEffect
            onSortingChange={ handleSortChange }
          />
        )
      ) }
      { !searchError && searchResult && (
        <div className={ styles.reportsSWPackageTable }>
          <DataTable
            columnsData={ ReportsSWPackageTableColumnConfig() }
            tableData={ searchResult }
            type={ TABLE_INSTANCES.REPORT_SWPACKAGE_SEARCH }
            rowHoverEffect
            onSortingChange={ handleSortChange }
          />
        </div>
      ) }
    </div>
  )
}

SWPackage.propTypes = {
  handleSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
  modality: PropTypes.number,
  searchError: PropTypes.bool,
  searchResult: PropTypes.array,
  swPackage: PropTypes.array
}

export default SWPackage
