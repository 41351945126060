import { Radio } from "@mui/material"
import PropTypes from "prop-types"
import { React } from "react"

import { ICON_NAME } from "../../../utils/GlobalConstants"
import Icon from "../../Icon/Icon"
import styles from "../MoveFleet.module.scss"
const SubFleetItem = ({ subFleet, item, handleFleetClick, selectedFleet, disabled }) => {

  const checkedState = selectedFleet.subFleet === subFleet.fleetId

  return (
    <span className={ checkedState ? styles.selectedSubFleet :styles.subFleet }>
      <span
        key={ subFleet.fleetId }
        className={ styles.subFleetLabel }
        data-testid={ `sub-fleet-${subFleet.fleetId}` }
      >
        <Radio
          disabled={ disabled }
          disableRipple
          icon={ <Icon icon={ ICON_NAME.unSelectedRadio } /> }
          checkedIcon={ <Icon icon={ ICON_NAME.selectedRadio } /> }
          name="subFleets"
          checked={ checkedState }
          className={ styles.subFleetRadio }
          onChange={ () => {
          // This is intentional
          } }
          onClick={ () => handleFleetClick(
            item.isSite, item.fleetId, item.fleetName, subFleet.fleetId, subFleet.fleetName
          ) }
        />
        <span>{ subFleet.fleetName }</span>
      </span>
    </span>

  )
}

SubFleetItem.propTypes = {
  disabled: PropTypes.bool,
  handleFleetClick: PropTypes.func,
  item: PropTypes.object,
  selectedFleet: PropTypes.object,
  subFleet: PropTypes.object
}
export default SubFleetItem
