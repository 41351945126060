import { IconButton } from "@mui/material"
import { React } from "react"
import { useTranslation } from "react-i18next"

import styles from "./About.module.scss"

import { buildDetails } from "../../config/serverConfig"
import { useAbout } from "../../contexts/AboutContext"
import { ICON_NAME } from "../../utils/GlobalConstants"
import { classNames } from "../../utils/styles/helper"
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup"
import Icon from "../Icon/Icon"

const About = () => {
  const { t } = useTranslation(["about"])
  const {
    state: { isOpen },
    dispatch
  } = useAbout()

  const handleClose = () => {
    dispatch({ payload: { isOpen: false }, type: "close" })
  }

  return (
    <div className={ styles.popupContainer }>
      <ConfirmationPopup
        isOpen={ isOpen }
        isSubmit={ false }
        popupStyles={ styles.popupStyles }
      >
        <IconButton
          onClick={ handleClose }
          className={ classNames(styles.closeIcon, "AboutCancelIcon") }
          aria-label="Close Popup"
        >
          <Icon icon={ ICON_NAME.closeIcon } />
        </IconButton>
        <span className={ styles.aboutContent }>
          <span>
            <Icon icon={ ICON_NAME.aboutIcon } iconStyles={ styles.aboutIcon } />
          </span>

          <span className={ styles.aboutHeading }>{ t("fleetManager") }</span>
          <span className={ styles.aboutVersion }>
            { " " }
            { t("version", {
              build: buildDetails.build,
              version: buildDetails.version
            }) }
          </span>
          <span className={ styles.aboutCopyRight }> { t("copyRight") }</span>
        </span>
      </ConfirmationPopup>
    </div>
  )
}

export default About
