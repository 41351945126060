import { Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import styles from "./SWVersionWithUpdates.module.scss"

import { classNames } from "../../utils/styles/helper"

const SWVersionWithUpdates = ({ cell, isResizing, version, availableUpdates }) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  const textElementRef = useRef()
  const headerFullSize = cell?.column?.getSize()
  const getCanResize = cell?.column?.getCanResize()

  const compareSize = () => {
    const compare =
      textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth
    setShowInfoTooltip(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [])

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize)
    },
    []
  )

  useEffect(() => {
    setTimeout(() => {
      compareSize()
    }, 0)
  }, [headerFullSize, getCanResize, textElementRef, isResizing])

  return (
    <div className={ styles.root }>
      <Tooltip
        title={ <span className={ "tooltipTitle" }>{ version }</span> }
        interactive="true"
        disableHoverListener={ !showInfoTooltip }
        arrow
        placement="bottom-start"
      >
        <div
          className={ classNames(
            "textContainer",
            availableUpdates > 0 && "width50"
          ) }
          ref={ textElementRef }
        >
          { version }
        </div>
      </Tooltip>
    </div>
  )
}

SWVersionWithUpdates.propTypes = {
  availableUpdates: PropTypes.number,
  cell: PropTypes.shape({
    column: PropTypes.object
  }),
  isResizing: PropTypes.any,
  version: PropTypes.any
}
SWVersionWithUpdates.defaultProps = {
  availableUpdates: 0
}

export default SWVersionWithUpdates
