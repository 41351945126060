import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./Error.module.scss"

const Unauthorized = () => {
  const { t } = useTranslation(["login"])
  return (
    <div className={ styles.authContainer }>
      <span className={ styles.heading }>{ t("unauthorized.title") }</span>
      <span className={ styles.message }>{ t("unauthorized.message") }</span>
    </div>
  )
}

export default Unauthorized
