/* eslint-disable react/display-name */
import PropTypes from "prop-types"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react"

import { NavItems } from "../../../utils/Constants/Login"
import styles from "../Login.module.scss"

const Navbar = forwardRef(({ t }, ref) => {
  const [visibleKey, setVisibleKey] = useState()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) setVisibleKey(entry?.target?.id)
        })
      }, { threshold: 1.0 }
    )
    NavItems.forEach((item) => {
      const div = document.querySelector(`#${item.key}`)
      observer?.observe(div)
    })
    return () => observer.disconnect()
  }, [])

  useImperativeHandle(ref, () => ({
    resetLinkSelection: () => {
      setVisibleKey("")
    }
  }))

  const onClick = (key) => {
    setTimeout(() => setVisibleKey(key))
  }

  return (
    <div className={ styles.fleetManagerHeader }>
      <ul>
        { NavItems.map((item) => {
          return (
            <li
              name={ item.key }
              key={ `item-${item.key}` }
              className={ `${item.key === visibleKey ? styles.activeLink : ""}` }
            >
              <a href={ `#${item.key}` } onClick={ () => onClick(item.key) }>
                <span className="text">{ t(`stickyBar.${item.labelKey}`) }</span>
              </a>
            </li>
          )
        }) }
      </ul>
    </div>
  )
})

Navbar.propTypes = {
  t: PropTypes.any
}

export default Navbar
