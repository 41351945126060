export const AssetAuthElement = {
  MuteAsset:  "mute:asset",
  Properties: {
    ExportAssetProperties: "exp:asset:property",
    ReadAssetProperties:"read:asset:property",
    RequestLatestProperties: "request:asset:property",
    UpdateAssetProperty: "update:asset:property"
  },
  ReadAssetPage: "read:asset",
  UnMuteAsset: "unmute:asset"
}
