import React, { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./AssetFaults.module.scss"
import AssetFaultStatistics from "./AssetFaultStatistics/AssetFaultStatistics"
import AssetFaultSummary from "./AssetFaultSummary/AssetFaultSummary"
import {
  transformAssetFaultStatistics
} from "./AssetFaultUtils"
import { useAssetFaultsContext } from "./Context/AssetFaultsContext"

import AcknowledgeUnAcknowledgePopUp from "../../components/AcknowledgeUnAcknowledgePopUp/AcknowledgeUnAcknowledgePopUp"
import ConfigFilterPopup from "../../components/ConfigFilterPopup/ConfigFilterPopup"
import Error from "../../components/Error/Error"
import { AssetFaultsFilterData } from "../../utils/Constants/AssetFaultsFilterData"
import { FaultsAuthElement } from "../../utils/Constants/Auth/faults"
import useAccess from "../../utils/CustomHooks/useAccess"
import { CONFIG_CONTEXT_TYPE } from "../../utils/GlobalConstants"
import { intialState } from "../AssetView/AssetFaults/AssetFaultsUtils"

const AssetFaults = () => {
  const allSelectedFaults = useRef([])
  const [isFilterOpen, setIsFilterOpen] = useState(true)
  const [filterAPIError, setFilterAPIError] = useState(false)
  const { t: common } = useTranslation(["common"])

  const {
    getFaultsQueryResults,
    t,
    assetSummaryRef,
    setSelectedAssets,
    acknowledgeUnAcknowledgePopUp,
    state,
    handleFilterSubmit,
    setAcknowledgeUnAcknowledgePopUp,
    faultsStatistics,
    queryKey
  } = useAssetFaultsContext()

  const hasConfigAccess = useAccess(FaultsAuthElement.ReadCentalFaultsPage)
  const { data: assetFaults, isError: isGetFaultsError } =
    getFaultsQueryResults
  let assets = assetFaults?.data?.faultList || []

  const { assetSummary, distributionData } = transformAssetFaultStatistics(
    assets,
    faultsStatistics
  )

  const handleSelectedAssets = useCallback((id, assets) => {
    const idx = allSelectedFaults.current.findIndex(
      (x) => Number(x.faultId) === Number(id)
    )
    if (idx > -1) {
      allSelectedFaults.current[idx].assets = assets
    } else {
      const faultData = {
        assets,
        faultId: id
      }
      allSelectedFaults.current.push(faultData)
    }
    let allSelectedAssets = []
    for (const fault of allSelectedFaults.current) {
      if (fault?.assets)
        allSelectedAssets = [...allSelectedAssets, ...fault.assets]
    }
    setSelectedAssets(allSelectedAssets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    setAcknowledgeUnAcknowledgePopUp(intialState)
    assetSummaryRef?.current?.resetCheckboxSelection()
  }

  if (isGetFaultsError)
    return <Error error={ { message: t("getApiFailMessage") } } />

  if (filterAPIError)
    return <Error error={ { message: common("errorInFilterOptionsApi") } } />

  return (
    <>
      <div className={ styles.assetsStaticCards }>
        <AssetFaultStatistics distributionData={ distributionData } />
      </div>
      <div className={ styles.assetsFaults }>
        { hasConfigAccess && (
          <AssetFaultSummary
            assetSummary={ assetSummary }
            ref={ assetSummaryRef }
            handleSelectedAssets={ handleSelectedAssets }
            selectedAssets={ state?.selectedAssets }
            setIsFilterOpen={ setIsFilterOpen }
          />
        ) }
        { isFilterOpen && (
          <ConfigFilterPopup
            isPopupOpen={ isFilterOpen }
            onCancel={ setIsFilterOpen }
            onSubmit={ handleFilterSubmit }
            setIsFilterOpen={ setIsFilterOpen }
            data={ AssetFaultsFilterData }
            popupStyles={ styles.overlay }
            type={ CONFIG_CONTEXT_TYPE.ASSET_FAULTS }
            customLoadingStyle={ styles.contentLoading }
            showToastError={ false }
            setFilterAPIError={ setFilterAPIError }
          />
        ) }
        <AcknowledgeUnAcknowledgePopUp
          message={ acknowledgeUnAcknowledgePopUp.message }
          type={ acknowledgeUnAcknowledgePopUp.type }
          isOpen={ acknowledgeUnAcknowledgePopUp.isOpen }
          handleCancel={ () => setAcknowledgeUnAcknowledgePopUp(intialState) }
          handleSubmit={ handleSubmit }
          queryKey={ queryKey }
          { ...acknowledgeUnAcknowledgePopUp }
        />
      </div>
    </>
  )
}

export default AssetFaults
