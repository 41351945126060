/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

const useOutsideAlerter = ({ ref, onClickOutside }) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!ref?.current?.contains(event.target)) {
        onClickOutside(event.target)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

export default useOutsideAlerter
