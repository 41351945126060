import addDays from "date-fns/addDays"
import subDays from "date-fns/subDays"

import { TABLE_ACTION } from "../../../utils/Constants/AssetsConfig"
import { ICON_NAME } from "../../../utils/GlobalConstants"
import { softwareStatus } from "../../../utils/helper"

const todaysDate = new Date()

export const DEFAULT_DATE_RANGE = {
  endDate: addDays(todaysDate, 0),
  maxDate: addDays(todaysDate, 0),
  minDate: subDays(todaysDate, 89),
  startDate: subDays(todaysDate, 13)
}

export const transformSoftwarePackages = (data) => {
  const softwarePackges = JSON.parse(JSON.stringify(data || []))
  for (const packages of softwarePackges) {
    packages.id = packages?.packageId
    packages["status"] = softwareStatus(
      packages?.status?.toLowerCase(),
      packages?.inProgressStatus?.toLowerCase()
    )
  }
  return softwarePackges
}

export const intialState = {
  isOpen: false,
  packageDetails: {}
}

export const SOFTWARE_PACKAGES_MENU_DATA = [
  {
    icon: ICON_NAME.edit,
    id: 1,
    name: "scheduled",
    type: TABLE_ACTION.EDIT_SCHEDULE_DEPLOY
  },
  {
    icon: ICON_NAME.cancelCircle,
    id: 2,
    name: "cancel",
    type: TABLE_ACTION.CANCEL_DEPLOY
  }
]

export const SOFTWARE_PACKAGES_MENU = {
  horizontal: "right",
  vertical: "top"
}
export const SOFTWARE_PACKAGES_MENU_ORIGIN = {
  horizontal: "right",
  vertical: "bottom"
}

export const SOFTWARE_TYPE = {
  ASSET_VIEW: "assetView",
  CENTRAL_VIEW: "centralView"
}

export const DEPLOY_BUTTON_STATUSES = [
  "success",
  "rejected",
  "cancelled",
  "canceled",
  "failed",
  "",
  undefined,
  null
]

export const INPROGRESS_STATUSES = [
  "in progress",
  "inprogress",
  "in-progress",
  "inprogressstep1",
  "inprogressstep2",
  "inprogressstep3"
]

export const SCHEDULED_STATUS = "scheduled"
