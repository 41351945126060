import axios from "axios"

import { apiServer } from "../../config/serverConfig"
import { DATE_FORMAT } from "../../utils/Constants/TimeZones"
import { COMPARE_VALUE } from "../../utils/GlobalConstants"
import { arrayGroupBy, convertToServerTimeZone } from "../../utils/helper"
import axiosClient from "../axios/axios"

const PAGE_SIZE = 1000

export const fileSVC = {
  deleteAction: ({ configFileId, variables }) => {
    return axiosClient.delete(
      `filesvc/v1/files/configurations/${configFileId}`,
      variables
    )
  },
  deployConfig: ({ variables }) => {
    return axiosClient.post(
      "/filesvc/v1/assets/configurations/deploy",
      variables
    )
  },
  downloadFilesFromS3: ({ documentId, offline }) => {
    if (offline) {
      return axios.get(
        `${apiServer}/filesvc/v1/readyfix/homeScreenDocuments/${documentId}/link`
      )
    } else {
      return axiosClient.get(
        `/filesvc/v1/readyfix/documents/${documentId}/link`
      )
    }
  },
  getAssetConfigFiles: (serialNumber, searchInput, assetConfigDateRange) => {
    const startDate = convertToServerTimeZone(
      assetConfigDateRange.startDate,
      DATE_FORMAT.date
    )
    const endDate = convertToServerTimeZone(
      assetConfigDateRange.endDate,
      DATE_FORMAT.date
    )
    const url = `/filesvc/v1/assets/${serialNumber}/configurations`
    const date = `?startDate=${startDate}&endDate=${endDate}`
    let query = url + date + `&page=1&size=${PAGE_SIZE}`
    if (searchInput) {
      query += `&search=${searchInput}`
    }
    return axiosClient.get(query)
  },
  getConfigFilesBySiteId: ({
    siteId,
    filterValue,
    searchInput,
    configDateRange
  }) => {
    const startDate = convertToServerTimeZone(
      configDateRange?.startDate,
      DATE_FORMAT.date
    )
    const endDate = convertToServerTimeZone(
      configDateRange?.endDate,
      DATE_FORMAT.date
    )
    const url = `/filesvc/v1/sites/${siteId}/configurations`
    const date = `?startDate=${startDate}&endDate=${endDate}`
    let query =
      date + `&size=${PAGE_SIZE}${searchInput ? "&search=" + searchInput : ""}`
    if (filterValue?.length > 0) {
      Object.entries(arrayGroupBy(filterValue, "type")).forEach(
        (eachFilter) => {
          query += `&${
            eachFilter[0] === COMPARE_VALUE.model
              ? COMPARE_VALUE.model
              : eachFilter[0]
          }=${eachFilter[1].map((each) => each.label).join(",")}`
        }
      )
    }
    return axiosClient.get(url + query)
  },
  getConfigReportsBySiteId: ({
    siteId,
    reportsDateRange,
    modality,
    model,
    modalityOptions,
    modelOptions,
    searchInput
  }) => {
    const startDate = convertToServerTimeZone(
      reportsDateRange.startDate,
      DATE_FORMAT.date
    )
    const endDate = convertToServerTimeZone(
      reportsDateRange.endDate,
      DATE_FORMAT.date
    )
    const url = `/filesvc/v1/sites/${siteId}/configuration-report`
    const date = `?startDate=${startDate}&endDate=${endDate}&size=${PAGE_SIZE}&page=0`
    let query = `${searchInput ? "&searchString=" + searchInput : ""}`
    if (modality) {
      query = query + `&modality=${modalityOptions[modality - 1]}`
    }
    if (model?.length > 0) {
      let modelList = ""
      for (const _model of model) {
        modelList =
          modelList + (modelList ? "," : "") + modelOptions[_model - 1]
      }
      query = query + `&model=${modelList}`
    }
    return axiosClient.get(url + date + query)
  },
  getConfigStatistics: (siteUCMId) => {
    return axiosClient.get(`/filesvc/v1/sites/${siteUCMId}/configStatistics`)
  },
  promoteLockActions: ({ configFileId, variables }) => {
    return axiosClient.patch(
      `filesvc/v1/files/configurations/${configFileId}`,
      variables
    )
  },
  requestConfig: ({ variables }) => {
    return axiosClient.get(
      `/filesvc/v1/assets/${variables?.serialNumber}/latestConfig?correlationId=${variables?.correlationId}`
    )
  }
}

export const fileSVCKeys = {
  DOWNLOAD_FILE_FROM_S3: "downloadFilesFromS3",
  GET_ASSET_CONFIG: "getAssetConfigFiles",
  GET_CENTRAL_CONFIG_STATISTICS: "getCentralConfigStatistics"
}
