/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

import DragHandle from "./DragHandle"

import { CHECKBOX_STATE_ICONS } from "../../../utils/GlobalConstants"
import { classNames } from "../../../utils/styles/helper"
import CardColorBar from "../../CardColorBar/CardColorBar"
import Icon from "../../Icon/Icon"
import { useDataTableContext } from "../../NestedDataTable/NestedDataTableContext"
import style from "../DataTable.module.scss"

const TableRowCustomSelection = ({
  tableProps,
  clickHandler,
  displayRowColorBar,
  selectableRows,
  draggableRows,
  componentProps
}) => {
  const { rowRefs } = useDataTableContext()
  const handleCheckbox = (event) => {
    event.stopPropagation()
    if (event.target.tagName !== "INPUT") {
      event?.target?.previousSibling?.click()
      return false
    }
    clickHandler?.({
      checked: event.target.checked,
      data: tableProps?.row?.original
    })
    if (componentProps?.canExpandRow?.(tableProps?.row?.original)) {
      if (event.target.checked) {
        rowRefs.current[tableProps?.row?.id]?.toggleAll(true)
      } else {
        rowRefs.current[tableProps?.row?.id]?.toggleAll(false)
      }
    }
  }

  let { customDefaultInterminentRows, currentRowState } = tableProps.table.getState()

  const disableRowSelection = useMemo(
    () => !tableProps?.row?.getCanSelect() || currentRowState?.[tableProps.row.id]?.disabled,
    [tableProps?.row, currentRowState]
  )

  const handleCheckedProps = useMemo(() => {
    const isSelected = tableProps?.row?.getIsSelected()
    if (!isSelected) {
      if (
        Object.hasOwn(customDefaultInterminentRows[tableProps.row.id] || {}, "checked") &&
        Object.hasOwn(customDefaultInterminentRows[tableProps.row.id] || {}, "indeterminate") &&
        customDefaultInterminentRows[tableProps.row.id]
      ) {
        return customDefaultInterminentRows[tableProps.row.id]
      } else if (currentRowState[tableProps.row.id]) {
        return currentRowState[tableProps.row.id]
      }
    } else if (disableRowSelection) {
      return { disabled: true }
    }
    return {}
  }, [customDefaultInterminentRows, currentRowState, tableProps?.row?.getIsSelected()])

  return (
    <div className={ style.selectionColumnStyles }>
      { draggableRows && <DragHandle 
        draggableRows={ draggableRows } dragHandleProps={ tableProps?.dragHandleProps } /> }
      { displayRowColorBar && <CardColorBar status={ tableProps?.row?.original?.status } /> }
      { selectableRows && (
        <>
          { disableRowSelection && componentProps?.rowSelectionTooltipText && (
            <Tooltip arrow title={ componentProps.rowSelectionTooltipText }>
              <div className={ style.disabledCheckboxTooltip }></div>
            </Tooltip>
          ) }
          <Checkbox
            type="checkbox"
            size="small"
            data-testid="tableRowSelectCheckbox"
            inputProps={ { "aria-label": tableProps?.row?.original?.name + ` row-${tableProps?.row?.id}` } }
            onClick={ handleCheckbox }
            { ...{
              checked: tableProps?.row?.getIsSelected(),
              indeterminate: tableProps?.row?.getIsSomeSelected(),
              onChange: tableProps?.row?.getToggleSelectedHandler()
            } }
            { ...handleCheckedProps }
            className={ classNames("customCheckbox", style.checkboxStyles) }
            icon={
              <Icon
                iconStyles={ style.checkboxStateIcons }
                icon={
                  !tableProps?.row?.getCanSelect()
                    ? CHECKBOX_STATE_ICONS.disableUnchecked
                    : CHECKBOX_STATE_ICONS.unChecked
                }
              />
            }
            indeterminateIcon={ <Icon 
              iconStyles={ style.checkboxStateIcons } icon={ CHECKBOX_STATE_ICONS.indeterminate } /> }
            disabled={ disableRowSelection }
            checkedIcon={
              <Icon
                iconStyles={ style.checkboxStateIcons }
                icon={ disableRowSelection ? CHECKBOX_STATE_ICONS.disableChecked : CHECKBOX_STATE_ICONS.checked }
              />
            }
          />
        </>
      ) }
    </div>
  )
}

TableRowCustomSelection.propTypes = {
  clickHandler: PropTypes.func,
  componentProps: PropTypes.shape({
    canExpandRow: PropTypes.func,
    parentTableProps: PropTypes.object,
    rowSelectionTooltipText: PropTypes.string
  }),
  displayRowColorBar: PropTypes.bool,
  draggableRows: PropTypes.bool,
  selectableRows: PropTypes.bool,
  tableProps: PropTypes.object
}

export default TableRowCustomSelection
