import PropTypes from "prop-types"
import React from "react"

import { ICON_NAME } from "../../../../utils/GlobalConstants"
import Icon from "../../../Icon/Icon"
import style from "../../CreateUpdateLabelPopup.module.scss"

const ErrorInfo = ({ message }) => {
  return (
    <div className={ style.errorInfo }>
      <Icon icon={ ICON_NAME.formError } iconStyles={ style.icon } />
      { message }
    </div>
  )
}

ErrorInfo.propTypes = {
  message: PropTypes.string
}

export default ErrorInfo
