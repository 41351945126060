import React from "react"

import AssetFaults from "./AssetFaults"
import { AssetFaultsProvider } from "./Context/AssetFaultsContext"

const SiteAssetFaultsContainer = () => {
  return (
    <AssetFaultsProvider>
      <AssetFaults />
    </AssetFaultsProvider>
  )
}

SiteAssetFaultsContainer.propTypes = {}

export default SiteAssetFaultsContainer
