import { ClickAwayListener, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React, { createRef, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { calculateWidth } from "./internal/calculate"
import CloseButton from "./internal/CloseButton"
import FilterDropdown from "./internal/FilterDropdown"
import FilterItem from "./internal/FilterItem"
import styles from "./SelectedFilters.module.scss"

import { ICON_NAME } from "../../utils/GlobalConstants"

function SelectedFilters({
  handleClearAll,
  filters,
  selectedFilterItem,
  setSelectedFilterItem,
  stateCarryForward,
  disableFilter, 
  isPageLoading
}) {
  const { t } = useTranslation(["selectedFilter"])
  const hasDisableFilterItem = []
  const containerRef = useRef()
  const filterRefs = useMemo(() => {
    return filters.map(() => createRef())
  }, [filters])
  const clearAllRef = useRef()
  const badgeRef = useRef()

  const [open, setOpen] = useState(false)

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const [filterState, setFilterState] = useState({
    badgeVisible: true,
    calculating: true,
    clearVisible: true,
    numberOfFiltersToDisplay: filterRefs.length
  })
  const hiddenFilters = useMemo(() => {
    return filters.slice(filterState.numberOfFiltersToDisplay)
  }, [filters, filterState.numberOfFiltersToDisplay])

  useEffect(() => {
    const startCalculation = () => {
      setFilterState({
        badgeVisible: true,
        calculating: true,
        clearVisible: true,
        numberOfFiltersToDisplay: filterRefs.length
      })
    }

    const handleWindowResize = () => {
      startCalculation()
    }

    startCalculation()

    const resizeObserver = new ResizeObserver(() => {
      handleWindowResize()
    })
    resizeObserver.observe(containerRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [filterRefs])

  useEffect(() => {
    if (filterState.calculating) {
      arrangeFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, filterRefs, filters.length, stateCarryForward])

  const arrangeFilters = () => {
    if (filterRefs.length === 1) {
      setFilterState({
        badgeVisible: false,
        calculating: false,
        clearVisible: false,
        numberOfFiltersToDisplay: filters.length
      })
    }

    const container = containerRef.current
    const clearAll = clearAllRef.current
    const badge = badgeRef.current

    const containerWidth = calculateWidth(container)
    const clearAllWidth = calculateWidth(clearAll)
    const badgeWidth = calculateWidth(badge)

    const filtersWidths = filterRefs.map((ref) => {
      return calculateWidth(ref.current)
    })

    const totalFiltersWidth = filtersWidths.reduce((sum, filterWidth) => sum + filterWidth)

    if (clearAllWidth + totalFiltersWidth > containerWidth) {
      let displayedWidth = badgeWidth
      let numberOfFiltersToDisplay = 0
      filtersWidths.forEach((width) => {
        if (displayedWidth + width < containerWidth) {
          displayedWidth += width
          numberOfFiltersToDisplay++
        }
      })

      if (numberOfFiltersToDisplay === filterRefs.length) {
        numberOfFiltersToDisplay--
      }

      setFilterState({
        badgeVisible: true,
        calculating: false,
        clearVisible: false,
        numberOfFiltersToDisplay
      })
    } else {
      setFilterState({
        badgeVisible: false,
        calculating: false,
        clearVisible: stateCarryForward === undefined ? true : false,
        numberOfFiltersToDisplay: filterRefs.length
      })
    }
  }

  const getContainerStyle = () => {
    return {
      height: filterState.calculating ? "0px" : "auto",
      opacity: filterState.calculating ? 0 : 1,
      visibility: filterState.calculating ? "hidden" : "visible"
    }
  }

  const getFilterStyle = (index) => {
    let visible = false
    if (filterState.calculating || index <= filterState.numberOfFiltersToDisplay - 1) {
      visible = true
    }
    return {
      display: visible ? "inline-block" : "none"
    }
  }

  const getClearAllStyle = () => {
    let visible = false
    if ((filterState.calculating || filterState.clearVisible) && filters.length > 1) {
      visible = true
    }
    return {
      display: visible ? "inline-block" : "none"
    }
  }

  const getBadgeStyle = () => {
    let visible = false
    if (filterState.calculating || filterState.badgeVisible) {
      visible = true
    }
    return {
      display: visible ? "inline-block" : "none"
    }
  }

  const removeItem = (event) => {
    const getElement = event.target.parentElement.id
    setSelectedFilterItem(selectedFilterItem.filter((item) => item.label !== getElement))
  }

  return (
    <div ref={ containerRef } className={ styles.selectedFilters } style={ getContainerStyle() }>
      { filters.map((filter, index) => {
        const filterDisabled = disableFilter?.(filter) ?? false
        hasDisableFilterItem.push(filterDisabled)
        return (
          <span
            className={ filterDisabled ? styles.disableFilter : styles.filter }
            key={ filter.label }
            style={ getFilterStyle(index) }
            ref={ filterRefs[index] }
          >
            <FilterItem filter={ filter } isDisabled={ filterDisabled } />
            { !filterDisabled && (
              <CloseButton
                label={ filter.label }
                closeButtonStyle={ styles.closeButton }
                removeItem={ removeItem }
                closeIconStyle={ styles.closeIcon }
                icon={ ICON_NAME.bgClose }
                aria-label={ filter.label }
                isPageLoading={ isPageLoading }
              />
            ) }
          </span>
        )
      }) }

      <button
        type="button"
        aria-label="Clear All Button"
        className={ styles.clearAll }
        ref={ clearAllRef }
        style={ getClearAllStyle() }
        onClick={ handleClearAll }
        disabled={ isPageLoading }
      >
        { t("clearAll") }
      </button>

      <ClickAwayListener onClickAway={ handleTooltipClose }>
        <Tooltip
          PopperProps={ { disablePortal: true } }
          onClose={ handleTooltipClose }
          open={ open }
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title={
            <FilterDropdown
              hiddenFilters={ hiddenFilters }
              removeItem={ removeItem }
              hasDisabledFilter={ hasDisableFilterItem.some((val) => val === true) }
              handleClearAll={ handleClearAll }
              isPageLoading={ isPageLoading }
            />
          }
        >
          <button
            aria-label="count-badge"
            className={ styles.badge }
            ref={ badgeRef }
            style={ getBadgeStyle() }
            onClick={ handleTooltipOpen }
          >
            +{ hiddenFilters.length }
          </button>
        </Tooltip>
      </ClickAwayListener>
    </div>
  )
}

SelectedFilters.propTypes = {
  disableFilter: PropTypes.func,
  filters: PropTypes.array,
  handleClearAll: PropTypes.func,
  isPageLoading: PropTypes.bool,
  selectedFilterItem: PropTypes.array,
  setSelectedFilterItem: PropTypes.func,
  stateCarryForward: PropTypes.object
}

export default SelectedFilters
