import PropTypes from "prop-types"
import React from "react"

import DistributionStatisticsCard from "../../../components/DistributionStatisticsCard/DistributionStatisticsCard"

const SubFleetSummaryStatistics = ({ distributionData }) => {
  return (
    <>
      { distributionData.map((eachDistribution) => (
        <DistributionStatisticsCard
          key={ eachDistribution.type }
          { ...eachDistribution }
        />
      )) }
    </>
  )
}

SubFleetSummaryStatistics.propTypes = {
  distributionData: PropTypes.array.isRequired
}

export default SubFleetSummaryStatistics
