import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import Icon from "../../../../components/Icon/Icon"
import SearchBar from "../../../../components/SearchBar/SearchBar"
import { ASSET_CONFIG_BUTTONS, CONFIG_ACTIONS } from "../../../../utils/Constants/AssetsConfig"
import { SEARCH_INSTANCES } from "../../../../utils/Constants/Search"
import styles from "../Configuration.module.scss"

const HeadSection = ({
  configurations,
  handleSearch,
  refreshConfigs,
  searchBarRef,
  searchResult,
  searchInput,
  muted,
  assetConfigDateRange,
  setAssetConfigDateRange,
  handleRequestLogClick
}) => {
  const { t } = useTranslation(["asset"])

  const searchResultWithMultipleText = () => {
    return searchResult.length > 1
      ? t("configurationTab.headSection.propSearchedCountText", {
        searchCount: searchResult?.length
      })
      : t("configurationTab.headSection.singlePropSearchedCountText", {
        searchCount: searchResult?.length
      })
  }

  const searchResultText = () => {
    return searchResult.length === 0 ? t("configurationTab.headSection.noResultSearch") : searchResultWithMultipleText()
  }

  const handleClick = (type) => {
    if (type === CONFIG_ACTIONS.REQUEST_CONFIG_FILE.key) {
      handleRequestLogClick()
    } else {
      refreshConfigs()
    }
  }

  return (
    <>
      { searchResult && (
        <span role={ "contentinfo" } className={ styles.selectedCountText }>
          { searchResultText() }
        </span>
      ) }

      { ASSET_CONFIG_BUTTONS.map((button) => (
        <AuthGuard key={ button.id } actionType={ button.accessKey }>
          <Button
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ muted ? button.disabledIcon : button.icon } /> }
            onClick={ () => handleClick(button.name) }
            disabled={ muted }
            aria-label={ t(`configurations.buttons.${button.name}`) }
          >
            { t(`configurations.buttons.${button.name}`) }
          </Button>
        </AuthGuard>
      )) }
      <DateRangePicker
        startDate={ assetConfigDateRange.startDate }
        endDate={ assetConfigDateRange.endDate }
        onChange={ (value) => setAssetConfigDateRange((prevState) => ({ ...prevState, ...value })) }
      />
      <div className={ styles.searchField }>
        <SearchBar
          ref={ searchBarRef }
          type={ SEARCH_INSTANCES.centralConfiguration }
          isDisabled={ !searchInput && searchInput == null && configurations?.length === 0 }
          onHandleSubmit={ handleSearch }
        />
      </div>
    </>
  )
}

HeadSection.propTypes = {
  assetConfigDateRange: PropTypes.object,
  configurations: PropTypes.array,
  handleRequestLogClick: PropTypes.func,
  handleSearch: PropTypes.func,
  muted: PropTypes.bool,
  refreshConfigs: PropTypes.func,
  searchBarRef: PropTypes.any,
  searchInput: PropTypes.string,
  searchResult: PropTypes.array,
  setAssetConfigDateRange: PropTypes.func
}

export default HeadSection
