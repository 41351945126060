import PropTypes from "prop-types"
import React, { useCallback, useId, useState } from "react"

import SoftwareColumnConfig from "./SoftwareColumnConfig"

import DataTable from "../../../../components/DataTable/DataTable"
import LabelDataCountAccordion from "../../../../components/LabelDataCountAccordion/LabelDataCountAccordion"
import SoftwarePackagesOverlay from "../../../../components/SoftwarePackagesOverlay/SoftwarePackagesOverlay"
import { TABLE_ACTION } from "../../../../utils/Constants/AssetsConfig"
import { TABLE_INSTANCES } from "../../../../utils/Constants/DataTable"
import { GLOBAL_THROTTLE_TIME, ICON_NAME } from "../../../../utils/GlobalConstants"
import { throttle } from "../../../../utils/helper"
import styles from "../../CentralSoftwareUpdate/CentralSoftwareUpdate.module.scss"
import { intialState, transformSoftwareList } from "../CentralSoftwareUpdatesUtils"

const SoftwareByModelAccordion = ({ softwareList }) => {
  const id = useId()
  const [softwareOverlay, setSoftwareOverlay] = useState(intialState)
  const [actionClick, setActionClick] = useState(false)

  const { packageCount, model, packageInfo } = softwareList

  const handleRowClickActions = (actionType, payload) => {
    if (actionType === TABLE_ACTION.INFO) {
      setActionClick(true)
      setSoftwareOverlay({
        ...softwareOverlay,
        isOpen: true,
        packageDetails: payload
      })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleActions = useCallback(throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME), [])

  const softwarePackages = transformSoftwareList(packageInfo)

  return (
    <>
      <LabelDataCountAccordion
        id={ id }
        icon={ ICON_NAME.fleet }
        label={ model }
        itemCount={ packageCount }
        actionClick={ actionClick }
        customClass={ styles.accordionCustomClass }
      >
        <DataTable
          tableData={ softwarePackages }
          rowHoverEffect
          columnsData={ SoftwareColumnConfig(throttleActions) }
          type={ TABLE_INSTANCES.SOFTWARE_PACKAGES }
        />
      </LabelDataCountAccordion>

      <SoftwarePackagesOverlay
        { ...softwareOverlay }
        isOpen={ softwareOverlay.isOpen }
        handleCancel={ () => setSoftwareOverlay(intialState) }
      />
    </>
  )
}

SoftwareByModelAccordion.propTypes = {
  softwareList: PropTypes.object
}

export default SoftwareByModelAccordion
